import { Flex, Icon, IconButton } from '@chakra-ui/react';
import { FaRegMap } from 'react-icons/fa';
import { ReactComponent as SatelliteIcon } from '../../../assets/satellite.svg';
import { mapStyles } from '../../../common/mapbox';
import { ControlTooltip } from './ControlTooltip';

interface Props {
  value: keyof typeof mapStyles;
  onChange: (value: keyof typeof mapStyles) => void;
}

export const StylesControls = (props: Props) => {
  const { value, onChange } = props;

  return (
    <Flex bg="dark3" p={1} rounded="md">
      <ControlTooltip title="Map view" placement="top">
        <IconButton
          onClick={() => onChange('streets')}
          isActive={value === 'streets'}
          variant={value === 'streets' ? 'secondary' : 'ghost'}
          aria-label="Streets"
          color="neutral4"
          icon={<Icon as={FaRegMap} />}
        />
      </ControlTooltip>
      <ControlTooltip title="Satellite view" placement="top">
        <IconButton
          onClick={() => onChange('satellite')}
          isActive={value === 'satellite'}
          variant={value === 'satellite' ? 'secondary' : 'ghost'}
          aria-label="Satellite"
          color="neutral4"
          icon={<Icon as={SatelliteIcon} />}
        />
      </ControlTooltip>
    </Flex>
  );
};
