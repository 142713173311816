import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends CheckboxProps {
  name: string;
}

export const HookedCheckbox = (props: React.PropsWithChildren<Props>) => {
  const { name, children, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return (
    <Checkbox {...rest} {...inputProps} onChange={(e) => inputProps.onChange(e.target.checked)}>
      {children}
    </Checkbox>
  );
};
