import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Queries } from '../../../api';
import { ROUTES } from '../../../common/routes';
import { currencyFormat, distanceFormat, makeErrorMessage } from '../../../common/utils';
import { useAppContext } from '../../../hooks/useAppContext';
import { OrderData } from '../../../types/main';
import { CreateOrderFormValues } from '../CreateOrder';

interface ConfirmOrderModalProps {
  formData: CreateOrderFormValues;
  orderData: OrderData;
}

export const ConfirmOrderModal = NiceModal.create<ConfirmOrderModalProps>(
  ({ orderData, formData }) => {
    const { successToast, errorToast, queryClient } = useAppContext();
    const modal = useModal();
    const navigate = useNavigate();
    const cancelRef = React.useRef<HTMLButtonElement | null>(null);

    const mutation = Queries.useOrdersCreateOrder({
      mutation: {
        onSettled: () => queryClient.invalidateQueries(Queries.getBalanceQueryKey()),
      },
    });

    const onConfirm = React.useCallback(() => {
      return mutation
        .mutateAsync({
          data: {
            name: formData.name,
            product: formData.product.id,
            target: {
              type: formData.target?.type,
              coordinates: formData.target?.coordinates,
            },
          } as any,
        })
        .then((res) => {
          modal.hide();
          successToast('Your order successfully created');
          navigate(ROUTES.ORDERS);
        })
        .catch((err) => {
          errorToast(makeErrorMessage(err));
        });
    }, [formData, modal, mutation, successToast, errorToast, navigate]);

    return (
      <AlertDialog
        isOpen={modal.visible}
        leastDestructiveRef={cancelRef}
        onClose={modal.hide}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent as={Flex} align="center" p={6}>
            <AlertDialogHeader fontSize="xl" fontWeight="bold">
              Order confirmation
            </AlertDialogHeader>

            <AlertDialogBody display="inline">
              This order of {distanceFormat(orderData.area)} will cost{' '}
              <Text display="inline" fontWeight="bold">
                {currencyFormat(orderData.price)}
              </Text>
              . There is no way to cancel the order once submitted.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="secondary" ref={cancelRef} onClick={modal.hide}>
                Cancel
              </Button>
              <Button onClick={onConfirm} ml={3} isLoading={mutation.isLoading}>
                Place order
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  },
);
