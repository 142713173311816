import { Avatar, Box, HStack, Icon, IconButton, Text, Tooltip } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { UserDetailResponse } from '../../../api/queries.schemas';
import { Table, TableProps } from '../../../components/Table';
import { DepartmentChooser } from '../../../components/forms/DepartmentChooser';
import { EmptyUsersState } from './EmptyUsersState';
import { InvitationStatusTag } from '../../../components/InvitationStatusTag';
import { RoleChooser } from '../../../components/forms/RoleChooser';
import { ConfirmUserDeleteModal } from './UserDeleteConfirmModal';
import { useAppContext } from '../../../hooks/useAppContext';

const columnHelper = createColumnHelper<UserDetailResponse>();

interface Props extends Omit<TableProps<UserDetailResponse, unknown>, 'data' | 'columns'> {
  users: UserDetailResponse[];
}

export const UsersTable = (props: Props) => {
  const { users, isLoading, error, pagination, ...rest } = props;
  const { user } = useAppContext();

  const onUserDelete = React.useCallback((id: string) => {
    NiceModal.show(ConfirmUserDeleteModal, { id });
  }, []);

  const columns: ColumnDef<UserDetailResponse, any>[] = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'User name',
        cell: (info) => (
          <HStack spacing={3}>
            <Avatar name={info.getValue()} size="sm" color="neutral2" />
            <Text fontWeight="semibold">{info.getValue()}</Text>
          </HStack>
        ),
      }),
      columnHelper.accessor('role', {
        header: 'Role',
        cell: (info) => (
          <RoleChooser userId={info.row.original.id} defaultValue={info.getValue()} />
        ),
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: (info) => <Text>{info.getValue()}</Text>,
      }),
      columnHelper.accessor('invite_status', {
        header: 'Invitation',
        cell: (info) => <InvitationStatusTag status={info.getValue()} />,
      }),
      columnHelper.accessor('department', {
        header: 'Department',
        cell: (info) => (
          <DepartmentChooser userId={info.row.original.id} defaultValue={info.getValue().id} />
        ),
      }),
      columnHelper.accessor('id', {
        header: () => {
          return null;
        },
        cell: (info) => {
          if ((info.table.getState() as any).hoveredRowId === info.row.id) {
            return (
              <Box w="40px">
                <Tooltip
                  label="Cannot delete currently logged in user."
                  shouldWrapChildren
                  isDisabled={info.row.original.id !== user.details.id}
                >
                  <IconButton
                    variant="secondary"
                    aria-label="go-to"
                    icon={<Icon as={FaTrash} />}
                    bg="dark5"
                    color="red1"
                    size="sm"
                    onClick={() => onUserDelete(info.row.original.id)}
                    isDisabled={info.row.original.id === user.details.id}
                  />
                </Tooltip>
              </Box>
            );
          }

          return <Box w="40px" />;
        },
      }),
    ],
    [onUserDelete, user],
  );

  return (
    <Table
      data={users}
      columns={columns}
      isLoading={isLoading}
      error={error}
      pagination={pagination}
      empty={<EmptyUsersState />}
      {...rest}
    />
  );
};
