import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props extends Omit<FormControlProps, 'label'> {
  name: string;
  label?: string | React.ReactNode;
  helperText?: string;
}

export const HookedFormControl = (props: React.PropsWithChildren<Props>) => {
  const { name, label, helperText, children, ...rest } = props;
  const { formState } = useFormContext();

  const error = _.get(formState.errors, `${name}.message`) ?? undefined;
  const message = _.isNil(error) ? undefined : String(error);

  return (
    <FormControl isInvalid={!_.isNil(message)} {...rest}>
      {label && (
        <FormLabel fontWeight="600" fontSize="md">
          {label}
        </FormLabel>
      )}
      {children}
      {!message ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage>{message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
