import React from 'react';
import { useController } from 'react-hook-form';
import { Select, SelectProps } from '../forms/select/Select';

interface Props extends Omit<SelectProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedSelect = (props: React.PropsWithChildren<Props>) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return <Select {...rest} onChange={field.onChange} value={field.value} />;
};
