import { Button, ButtonProps, Flex, HStack, Icon, Spacer, Spinner, Text } from '@chakra-ui/react';
import { Listbox } from '@headlessui/react';
import React from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { SelectOption } from './Select';

export interface SelectButtonProps<A> extends Omit<ButtonProps, 'value' | 'children'> {
  selectedValue?: SelectOption<A>;
  options?: SelectOption<A>[];
  isLoading?: boolean;
  children?: (props: {
    selectedValue?: SelectOption<A>;
    options?: SelectOption<A>[];
  }) => React.ReactNode;
}

export const SelectButtonInternal = <A extends unknown>(
  props: SelectButtonProps<A>,
  ref: React.Ref<HTMLInputElement>,
) => {
  const { as, selectedValue, options, isLoading, children, ...rest } = props;

  let content: React.ReactNode;

  if (!selectedValue && rest.placeholder) {
    content = <Text color="neutral4">{rest.placeholder}</Text>;
  } else if (children) {
    content = children({ selectedValue, options });
  } else {
    content = (
      <HStack spacing={2} w="full">
        <Text>{selectedValue?.label}</Text>
        <Spacer />
        <Flex pointerEvents="none" w="20px">
          {isLoading ? <Spinner size="sm" /> : <Icon as={FaCaretDown} fontSize="xs" />}
        </Flex>
      </HStack>
    );
  }

  return (
    <Listbox.Button
      as={props.as ?? Button}
      ref={ref}
      variant="secondary"
      pos="relative"
      w="full"
      cursor="default"
      role="group"
      textAlign="start"
      justifyContent="start"
      fontWeight="normal"
      textTransform="none"
      {...rest}
    >
      {content}
    </Listbox.Button>
  );
};

// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const SelectButton = React.forwardRef(SelectButtonInternal) as <A extends unknown>(
  p: SelectButtonProps<A> & { ref?: React.Ref<HTMLInputElement> },
) => React.ReactElement;
