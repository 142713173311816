import inflection from 'inflection';
import _ from 'lodash';
import React from 'react';
import { RoleResponse } from '../api/queries.schemas';
import { TagOption, TagsFilter } from './TagsFilter';

const roleResponseToTagOption = (role: RoleResponse): TagOption => ({
  value: role,
  label: inflection.humanize(role),
});

interface Props {
  value: RoleResponse[] | undefined;
  onChange: (status: RoleResponse[] | undefined) => void;
}

export const RolesFilter = (props: Props) => {
  const options = _.map(RoleResponse, roleResponseToTagOption);
  const value = _.map(props.value, roleResponseToTagOption);

  const onChange = React.useCallback(
    (values: TagOption[]) => {
      props.onChange(_.map(values, (value) => value.value as RoleResponse));
    },
    [props],
  );

  return <TagsFilter options={options} value={value} onChange={onChange} />;
};
