import { Box, Button, Divider, Heading, Icon, Spacer, Stack, Text } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { OrderDetailResponse } from '../../../api/queries.schemas';
import { ROUTES } from '../../../common/routes';
import { currencyFormat } from '../../../common/utils';
import { BandsList } from '../../../components/BandsList';
import { DescriptionList } from '../../../components/DescriptionList';
import { HumanizedDate } from '../../../components/HumanizedDate';
import { OrderStatusTag } from '../../../components/OrderStatusTag';
import { useAppContext } from '../../../hooks/useAppContext';
import { OrderCapturesList } from './OrderCapturesList';

interface Props {
  order: OrderDetailResponse;
  selectedCaptureId: string | undefined;
  setSelectedCaptureId: (captureId: string | undefined) => void;
}

export const OrderDetailsSidebar = (props: Props) => {
  const { order, selectedCaptureId, setSelectedCaptureId } = props;

  const navigate = useNavigate();
  const { tokenManager } = useAppContext();

  const onReorderClick = React.useCallback(() => {
    navigate(ROUTES.CREATE_ORDER, {
      state: {
        name: `${order.name} - reorder`,
        target: order.target,
        area: order.area,
        productId: order.product.id,
      },
    });
  }, [order, navigate]);

  const onDownloadSpecSheet = React.useCallback(() => {
    tokenManager.refreshToken().then(() => {
      const token = tokenManager.getAccessToken();
      window.open(
        `${axios.defaults.baseURL}/api/products/${order.product.id}/spec_sheet?api_key=${token}`,
        '_blank',
      );
    });
  }, [order, tokenManager]);

  return (
    <Stack bg="dark3" w="360px" p={6} spacing={6} overflowY="auto">
      <Heading size="lg">{order.name}</Heading>

      <DescriptionList
        items={[
          {
            title: 'Date',
            content: <HumanizedDate date={order.created_at} />,
          },
          {
            title: 'Status',
            content: <OrderStatusTag status={order.status} />,
          },
          {
            title: 'Cost',
            content: <Text>{currencyFormat(order.price)}</Text>,
          },
          {
            title: 'Product',
            content: <Text>{order.product.name}</Text>,
          },
          {
            title: 'Bands',
            content: <BandsList bands={order.product.bands} />,
          },
          {
            title: 'Resolution',
            content: <Text>{order.product.resolution} m</Text>,
          },
        ]}
      />

      <Button variant="link" leftIcon={<Icon as={FaDownload} />} onClick={onDownloadSpecSheet}>
        Download spec sheet
      </Button>
      <Divider />

      <OrderCapturesList
        captures={order.captures}
        selectedCaptureId={selectedCaptureId}
        setSelectedCaptureId={setSelectedCaptureId}
      />

      <Spacer />
      <Box w="full">
        <Button w="full" onClick={onReorderClick}>
          Re-Order
        </Button>
      </Box>
    </Stack>
  );
};
