import { Box, BoxProps, HStack, Spacer } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

export const Card = (props: React.PropsWithChildren<Props>) => {
  const { leftContent, rightContent, children, ...rest } = props;

  return (
    <Box px={8} py={6} rounded="md" borderWidth="1px" borderColor="dark5" {...rest}>
      <HStack>
        <Box>{leftContent}</Box>
        <Spacer />
        <Box>{rightContent}</Box>
      </HStack>
      <Box>{children}</Box>
    </Box>
  );
};
