import { Wrap } from '@chakra-ui/react';
import _ from 'lodash';
import { BandResponse } from '../api/queries.schemas';
import { BandTag } from './BandTag';

interface Props {
  bands: BandResponse[];
}

export const BandsList = (props: Props) => {
  return (
    <Wrap shouldWrapChildren>
      {_.map(props.bands, (band) => (
        <BandTag key={band.name} size="sm" band={band} />
      ))}
    </Wrap>
  );
};
