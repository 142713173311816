import _ from 'lodash';
import React from 'react';
import { useDepartmentListDepartments } from '../api/queries';
import { DepartmentResponse } from '../api/queries.schemas';
import { Error } from './Error';
import { TagOption, TagsFilter } from './TagsFilter';

const departmentResponseToTagOption = (department: DepartmentResponse): TagOption => ({
  value: department.id,
  label: department.name,
});

interface Props {
  value: string[] | undefined;
  onChange: (value: string[] | undefined) => void;
}

export const DepartmentFilter = (props: Props) => {
  const { isLoading, error, data } = useDepartmentListDepartments();

  const onChange = React.useCallback(
    (values: TagOption[]) => {
      props.onChange(_.map(values, (value) => value.value));
    },
    [props],
  );

  const departments = data?.data ?? [];

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Error />;
  }

  const options = _.map(departments, departmentResponseToTagOption);

  const value = _.chain(props.value)
    .map((id) => _.find(departments, { id }))
    .compact()
    .map(departmentResponseToTagOption)
    .value();

  return <TagsFilter options={options} value={value} onChange={onChange} />;
};
