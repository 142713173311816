// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax

import { Result } from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf';
import { Feature, FeatureCollection, Geometry, Polygon, Position } from 'geojson';
import _ from 'lodash';
import mapboxgl, { Map } from 'mapbox-gl';
import { distanceFormat } from './utils';

export const mapStyles = {
  streets: 'mapbox://styles/mapbox/streets-v11',
  satellite: 'mapbox://styles/mapbox/satellite-streets-v11',
} as const;

interface DrawPolygonArguments {
  map: Map;
  coordinates: any;
  customID?: string;
  fillColor?: string;
}

interface DrawMultiPolygonArguments {
  map: Map;
  coordinates: any[];
  customID?: string;
  fillColor?: string;
}

interface DrawPointArguments {
  map: Map;
  coordinates: Position;
  customID?: string;
  POIRadius: number;
  fillColor?: string;
}

export const initialViewState = {
  longitude: 134.3545267,
  latitude: -25.6082184,
  zoom: 3.5,
};

function dtheta(coords: turf.Coord[], a: number, b: number) {
  // For bearings +180 is clockwise, -180 is counterclockwise
  const a1 = turf.bearing(coords[a], coords[(a + 1) % coords.length]);
  const a2 = turf.bearing(coords[b], coords[(b + 1) % coords.length]);

  // Difference between bearings in counterclockwise degrees
  const delta = -a2 - -a1;

  // Convert to radians, make limits 0 to 2*pi
  const limit = 2 * Math.PI;
  return (limit + (Math.PI * delta) / 180) % limit;
}

export const polygonDiameter = (geojson: Polygon) => {
  // Build a convex hull for the feature
  const points = turf.points(turf.coordAll(geojson));
  const concavePolygon = turf.concave(points);

  if (!concavePolygon) {
    throw new Error('Not a concave polygon');
  }

  const coords: turf.Position[] = turf.coordAll(concavePolygon);

  // Through analysis (Chrome devtools), it seems that concaveman
  // will return coordinates in the opposite order of what we need.
  //
  // Flip them to be consistent with our algorithm.
  coords.reverse();

  let max = 0;
  const addToMax = (coords: [turf.Position, turf.Position]) => {
    const [a, b] = coords;
    max = Math.max(max, turf.distance(a, b));
  };

  let i = 0;
  let j = 1;
  while (i !== j && dtheta(coords, i, j) < Math.PI) {
    j = (j + 1) % coords.length;
  }

  addToMax([coords[i], coords[j]]);

  while (j !== 0) {
    const a = 2 * Math.PI - dtheta(coords, i, j);
    if (a === Math.PI) {
      addToMax([coords[(i + 1) % coords.length], coords[j]]);
      addToMax([coords[i], coords[(j + 1) % coords.length]]);
      addToMax([coords[(i + 1) % coords.length], coords[(j + 1) % coords.length]]);

      i = (i + 1) % coords.length;
      j = (j + 1) % coords.length;
    } else if (a < Math.PI) {
      addToMax([coords[(i + 1) % coords.length], coords[j]]);
      i = (i + 1) % coords.length;
    } else {
      addToMax([coords[i], coords[(j + 1) % coords.length]]);
      j = (j + 1) % coords.length;
    }
  }

  return max;
};

export const drawPolygon = ({
  coordinates,
  customID = '1',
  map,
  fillColor = '#0080ff',
}: DrawPolygonArguments) => {
  const polygonId = `polygon-${customID}`;
  const polygonOutlineId = `outline-${customID}`;

  map.addSource(polygonId, {
    type: 'geojson',
    data: {
      properties: null,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates,
      },
    },
  });

  map.addLayer({
    id: polygonId,
    type: 'fill',
    source: polygonId,
    layout: {},
    paint: {
      'fill-color': fillColor,
      'fill-opacity': 0.3,
    },
  });

  map.addLayer({
    id: polygonOutlineId,
    type: 'line',
    source: polygonId,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 1,
    },
  });
};

export const drawMultiPolygon = ({
  coordinates,
  customID = '1',
  map,
  fillColor = '#0080ff',
}: DrawMultiPolygonArguments) => {
  return _.each(coordinates, (coordinate, index) => {
    drawPolygon({
      coordinates: coordinate,
      customID: `${customID}-${index}`,
      map,
      fillColor,
    });
  });
};

export const drawPoint = ({
  map,
  coordinates,
  customID = '1',
  POIRadius,
  fillColor = '#0080ff',
}: DrawPointArguments) => {
  const sourceId = `circle-${customID}`;
  const fillId = `circle-fill-${customID}`;
  const outlineID = `circle-outline-${customID}`;

  const feature = {
    geometry: {
      coordinates,
      type: 'Point',
    },
    properties: null,
    type: 'Feature',
  };

  //@ts-ignore
  const circle = turf.circle(feature, POIRadius, {
    units: 'meters',
  });
  map.addSource(sourceId, {
    type: 'geojson',
    data: circle,
  });

  map.addLayer({
    id: fillId,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': fillColor,
      'fill-opacity': 0.3,
    },
  });

  map.addLayer({
    id: outlineID,
    type: 'line',
    source: sourceId,
    layout: {},
    paint: {
      'line-color': '#000',
      'line-width': 1,
    },
  });
};

export function getArea(feature: Feature, POIRadius: number): number | null {
  switch (feature.geometry.type) {
    case 'Point': {
      return turf.convertArea(Math.PI * POIRadius ** 2, 'meters', 'kilometers');
    }

    case 'Polygon': {
      return turf.convertArea(turf.area(feature), 'meters', 'kilometers');
    }

    case 'MultiPolygon': {
      return turf.convertArea(turf.area(feature), 'meters', 'kilometers');
    }

    default:
      return null;
  }
}

export function addEditableLayer(
  map: Map,
  geometry: Geometry,
  POIRadius: number,
): FeatureCollection {
  if (geometry.type === 'Point') {
    if (!map.getSource('circle')) {
      const circle = turf.circle(
        {
          id: '1eb47a7cb205999f120e442d87c9d918',
          type: 'Feature',
          properties: {},
          geometry: geometry,
        },
        POIRadius,
        {
          units: 'meters',
        },
      );

      map.addSource('circle', {
        type: 'geojson',
        data: circle,
      });

      map.addLayer({
        id: 'circle-fill',
        type: 'fill',
        source: 'circle',
        paint: {
          'fill-color': 'orange',
          'fill-opacity': 0.3,
        },
      });
    }
  }

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry,
        properties: [],
      },
    ],
  };
}

export function zoomToFeature(map: Map, geometry: Geometry) {
  //@ts-ignore
  map.fitBounds(turf.bbox(geometry), {
    padding: 150,
    ...(geometry.type === 'Point' && { zoom: 12 }),
  });
}

export function drawPreviewLayer(map: mapboxgl.Map, geometry: Geometry, POIRadius: number) {
  if (geometry.type === 'Polygon') {
    drawPolygon({
      map,
      coordinates: geometry.coordinates,
      customID: 'footprint',
    });
  }

  if (geometry.type === 'MultiPolygon') {
    drawMultiPolygon({
      map,
      coordinates: geometry.coordinates,
      customID: 'footprint',
    });
  }

  if (geometry.type === 'Point') {
    drawPoint({
      map,
      coordinates: geometry.coordinates,
      customID: 'footprint',
      POIRadius,
    });
  }

  zoomToFeature(map, geometry);
}

export function drawPreviewTargetLayer(map: mapboxgl.Map, geometry: Geometry, POIRadius: number) {
  if (geometry.type === 'Polygon') {
    drawPolygon({
      map: map,
      coordinates: geometry.coordinates,
      customID: 'target',
      fillColor: 'red',
    });
  }

  if (geometry.type === 'Point') {
    drawPoint({
      map: map,
      coordinates: geometry.coordinates,
      customID: 'target',
      fillColor: 'red',
      POIRadius,
    });
  }

  zoomToFeature(map, geometry);
}

export function removeOldLayers(map: mapboxgl.Map) {
  const layers = map.getStyle().layers;
  const sources = map.getStyle().sources;

  _.each(['target', 'footprint'], (layerName) => {
    const layersToDelete = _.filter(layers, (layer) => layer.id.includes(layerName));
    const sourcesToDelete = _.filter(_.keys(sources), (sourceId) => sourceId.includes(layerName));

    // Remove layers first, then remove sources.
    // We cannot delete sources until layers are deleted.
    _.each(layersToDelete, (layerToDelete) => {
      map.removeLayer(layerToDelete.id);
    });

    _.each(sourcesToDelete, (sourceId) => {
      map.removeSource(sourceId);
    });
  });
}

export const polygonLatLngValid = (polygon: Polygon) => {
  const coordinates = turf.coordAll(polygon);

  const isValid = _.every(coordinates, (coordinate) => {
    const [longitude, latitude] = coordinate;

    if (longitude > 180 || longitude < -180) {
      return false;
    }

    if (latitude > 90 || latitude < -90) {
      return false;
    }

    return true;
  });

  if (!isValid) {
    throw new Error(
      'Feature coordinates are invalid. Some latitude and longitude values are out of range.',
    );
  }

  return true;
};

export const polygonDiameterValid = (polygon: Polygon, AOIMaxRadius: number) => {
  const diameter = polygonDiameter(polygon);
  const isValid = diameter <= (AOIMaxRadius / 1000) * 2;

  if (!isValid) {
    throw new Error(
      `Polygon is invalid. Diameter of polygon (${distanceFormat(
        diameter,
      )}) cannot be larger than ${distanceFormat((AOIMaxRadius * 2) / 1000)}`,
    );
  }

  return true;
};

export const polygonKinksValid = (polygon: Polygon) => {
  const kinks = turf.kinks(polygon);

  if (kinks.features.length > 0) {
    throw new Error(`Polygon is invalid. There are coordinates that are self intersecting.`);
  }

  return true;
};

export const isPolygonValid = (polygon: Polygon, AOIMaxRadius: number) => {
  return (
    polygonLatLngValid(polygon) &&
    polygonDiameterValid(polygon, AOIMaxRadius) &&
    polygonKinksValid(polygon)
  );
};

const coordinateFeature = (lng: number, lat: number): Result => {
  return {
    center: [lng, lat],
    geometry: {
      type: 'Point',
      coordinates: [lng, lat],
    },
    place_name: 'Lat: ' + lat + ' Lng: ' + lng,
    place_type: ['coordinate'],
    properties: {},
    type: 'Feature',
  } as Result;
};

export const coordinatesGeocoder = (query: string): Result[] => {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
  if (!matches) {
    return [];
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes: Result[] = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};
