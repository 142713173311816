import { chakra } from '@chakra-ui/react';
import { Listbox } from '@headlessui/react';
import _ from 'lodash';
import React from 'react';
import { FloatingPopover } from '../../FloatingPopover';

export interface SelectOption<A> {
  value: string;
  label: string;
  raw: A;
}

export interface SelectProps {
  value?: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

export const Select = <A extends unknown>(props: React.PropsWithChildren<SelectProps>) => {
  const { value, onChange, isDisabled, children } = props;

  const [SelectButton, Menu] = React.Children.toArray(children) as React.ReactElement[];

  if (!SelectButton || !Menu) {
    return null;
  }

  const options: SelectOption<A>[] = React.Children.map(Menu.props.children, (child) => ({
    value: child.props.value,
    label: child.props.label,
    raw: child.props.raw,
  }));

  const selectedValue = _.find(options, { value });

  return (
    <Listbox as={chakra.div} value={value ?? ''} onChange={onChange} disabled={isDisabled}>
      {({ open }) => (
        <FloatingPopover
          open={open}
          placement="bottom-start"
          isRoot={false}
          render={() => React.cloneElement(Menu, { open })}
        >
          {React.cloneElement(SelectButton, {
            selectedValue,
            options,
          })}
        </FloatingPopover>
      )}
    </Listbox>
  );
};
