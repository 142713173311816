const baseStyle = () => ({
  tbody: {
    tr: {
      _hover: {
        bg: 'dark7',
      },
    },
  },
});

export const Table = {
  baseStyle,
};
