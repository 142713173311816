import { Icon, IconButton, Text } from '@chakra-ui/react';
import React from 'react';
import { FaMapPin } from 'react-icons/fa';
import { MapDrawActions } from '../hooks/useMapDrawActions';
import { ControlTooltip } from './ControlTooltip';

const MODE = 'draw_point';

interface Props {
  actions: MapDrawActions;
}

export const PointControl = (props: Props) => {
  const { actions } = props;

  const onClick = React.useCallback(() => {
    actions.draw.changeMode(MODE);
  }, [actions]);
  return (
    <ControlTooltip
      title="Place a point"
      content={<Text>Click a point on the map to create a point radius</Text>}
    >
      <IconButton
        aria-label="point"
        variant="secondary"
        rounded="md"
        icon={<Icon as={FaMapPin} />}
        size="lg"
        onClick={onClick}
      />
    </ControlTooltip>
  );
};
