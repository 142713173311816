import { StyleFunctionProps, SystemStyleFunction } from '@chakra-ui/react';

const baseStyleDialog: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props;

  return {
    borderRadius: 'md',
    bg: 'dark3',
    color: 'inherit',
    my: '3.75rem',
    zIndex: 'modal',
    maxH: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined,
  };
};

const baseStyle = (props: StyleFunctionProps) => ({
  dialog: baseStyleDialog(props),
});

export const Modal = {
  baseStyle,
};
