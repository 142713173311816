import {
  Box,
  Flex,
  Spacer,
  Table as ChakraTable,
  TableCaption,
  TableProps as ChakraTableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';
import { PaginationOptions } from '../common/pagination';
import { Empty } from './Empty';
import { Error } from './Error';
import { PaginationControls } from './PaginationControls';
import { Spin } from './Spin';

export interface TableProps<TData, B> extends ChakraTableProps {
  data: TData[];
  columns: ColumnDef<TData, B>[];
  isLoading?: boolean;
  error?: Error | null;
  empty?: React.ReactElement;
  pagination?: PaginationOptions;
  showHeader?: boolean;
  bordered?: boolean;
  onRowClick?: (row: Row<TData>) => void;
}

export const Table = <A extends unknown, B extends unknown>(props: TableProps<A, B>) => {
  const {
    data,
    columns,
    isLoading,
    error,
    pagination,
    empty,
    showHeader,
    bordered,
    onRowClick,
    ...rest
  } = props;

  const isEmpty = _.isEmpty(data);

  const onPaginationChange = (
    state: PaginationState | ((old: PaginationState) => PaginationState),
  ) => {
    if (_.isFunction(state)) {
      return;
    }

    if (pagination) {
      pagination.onPageSizeChange(state.pageSize);
      pagination.gotoPage(state.pageIndex);
    }
  };

  const { getHeaderGroups, getRowModel, setState } = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: pagination?.pageSize,
    state: pagination && {
      pagination: {
        pageIndex: pagination.currentPage,
        pageSize: pagination.pageSize,
      },
    },
    onPaginationChange: onPaginationChange,
  });

  const TableContent = () => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error />;
    }

    if (isEmpty) {
      return empty ?? <Empty />;
    }

    return null;
  };

  return (
    <Flex h="full" w="full" flexDir="column" {...rest}>
      <ChakraTable size="lg">
        <TableCaption placement="bottom" px={0}>
          <TableContent />
        </TableCaption>
        {(showHeader ?? true) && (
          <Thead>
            {getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    color="neutral3"
                    borderColor="dark1"
                    fontSize="14px"
                    fontWeight="semibold"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
        )}

        {!isLoading && !error ? (
          <Tbody>
            {getRowModel().rows.map((row) => {
              return (
                <Tr
                  key={row.id}
                  onClick={() => onRowClick?.(row)}
                  onMouseEnter={() => setState((old) => ({ ...old, hoveredRowId: row.id }))}
                  onMouseLeave={() => setState((old) => ({ ...old, hoveredRowId: undefined }))}
                  cursor={onRowClick ? 'pointer' : 'default'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} borderColor={bordered ?? true ? 'dark1' : 'transparent'}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        ) : null}
      </ChakraTable>

      <Spacer />
      <Box>{pagination && <PaginationControls pagination={pagination} />}</Box>
    </Flex>
  );
};
