import { cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = () => ({
  content: {
    bg: 'dark3',
    [$arrowBg.variable]: 'colors.dark3',
    borderColor: 'dark4',
    p: 3,
  },
});

const variants = {
  responsive: {
    popper: {
      zIndex: '1700',
      maxWidth: 'unset',
      width: 'unset',
    },
  },
};

export const Popover = {
  variants,
  baseStyle,
};
