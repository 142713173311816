import { Button, Icon } from '@chakra-ui/react';
import { Empty } from '../../../components/Empty';
import { ReactComponent as EmptyOrders } from '../../../assets/empty-orders.svg';
import { FaPlus } from 'react-icons/fa';
import { ROUTES } from '../../../common/routes';
import { Link } from 'react-router-dom';

interface Props {
  showButton?: boolean;
}

export const EmptyOrdersState = (props: Props) => {
  const { showButton } = props;

  return (
    <Empty
      title="No orders found"
      icon={<Icon fontSize="96px" as={EmptyOrders} />}
      extra={
        (showButton ?? true) && (
          <Link to={ROUTES.CREATE_ORDER}>
            <Button leftIcon={<Icon as={FaPlus} />}>Create order</Button>
          </Link>
        )
      }
    />
  );
};
