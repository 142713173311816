import { HStack, Tag, TagProps, Text, Tooltip } from '@chakra-ui/react';
import { BandResponse } from '../api/queries.schemas';
import { wavelengthToColor } from '../common/utils';

interface Props extends TagProps {
  band: BandResponse;
}

export const BandTag = (props: Props) => {
  const { band, ...rest } = props;

  const bgColor = wavelengthToColor(band.wavelength, '0.4');
  const textColor = wavelengthToColor(band.wavelength, '0.8');

  return (
    <Tooltip
      label={
        <HStack>
          <Text color="neutral6" fontWeight="bold">
            Wavelength:
          </Text>
          <Text color="neutral6">{band.wavelength}</Text>
        </HStack>
      }
    >
      <Tag variant="solid" bg={bgColor} color={textColor} {...rest}>
        {band.name}
      </Tag>
    </Tooltip>
  );
};
