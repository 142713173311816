const baseStyle = {
  track: {
    rounded: 'lg',
    bg: 'dark5',
  },
  filledTrack: {
    bg: 'primary1',
  },
};

export const Progress = {
  baseStyle,
};
