import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ControlPosition, useControl } from 'react-map-gl';
import { coordinatesGeocoder } from '../../../common/mapbox';

type Props = {
  position?: ControlPosition;
} & MapboxGeocoder.GeocoderOptions;

export const GeoCoderControl = (props: Props) => {
  useControl(
    () =>
      new MapboxGeocoder({
        ...props,

        localGeocoder: coordinatesGeocoder,
        reverseGeocode: true,
      }),
    {
      position: props.position,
    },
  );

  return null;
};
