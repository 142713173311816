import { ReactElement } from 'react';
import { Flex, Heading, Icon, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import { FaDollarSign, FaEnvira, FaRegClock } from 'react-icons/fa';
import { BsArrowRepeat } from 'react-icons/bs';
import { FiAperture } from 'react-icons/fi';
import { GiAustralia } from 'react-icons/gi';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack align="center" color="black">
      <Flex w={16} h={16} align="center" justify="center" color="primary1" mb={1}>
        {icon}
      </Flex>
      <Heading size="md">{title}</Heading>
      <Text textAlign={'center'}>{text}</Text>
    </Stack>
  );
};

export const FeatureList = () => {
  return (
    <VStack spacing={10}>
      <Heading textAlign={'center'}>Delivering the right data, at the right time</Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={FaRegClock} w={10} h={10} />}
          title="Faster"
          text="Delivery in as little as 36 hours from order."
        />
        <Feature
          icon={<Icon as={BsArrowRepeat} w={10} h={10} />}
          title="3 Revisits/day"
          text="Multiple opportunities for capture each day."
        />
        <Feature
          icon={<Icon as={FaDollarSign} w={10} h={10} />}
          title="Cost Effective"
          text="Data at a fraction of the cost of aerial imagery."
        />
        <Feature
          icon={<Icon as={FiAperture} w={10} h={10} />}
          title="High Resolution"
          text="Satellite imagery on demand, at up to 70cm resolution."
        />
        <Feature
          icon={<Icon as={GiAustralia} w={10} h={10} />}
          title="Australian"
          text="Developed in Sydney by Spiral Blue, an Australian company."
        />
        <Feature
          icon={<Icon as={FaEnvira} w={10} h={10} />}
          title="Eco-Friendly"
          text="Less CO₂ emissions per image compared to aerial imagery."
        />
      </SimpleGrid>
    </VStack>
  );
};
