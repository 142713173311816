const baseStyle = () => ({
  container: {
    bg: 'neutral5',
    color: 'neutral2',
    borderRadius: '4px',
    fontWeight: '600',
  },
});

export const Avatar = {
  baseStyle,
};
