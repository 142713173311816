import _ from 'lodash';
import React from 'react';
import { OrderStatusResponse } from '../api/queries.schemas';
import { TagOption, TagsFilter } from './TagsFilter';
import inflection from 'inflection';

const statusResponseToTagOption = (status: OrderStatusResponse): TagOption => ({
  value: status,
  label: inflection.humanize(status),
});

interface Props {
  value: OrderStatusResponse[] | undefined;
  onChange: (status: OrderStatusResponse[] | undefined) => void;
}

export const OrderStatusFilter = (props: Props) => {
  const options = _.map(OrderStatusResponse, statusResponseToTagOption);
  const value = _.map(props.value, statusResponseToTagOption);

  const onChange = React.useCallback(
    (values: TagOption[]) => {
      props.onChange(_.map(values, (value) => value.value as OrderStatusResponse));
    },
    [props],
  );

  return <TagsFilter options={options} value={value} onChange={onChange} />;
};
