import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Queries } from '../../api';
import { Error } from '../../components/Error';
import { Mapbox } from '../../components/maps/Mapbox';
import { Spin } from '../../components/Spin';
import { stringParam, useQueryParams } from '../../hooks/useQueryParams';
import { useRequiredParams } from '../../hooks/useRequiredParams';
import { OrderDetailsSidebar } from './components/OrderDetailsSidebar';

export const Order = () => {
  const { id } = useRequiredParams<{ id: string }>();
  const [decodedParams, setParams] = useQueryParams({
    captureId: stringParam,
  });

  const setSelectedCaptureId = React.useCallback(
    (captureId: string | undefined) => {
      if (decodedParams.captureId === captureId) {
        setParams({ captureId: undefined });
      } else {
        setParams({ captureId });
      }
    },
    [decodedParams, setParams],
  );

  const { isLoading, error, data } = Queries.useOrdersGetOrder(id);

  const order = data?.data;

  if (isLoading) {
    return <Spin />;
  }

  if (error || !order) {
    return <Error title="Error fetching order information" />;
  }

  const selectedCaptureId = decodedParams.captureId;
  const selectedCapture = order.captures.find((c) => c.id === selectedCaptureId) ?? null;

  return (
    <Flex flexDir="row" h="full">
      <OrderDetailsSidebar
        order={order}
        selectedCaptureId={selectedCaptureId}
        setSelectedCaptureId={setSelectedCaptureId}
      />
      <Box flexGrow={1} h="full" pos="relative">
        <Mapbox
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!}
          layer={undefined}
          POIRadius={order.product.poi_radius}
          AOIMaxRadius={order.product.aoi_max_radius}
          previewLayer={selectedCapture?.footprint}
          previewTargetLayer={order.target}
        />
      </Box>
    </Flex>
  );
};
