export const ROUTES = {
  ORDERS: '/orders',
  ORDER: '/order',
  USERS: '/users',
  DASHBOARD: '/dashboard',
  CREATE_ORDER: '/create-order',
  LOGIN: '/login',
  INVITE: '/invite',
  CHANGE_PASSWORD: '/change-password',
  HOME: '/',
};
