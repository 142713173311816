import { Button, FormControl, FormLabel, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { DepartmentFilter } from '../../../components/DepartmentFilter';
import { InviteStatusFilter } from '../../../components/InviteStatusFilter';
import { RolesFilter } from '../../../components/RolesFilter';
import { SlideDrawer } from '../../../components/SlideDrawer';
import { UsersFilter } from '../hooks/useUsersFilter';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  filter: UsersFilter;
}

export const UsersFilterDrawer = (props: Props) => {
  const { isOpen, onClose, filter } = props;

  return (
    <SlideDrawer isOpen={isOpen} onClose={onClose}>
      <Stack spacing={8}>
        <HStack>
          <Text fontWeight="semibold" fontSize="18px">
            Filters
          </Text>
          <Spacer />
          <Button variant="link" onClick={() => filter.clearFilter()}>
            Clear all
          </Button>
        </HStack>

        <FormControl>
          <FormLabel fontWeight="semibold">Invite status</FormLabel>
          <InviteStatusFilter value={filter.inviteStatus} onChange={filter.setInviteStatus} />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="semibold">Roles</FormLabel>
          <RolesFilter value={filter.roles} onChange={filter.setRoles} />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="semibold">Departments</FormLabel>
          <DepartmentFilter value={filter.department} onChange={filter.setDepartment} />
        </FormControl>
      </Stack>
    </SlideDrawer>
  );
};
