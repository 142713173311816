import React from 'react';
import { RoleResponse } from '../api/queries.schemas';
import { useRole } from '../hooks/useRole';
import { Unauthorized } from './Unauthorized';

interface Props {
  role: RoleResponse[] | RoleResponse;
}

export const PermissionRoute = (props: React.PropsWithChildren<Props>) => {
  const { children, role } = props;
  const hasPermission = useRole(role);

  if (!hasPermission) {
    return <Unauthorized />;
  }

  return <>{children}</>;
};
