import { Icon, IconButton, Text } from '@chakra-ui/react';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { MapDrawActions } from '../hooks/useMapDrawActions';
import { ControlTooltip } from './ControlTooltip';

interface Props {
  actions: MapDrawActions;
}

export const ClearControl = (props: Props) => {
  const { actions } = props;

  const onClick = React.useCallback(() => {
    actions.draw.trash();
  }, [actions]);

  return (
    <ControlTooltip
      title="Remove features"
      content={<Text>Click to remove all features drawn on the map</Text>}
    >
      <IconButton
        aria-label="clear"
        variant="secondary"
        icon={<Icon as={FaTrash} />}
        size="lg"
        rounded="md"
        onClick={onClick}
      />
    </ControlTooltip>
  );
};
