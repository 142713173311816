import { Box, Flex } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueries } from '@tanstack/react-query';
import { Polygon } from 'geojson';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Queries } from '../../api';
import { CreateOrderResponseTarget, ProductDetailResponse } from '../../api/queries.schemas';
import { isPolygonValid } from '../../common/mapbox';
import { currencyFormat } from '../../common/utils';
import { Error } from '../../components/Error';
import { Mapbox } from '../../components/maps/Mapbox';
import { Spin } from '../../components/Spin';
import { OrderData, SelectAreaPayload } from '../../types/main';
import { ConfirmOrderModal } from './components/ConfirmOrderModal';
import { CreateOrderSidebar } from './components/CreateOrderSidebar';

export type CreateOrderFormValues = {
  name: string | undefined;
  target:
    | (CreateOrderResponseTarget & {
        price: number;
        balance: number;
      })
    | undefined;
  product: ProductDetailResponse;
  area: number | undefined;
};

export const CreateOrder = () => {
  const recreateOrderData = useLocation().state as CreateOrderFormValues | null;

  const queries = useQueries({
    queries: [Queries.useProductsListProducts(), Queries.useBalance()],
  });

  const isLoading = _.some(queries, (u) => u.isLoading);
  const isError = _.some(queries, (u) => u.isError);
  const [productData, balanceData] = queries;

  const products = productData?.data?.data ?? [];
  const balance = balanceData?.data?.data;

  const defaultProduct = _.head(products);

  const validationSchema = Yup.object({
    name: Yup.string().required('Capture name is a required field'),
    target: Yup.object({
      type: Yup.string(),
      coordinates: Yup.array().required(),
      balance: Yup.number(),
      price: Yup.number(),
    })
      .test('polygonValid', (value, context) => {
        if (value.type === 'Polygon') {
          try {
            return isPolygonValid(value as Polygon, context.parent.product.aoi_max_radius);
          } catch (error: any) {
            return context.createError({ message: error.message });
          }
        }
        return true;
      })
      .test('balanceValid', (value, context) => {
        if (!!value.price && !!value.balance && value.price > value.balance) {
          return context.createError({
            message: `Insufficient balance. Balance (${currencyFormat(
              value.balance,
            )}) cannot be less than price (${currencyFormat(value.price)}).`,
          });
        }

        return true;
      })
      .required('Capture target is a required field'),
    area: Yup.number().required('Capture area is a required field'),
  });

  const methods = useForm<CreateOrderFormValues>({
    defaultValues: recreateOrderData ?? {
      name: undefined,
      product: undefined,
      area: undefined,
      target: undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const area = methods.watch('area');
  const target = methods.watch('target');
  const product = methods.watch('product');

  React.useEffect(() => {
    if (defaultProduct) {
      methods.setValue('product', defaultProduct);
    }
  }, [methods, defaultProduct]);

  const orderData = React.useMemo<OrderData | null>(() => {
    if (!product || !balance) {
      return null;
    }

    const areaToCalculateWith = area ?? 0;
    const availableCredits =
      balance.balance - balance.pending_balance > 0 ? balance.balance - balance.pending_balance : 0;
    const priceOfArea = areaToCalculateWith * product.price_per_km2!;
    const minimumPrice = product.price_per_km2! * product.minimum_area!;
    const price = areaToCalculateWith > 0 ? Math.max(priceOfArea, minimumPrice) : 0;

    return {
      product: product,
      total: balance.balance,
      pending: balance.pending_balance,
      available: availableCredits,
      area: areaToCalculateWith,
      price: price,
      remainingCredits: availableCredits - priceOfArea,
    };
  }, [area, balance, product]);

  React.useEffect(() => {
    if (orderData) {
      methods.setValue('target.balance', orderData.available);
      methods.setValue('target.price', orderData.price);
    }
  }, [methods, orderData]);

  const onSubmit = React.useCallback(
    (formData: CreateOrderFormValues) => {
      NiceModal.show(ConfirmOrderModal, { formData, orderData });
    },
    [orderData],
  );

  const onSelectArea = React.useCallback(
    (payload: SelectAreaPayload) => {
      methods.setValue('target', (payload.geometry as any) ?? undefined, {
        shouldDirty: true,
        shouldValidate: true,
      });
      methods.setValue('area', payload.area ?? undefined, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [methods],
  );

  // If there is recreateOrderData, then trigger validation once everything is loaded
  React.useEffect(() => {
    if (!isLoading && !isError && orderData && product && balance && recreateOrderData) {
      methods.trigger();
    }
  }, [isLoading, isError, orderData, product, balance, recreateOrderData, methods]);

  if (isLoading) {
    return <Spin />;
  }

  if (isError || !orderData || !product || !balance) {
    return <Error title="Error fetching product information" />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Flex flexDir="row" h="full">
          <CreateOrderSidebar
            orderData={orderData}
            products={products}
            forceProductChoice={!recreateOrderData}
          />
          <Box flexGrow={1} h="full" pos="relative">
            <Mapbox
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!}
              layer={recreateOrderData?.target}
              previewTargetLayer={target}
              POIRadius={product.poi_radius}
              AOIMaxRadius={product.aoi_max_radius}
              onSelectArea={onSelectArea}
              isEdit
            />
          </Box>
        </Flex>
      </form>
    </FormProvider>
  );
};
