import inflection from 'inflection';
import _ from 'lodash';
import React from 'react';
import { InvitationStatusResponse } from '../api/queries.schemas';
import { TagOption, TagsFilter } from './TagsFilter';

const invitationResponseToTagOption = (status: InvitationStatusResponse): TagOption => ({
  value: status,
  label: inflection.humanize(status),
});

interface Props {
  value: InvitationStatusResponse[] | undefined;
  onChange: (status: InvitationStatusResponse[] | undefined) => void;
}

export const InviteStatusFilter = (props: Props) => {
  const options = _.map(InvitationStatusResponse, invitationResponseToTagOption);
  const value = _.map(props.value, invitationResponseToTagOption);

  const onChange = React.useCallback(
    (values: TagOption[]) => {
      props.onChange(_.map(values, (value) => value.value as InvitationStatusResponse));
    },
    [props],
  );

  return <TagsFilter options={options} value={value} onChange={onChange} />;
};
