import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { FaCaretDown, FaCaretUp, FaDownload } from 'react-icons/fa';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { CaptureResponse } from '../../../api/queries.schemas';
import FallbackImage from '../../../assets/missing-capture.webp';
import { CaptureStatusTag } from '../../../components/CaptureStatusTag';
import { HumanizedDate } from '../../../components/HumanizedDate';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props extends BoxProps {
  capture: CaptureResponse;
  isSelected: boolean;
  onClick: () => void;
}

export const OrderCaptureAttempt = (props: Props) => {
  const { capture, isSelected, onClick, ...rest } = props;
  const { tokenManager } = useAppContext();

  const onDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    tokenManager.refreshToken().then(() => {
      const token = tokenManager.getAccessToken();
      window.open(
        `${axios.defaults.baseURL}/api/captures/${capture.id}/data?api_key=${token}`,
        '_blank',
      );
    });
  };

  const onPreviewClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    tokenManager.refreshToken().then(() => {
      const token = tokenManager.getAccessToken();
      window.open(
        `${axios.defaults.baseURL}/api/captures/${capture.id}/preview?api_key=${token}`,
        '_blank',
      );
    });
  };

  const downloadSize = capture.data?.size
    ? `${(capture.data.size / 1000000).toFixed(0)}MB`
    : 'Unknown size';

  return (
    <Box
      p={2}
      as={Button}
      w="full"
      variant="secondary"
      rounded="md"
      borderWidth="1px"
      borderColor="dark5"
      bg="dark3"
      h="full"
      flexDir="column"
      align="start"
      onClick={onClick}
      isActive={isSelected}
      _hover={{
        color: 'inherit',
      }}
      _active={{
        bg: 'dark5',
        borderColor: 'primary1',
      }}
      {...rest}
    >
      <HStack spacing={4} w="full">
        <HStack w="full">
          <Image src={capture.thumbnail} boxSize="64px" rounded="md" fallbackSrc={FallbackImage} />
          <Stack textAlign="left">
            <HumanizedDate date={capture.timestamp} />
            <Spacer />
            <Flex>
              <CaptureStatusTag status={capture.status} />
            </Flex>
          </Stack>
        </HStack>
        <Spacer />
        {capture.data && <Icon as={isSelected ? FaCaretUp : FaCaretDown} />}
      </HStack>
      {capture.data && (
        <Box w="full">
          <Collapse in={isSelected}>
            <Stack w="full" mt={2} spacing={2}>
              <Divider borderColor="neutral5" />
              <HStack w="full">
                <Button
                  size="sm"
                  leftIcon={<Icon as={HiOutlineExternalLink} />}
                  variant="secondary"
                  onClick={onPreviewClick}
                  textTransform="uppercase"
                >
                  Preview
                </Button>
                <Button size="sm" leftIcon={<Icon as={FaDownload} />} onClick={onDownloadClick}>
                  Download ({downloadSize})
                </Button>
              </HStack>
            </Stack>
          </Collapse>
        </Box>
      )}
    </Box>
  );
};
