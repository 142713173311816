import { Button, Icon } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { ReactComponent as EmptyUser } from '../../../assets/empty-users.svg';
import { Empty } from '../../../components/Empty';
import { InviteUserModal } from './InviteUserModal';

interface Props {
  showButton?: boolean;
}

export const EmptyUsersState = (props: Props) => {
  const { showButton } = props;

  const onClickInvite = React.useCallback(() => {
    NiceModal.show(InviteUserModal);
  }, []);

  return (
    <Empty
      title="No users found"
      icon={<Icon fontSize="96px" as={EmptyUser} />}
      extra={
        (showButton ?? true) && (
          <Button leftIcon={<Icon as={FaUser} />} onClick={onClickInvite}>
            Invite people
          </Button>
        )
      }
    />
  );
};
