import { Box, chakra } from '@chakra-ui/react';
import { Listbox } from '@headlessui/react';
import React from 'react';
import { ScrollAreaAutosize, ScrollAreaProps } from '../../ScrollArea';

interface SelectMenuProps extends ScrollAreaProps {
  open?: boolean;
}

export const SelectMenu = (props: React.PropsWithChildren<SelectMenuProps>) => {
  const { open, children, ...rest } = props;

  return (
    <Listbox.Options static as={chakra.ul} zIndex={1800} {...rest}>
      <Box
        w="full"
        h="auto"
        rounded="md"
        bg="dark4"
        boxShadow="rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px"
      >
        <ScrollAreaAutosize maxH="2xs" overflow="auto">
          {children}
        </ScrollAreaAutosize>
      </Box>
    </Listbox.Options>
  );
};
