import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedFormControl } from '../../../components/hooked/HookedFormControl';
import { HookedInput } from '../../../components/hooked/HookedInput';
import * as Yup from 'yup';
import { HookedSubmitButton } from '../../../components/hooked/HookedSubmitButton';
import { Queries } from '../../../api';
import { useAppContext } from '../../../hooks/useAppContext';
import { makeErrorMessage } from '../../../common/utils';

interface FormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Department name is a required field'),
});

export const DepartmentCreateModal = NiceModal.create<{}>(() => {
  const modal = useModal();
  const { successToast, errorToast, queryClient } = useAppContext();

  const methods = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const addDepartmentMutation = Queries.useDepartmentCreateDepartment({
    mutation: {
      onSettled: () =>
        queryClient.invalidateQueries(Queries.getDepartmentListDepartmentsQueryKey()),
    },
  });

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      return addDepartmentMutation
        .mutateAsync({
          data: {
            name: values.name,
          },
        })
        .then(() => {
          successToast(`${values.name} created successfully`);
          modal.hide();
        })
        .catch((err) => {
          errorToast(`Error creating ${values.name} - ${makeErrorMessage(err)}`);
        });
    },
    [successToast, errorToast, modal, addDepartmentMutation],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="lg">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <ModalHeader fontSize="2xl" py={4}>
              Create new department
            </ModalHeader>
            <ModalCloseButton py={8} />
            <ModalBody>
              <Stack>
                <HookedFormControl name="name" label="Department name">
                  <HookedInput name="name" autoFocus />
                </HookedFormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={modal.hide}>
                Cancel
              </Button>
              <HookedSubmitButton>Create</HookedSubmitButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
