import { BoxProps, Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SlideDrawer = (props: React.PropsWithChildren<Props>) => {
  const { isOpen, onClose, children, ...rest } = props;

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={6} {...rest}>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
