import { StyleFunctionProps, SystemStyleFunction } from '@chakra-ui/react';

const variants = {
  solid: {
    container: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '13px',
      rounded: 'sm',
    },
  },
};

const baseStyleContainer: SystemStyleFunction = () => {
  return {
    whiteSpace: 'nowrap',
  };
};

const baseStyle = (props: StyleFunctionProps) => ({
  container: baseStyleContainer(props),
});

export const Tag = {
  variants,
  baseStyle,
};
