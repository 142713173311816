import React from 'react';

export const useDidMountEffect = (
  f: React.EffectCallback,
  deps?: React.DependencyList | undefined,
) => {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) f();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
