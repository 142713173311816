import {
  OrdersListOrdersOrderByItem,
  OrdersListOrdersParams,
  OrderStatusResponse,
} from '../../../api/queries.schemas';
import { PaginationFilter } from '../../../common/pagination';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import {
  arrayOfParam,
  dateParam,
  numberParam,
  stringParam,
  useQueryParams,
} from '../../../hooks/useQueryParams';

const DEFAULT_ORDERS_PAGE_NUMBER = 1;
const DEFAULT_ORDERS_PAGE_SIZE = 10;
const DEFAULT_ORDERS_NAME = undefined;
const DEFAULT_ORDERS_DEPARTMENT = undefined;
const DEFAULT_ORDERS_USER = undefined;
const DEFAULT_ORDERS_STATUS = undefined;
const DEFAULT_ORDERS_ORDER_BY = OrdersListOrdersOrderByItem['-downloads'];
const DEFAULT_ORDERS_START = undefined;
const DEFAULT_ORDERS_END = undefined;

export interface OrdersFilter extends PaginationFilter {
  user: string[] | undefined;
  setUser: (user: string[] | undefined) => void;
  department: string[] | undefined;
  setDepartment: (department: string[] | undefined) => void;
  status: OrderStatusResponse[] | undefined;
  setStatus: (status: OrdersListOrdersParams['status'] | undefined) => void;
  start: Date | undefined;
  setStart: (start: Date | undefined) => void;
  end: Date | undefined;
  setEnd: (start: Date | undefined) => void;
  orderBy: OrdersListOrdersOrderByItem | undefined;
  setOrderBy: (orderBy: OrdersListOrdersOrderByItem | undefined) => void;
  name: OrdersListOrdersParams['search'] | undefined;
  setName: (name: OrdersListOrdersParams['search'] | undefined) => void;
  clearFilters: () => void;
}

export const useOrdersFilter: (options?: Partial<OrdersFilter>) => OrdersFilter = (options) => {
  const [params, setParams] = useQueryParams({
    page: numberParam,
    pageSize: numberParam,
    name: stringParam,
    user: arrayOfParam(stringParam, { delimiter: ',' }),
    department: arrayOfParam(stringParam, { delimiter: ',' }),
    status: arrayOfParam(stringParam, { delimiter: ',' }),
    start: dateParam,
    end: dateParam,
    orderBy: stringParam,
  });

  const setCurrentPageNumber = (value: number) =>
    setParams({
      page: value,
    });

  const setPageSize = (value: number) =>
    setParams({
      pageSize: value,
    });

  // TODO: Is this going to be too spammy
  const setName = useDebouncedCallback(
    (value: string | undefined) =>
      setParams({
        name: value,
        page: 1,
      }),
    0,
  );

  const setDepartment = (value: string[] | undefined) =>
    setParams({
      department: value,
    });

  const setUser = (value: string[] | undefined) =>
    setParams({
      user: value,
    });

  const setStatus = (value: OrderStatusResponse[] | undefined) =>
    setParams({
      status: value,
    });

  const setStart = (value: Date | undefined) =>
    setParams({
      start: value,
    });

  const setEnd = (value: Date | undefined) =>
    setParams({
      end: value,
    });

  const setOrderBy = (value: OrdersListOrdersOrderByItem | undefined) =>
    setParams({
      orderBy: value,
    });

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} orders`;

  const clearFilters = () => {
    setParams({
      page: DEFAULT_ORDERS_PAGE_NUMBER,
      pageSize: DEFAULT_ORDERS_PAGE_SIZE,
      name: DEFAULT_ORDERS_NAME,
      department: DEFAULT_ORDERS_DEPARTMENT,
      user: DEFAULT_ORDERS_USER,
      status: DEFAULT_ORDERS_STATUS,
      start: DEFAULT_ORDERS_START,
      end: DEFAULT_ORDERS_END,
    });
  };

  return {
    pageSizeOptions: options?.pageSizeOptions ?? [5, 10, 20, 30],
    pageSize: params.pageSize ?? options?.pageSize ?? DEFAULT_ORDERS_PAGE_SIZE,
    currentPageNumber: params.page ?? options?.currentPageNumber ?? DEFAULT_ORDERS_PAGE_NUMBER,
    name: params.name ?? DEFAULT_ORDERS_NAME,
    orderBy: (params.orderBy as OrdersListOrdersOrderByItem) ?? DEFAULT_ORDERS_ORDER_BY,
    user: params.user,
    department: params.department,
    status: params.status as OrdersListOrdersParams['status'],
    start: params.start,
    end: params.end,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    setOrderBy,
    setName,
    setUser,
    setDepartment,
    setStatus,
    setEnd,
    setStart,
    clearFilters,
  };
};
