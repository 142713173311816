const baseStyle = () => ({
  list: {
    bg: 'dark4',
    borderColor: 'dark4',
  },
  item: {
    _focus: {
      bg: 'primary2',
    },
    _active: {
      bg: 'primary3',
    },
  },
  divider: {
    borderColor: 'dark7',
    opacity: 1,
  },
  groupTitle: {
    color: 'neutral4',
    textTransform: 'uppercase',
    fontweight: 'semibold',
  },
});

export const Menu = {
  baseStyle,
};
