import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
} from '@chakra-ui/react';
import { RangeCalendar } from '@mantine/dates';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { FaCalendarDay, FaCaretDown } from 'react-icons/fa';
import { useGetCss } from '../../hooks/useGetCss';

const DATE_FORMAT = 'DD/MM/YYYY';
const DEFAULT_PRESETS: DatePreset[] = [
  'today',
  'yesterday',
  'thisWeek',
  'lastWeek',
  'thisMonth',
  'lastMonth',
  'allTime',
];

const renderPresetText = (preset: DatePreset): string => {
  switch (preset) {
    case 'today':
      return 'Today';
    case 'yesterday':
      return 'Yesterday';
    case 'thisWeek':
      return 'This week';
    case 'lastWeek':
      return 'Last week';
    case 'thisMonth':
      return 'This month';
    case 'lastMonth':
      return 'Last month';
    case 'thisQuarter':
      return 'This quarter';
    case 'lastQuarter':
      return 'Last quarter';
    case 'thisYear':
      return 'This year';
    case 'lastYear':
      return 'Last year';
    case 'allTime':
      return 'All time';
  }
};

const getPresetDateRange = (preset: DatePreset): DateRange => {
  switch (preset) {
    case 'today':
      return [dayjs().toDate(), dayjs().toDate()];
    case 'yesterday':
      return [dayjs().subtract(1, 'day').toDate(), dayjs().subtract(1, 'day').toDate()];
    case 'thisWeek':
      return [
        dayjs().startOf('week').add(1, 'day').toDate(),
        dayjs().endOf('week').add(1, 'day').toDate(),
      ];
    case 'lastWeek':
      return [
        dayjs().subtract(7, 'day').startOf('week').add(1, 'day').toDate(),
        dayjs().subtract(7, 'day').endOf('week').add(1, 'day').toDate(),
      ];
    case 'thisMonth':
      return [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()];
    case 'lastMonth':
      return [
        dayjs().subtract(1, 'month').startOf('month').toDate(),
        dayjs().subtract(1, 'month').endOf('month').toDate(),
      ];
    case 'thisQuarter':
      return [dayjs().startOf('quarter').toDate(), dayjs().endOf('quarter').toDate()];
    case 'lastQuarter':
      return [
        dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
        dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
      ];
    case 'thisYear':
      return [dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate()];
    case 'lastYear':
      return [
        dayjs().subtract(1, 'year').startOf('year').toDate(),
        dayjs().subtract(1, 'year').endOf('year').toDate(),
      ];
    case 'allTime':
      return [dayjs().subtract(5, 'years').startOf('year').toDate(), null];
  }
};

export type DateRange = [Date | null, Date | null];

export type DatePreset =
  | 'today'
  | 'yesterday'
  | 'thisWeek'
  | 'lastWeek'
  | 'thisMonth'
  | 'lastMonth'
  | 'thisQuarter'
  | 'lastQuarter'
  | 'thisYear'
  | 'lastYear'
  | 'allTime';

interface PresetButtonsProps {
  value: DateRange;
  onChange: (value: DateRange) => void;
  presets: DatePreset[];
}

export const PresetButtons = (props: PresetButtonsProps) => {
  const onChange = (preset: DatePreset) => () => {
    props.onChange(getPresetDateRange(preset));
  };

  return (
    <Stack
      color="neutral6"
      align="start"
      py={6}
      px={4}
      justify="space-between"
      borderRightWidth="1px"
      borderRightColor="dark7"
    >
      {props.presets.map((preset) => (
        <Button key={preset} variant="ghost" onClick={onChange(preset)}>
          {renderPresetText(preset)}
        </Button>
      ))}
    </Stack>
  );
};

interface Props {
  value: DateRange;
  onChange: (value: DateRange) => void;
  presets?: DatePreset[];
}

export const RangeDatePicker = (props: Props) => {
  const { value, onChange, presets } = props;

  const [startDate, endDate] = value;
  const [proposedValue, setProposedValue] = React.useState<DateRange>(value);
  const [month, onMonthChange] = React.useState(startDate ?? new Date());
  const formattedStartDate = startDate ? dayjs(startDate).format(DATE_FORMAT) : undefined;
  const formattedEndDate = endDate ? dayjs(endDate).format(DATE_FORMAT) : undefined;
  const formattedDate = `${formattedStartDate ?? 'Start date'} - ${formattedEndDate ?? 'End date'}`;

  const css = useGetCss();

  const onChangePrime = React.useCallback(
    (value: DateRange) => {
      const [start, end] = value;
      setProposedValue(value);

      if (!_.isNil(start) && !_.isNil(end)) {
        // Handle single date
        if (start.getTime() === end.getTime()) {
          onChange([start, dayjs(start).add(1, 'day').subtract(1, 'second').toDate()]);
        } else {
          onChange(value);
        }
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    const [startDate] = value;
    if (startDate) {
      onMonthChange(startDate);
    }
    setProposedValue(value);
  }, [value]);

  return (
    <Popover variant="responsive" placement="bottom-end">
      <PopoverTrigger>
        <InputGroup variant="filled">
          <InputLeftElement>
            <Icon as={FaCalendarDay} />
          </InputLeftElement>
          <Input value={formattedDate} readOnly />
          <InputRightElement>
            <Icon as={FaCaretDown} />
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="fit-content" p={0}>
          <PopoverBody p={0}>
            <Flex>
              <PresetButtons
                value={value}
                onChange={onChange}
                presets={presets ?? DEFAULT_PRESETS}
              />
              <Box p={2}>
                <RangeCalendar
                  value={proposedValue}
                  onChange={onChangePrime}
                  allowSingleDateInRange
                  month={month}
                  onMonthChange={onMonthChange}
                  styles={() => ({
                    calendarHeaderControl: css({
                      color: 'white',
                      _hover: { bg: 'dark1' },
                    }),
                    calendarHeaderLevel: css({
                      fontFamily: 'Titillium Web, sans-serif',
                      color: 'neutral1',
                      _hover: { bg: 'dark1' },
                    }),
                    monthPickerControl: css({
                      fontFamily: 'Titillium Web, sans-serif',
                      color: 'white',
                      _hover: { bg: 'dark1' },
                    }),
                    day: css({
                      fontFamily: 'Titillium Web, sans-serif',
                      color: 'white',
                      _hover: { bg: 'dark1' },
                    }),
                  })}
                />
              </Box>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
