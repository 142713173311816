import { HStack, Icon, Spacer, Text } from '@chakra-ui/react';
import { dataAttr } from '@chakra-ui/utils';
import { Listbox } from '@headlessui/react';
import { FaCheck } from 'react-icons/fa';

interface Props<A> {
  value: string;
  label: string;
  raw: A;
}

export const SelectOption = <A extends unknown>(props: Props<A>) => {
  const { value, label } = props;

  return (
    <Listbox.Option key={value} value={value}>
      {({ active, selected, disabled }) => {
        return (
          <HStack
            px={3}
            py={2}
            fontSize="md"
            _active={{
              bg: 'primary3',
            }}
            bg={active ? 'primary2' : 'inherit'}
            data-selected={dataAttr(selected)}
            data-disabled={dataAttr(disabled)}
          >
            <Text userSelect="none">{label}</Text>
            <Spacer />
            {selected && <Icon color="primary1" fontSize="sm" as={FaCheck} />}
          </HStack>
        );
      }}
    </Listbox.Option>
  );
};
