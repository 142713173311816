import { cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = () => ({
  rounded: 'md',
  p: 4,
  bg: 'dark4',
  [$arrowBg.variable]: 'colors.dark3',
});

export const Tooltip = {
  baseStyle,
};
