import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaSort } from 'react-icons/fa';
import { UserListUsersOrderByItem } from '../../../api/queries.schemas';
import { UsersFilter } from '../hooks/useUsersFilter';

interface Props {
  filter: UsersFilter;
}

export const UsersSortByMenu = (props: Props) => {
  const { filter } = props;

  const orderBy = _.trimStart(filter.orderBy, '-');
  const modifier = _.startsWith(filter.orderBy, '-') ? '-' : '';

  const onChange = React.useCallback(
    (prefix: string, value: string) => {
      filter.setOrderBy(`${prefix}${value}` as UserListUsersOrderByItem);
    },
    [filter],
  );

  const onClickOrderBy = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const value = e.currentTarget.value;
      const defaultedValue = value === '' ? 'created_at' : value;
      const oldValue = filter.orderBy;
      const newValue = `${modifier}${defaultedValue}` as UserListUsersOrderByItem;
      if (oldValue === newValue) {
        const newModifier = modifier === '-' ? '' : '-';
        onChange(newModifier, value);
      } else {
        onChange(modifier, value);
      }
    },
    [modifier, filter.orderBy, onChange],
  );

  return (
    <Menu closeOnSelect={false} placement="bottom-end">
      <MenuButton as={Button} variant="secondary" leftIcon={<Icon as={FaSort} />}>
        Sort by
      </MenuButton>
      <MenuList>
        <MenuOptionGroup value={orderBy} title="Sort by" type="radio">
          <MenuItemOption value="name" onClick={onClickOrderBy}>
            Name
          </MenuItemOption>
          <MenuItemOption value="role" onClick={onClickOrderBy}>
            Role
          </MenuItemOption>
          <MenuItemOption value="email" onClick={onClickOrderBy}>
            Email
          </MenuItemOption>
          <MenuItemOption value="invite_status" onClick={onClickOrderBy}>
            Invite status
          </MenuItemOption>
          <MenuItemOption value="department" onClick={onClickOrderBy}>
            Department
          </MenuItemOption>
          <MenuItemOption value="date_joined" onClick={onClickOrderBy}>
            Date joined
          </MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup
          title="Order"
          type="radio"
          value={modifier}
          onChange={(e) => onChange(e as string, orderBy)}
        >
          <MenuItemOption value="">Ascending</MenuItemOption>
          <MenuItemOption value="-">Descending</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
