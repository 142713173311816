import { Box, Text, Tooltip, TooltipProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends Omit<TooltipProps, 'title'> {
  title: string | React.ReactElement;
  content?: string | React.ReactElement;
}

export const ControlTooltip = (props: Props) => {
  const { title, content, children, ...rest } = props;

  return (
    <Tooltip
      hasArrow
      gutter={12}
      label={
        <Box p={1}>
          <Text fontSize="lg" fontWeight="bold">
            {title}
          </Text>
          <Box>{content}</Box>
        </Box>
      }
      openDelay={500}
      placement="left-start"
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
