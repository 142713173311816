import inflection from 'inflection';
import _ from 'lodash';
import React from 'react';
import { Queries } from '../../api';
import { RoleResponse } from '../../api/queries.schemas';
import { Select } from './select/Select';
import { SelectButton } from './select/SelectButton';
import { SelectMenu } from './select/SelectMenu';
import { SelectOption } from './select/SelectOption';
import { useAppContext } from '../../hooks/useAppContext';
import { makeErrorMessage } from '../../common/utils';

interface Props {
  userId: string;
  defaultValue: RoleResponse;
}

export const RoleChooser = (props: Props) => {
  const { userId, defaultValue } = props;

  const { errorToast, successToast, user, queryClient } = useAppContext();
  const updateUserMutation = Queries.useUserUpdateUser({
    mutation: {
      onSettled: () => queryClient.invalidateQueries(Queries.getUserListUsersQueryKey()),
    },
  });

  const onChange = React.useCallback(
    (value: RoleResponse) => {
      updateUserMutation
        .mutateAsync({
          userId,
          data: {
            role: value,
          },
        })
        .then(() => {
          successToast("User's role updated successfully");
          return user.refreshToken();
        })
        .catch((err) => {
          errorToast(`Error updating user's role - ${makeErrorMessage(err)}`);
        });
    },
    [userId, updateUserMutation, user, errorToast, successToast],
  );

  const options = _.map(RoleResponse, (role) => ({
    label: inflection.humanize(role),
    value: role,
    raw: role,
  }));

  return (
    <Select value={defaultValue} onChange={(e) => onChange(e as RoleResponse)}>
      <SelectButton variant="selectInline" isLoading={updateUserMutation.isLoading} w="auto" />
      <SelectMenu w="fit-content">
        {options.map((option) => (
          <SelectOption key={option.value} {...option} />
        ))}
      </SelectMenu>
    </Select>
  );
};
