import { forwardRef, Input, InputProps, useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends InputProps {
  name: string;
}

export const HookedInput = forwardRef<Props, 'input'>((props, forwardedRef) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const mergedRef = useMergeRefs(ref, forwardedRef);

  return <Input {...rest} {...inputProps} value={inputProps.value ?? ''} ref={mergedRef} />;
});
