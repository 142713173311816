import { Tag } from '@chakra-ui/react';
import { OrderStatusResponse } from '../api/queries.schemas';

interface Props {
  status: OrderStatusResponse;
}

export const OrderStatusTag = (props: Props) => {
  switch (props.status) {
    case OrderStatusResponse.completed:
      return (
        <Tag variant="solid" bg="rgba(38, 156, 128, 0.2)" color="#269C80">
          Completed
        </Tag>
      );
    case OrderStatusResponse.failed:
      return (
        <Tag variant="solid" bg="rgba(235,65,75,0.2)" color="#E8414B">
          Failed
        </Tag>
      );
    case OrderStatusResponse.processing:
      return (
        <Tag variant="solid" bg="rgba(16, 135, 255, 0.2)" color="#1087FF">
          Processing
        </Tag>
      );
    case OrderStatusResponse.initialising:
      return (
        <Tag variant="solid" bg="rgba(180, 188, 196, 0.2)" color="#B4BCC4">
          Initialising
        </Tag>
      );
    case OrderStatusResponse.dispatched:
      return (
        <Tag variant="solid" bg="rgba(123, 78, 252, 0.2)" color="#7B4EFC">
          Dispatched
        </Tag>
      );
    case OrderStatusResponse.in_progress:
      return (
        <Tag variant="solid" bg="rgba(222, 172, 43, 0.2)" color="#DEAC2B">
          In progress
        </Tag>
      );
    case OrderStatusResponse.unfeasible:
      return (
        <Tag variant="solid" bg="rgba(250, 130, 78, 0.2)" color="#FA824E">
          Unfeasible
        </Tag>
      );
  }
};
