import { AxiosError } from 'axios';
import dajys from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import _ from 'lodash';
import { ErrorResponse } from '../api/queries.schemas';
import { DATE_FORMAT_SHORT } from './constants';

export const dateFormatShort = (date: Date) => {
  return dajys(date).format(DATE_FORMAT_SHORT);
};

export const durationFormat = (duration: Duration): string => {
  if (duration.asHours() <= 48) {
    return `${duration.asHours()} hours`;
  } else {
    return `${duration.asDays()} days`;
  }
};

export const currencyFormat = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 2,
}).format;

export const currencyFormatShort = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 0,
  notation: 'compact',
}).format;

export const distanceFormat = new Intl.NumberFormat('en-AU', {
  style: 'unit',
  unit: 'kilometer',
  maximumFractionDigits: 2,
}).format;

export const intersperse = <T>(arr: T[], separator: (n: number) => T): T[] =>
  arr.reduce<T[]>((acc, currentElement, currentIndex) => {
    const isLast = currentIndex === arr.length - 1;
    return [...acc, currentElement, ...(isLast ? [] : [separator(currentIndex)])];
  }, []);

export const wavelengthToColor = (wavelength: string, alpha: string) => {
  const gamma = 0.8;
  const maxIntensity = 255;

  let factor = 0.0;
  let red = 0.0;
  let green = 0.0;
  let blue = 0.0;

  const [min, max] = wavelength.replace('nm', '').split(' - ').map(_.toNumber);
  const averageWavelength = (min + max) / 2;

  if (averageWavelength >= 380 && averageWavelength < 440) {
    red = -(averageWavelength - 440) / (440 - 380);
    green = 0.0;
    blue = 1.0;
  } else if (averageWavelength >= 440 && averageWavelength < 490) {
    red = 0.0;
    green = (averageWavelength - 440) / (490 - 440);
    blue = 1.0;
  } else if (averageWavelength >= 490 && averageWavelength < 510) {
    red = 0.0;
    green = 1.0;
    blue = -(averageWavelength - 510) / (510 - 490);
  } else if (averageWavelength >= 510 && averageWavelength < 580) {
    red = (averageWavelength - 510) / (580 - 510);
    green = 1.0;
    blue = 0.0;
  } else if (averageWavelength >= 580 && averageWavelength < 645) {
    red = 1.0;
    green = -(averageWavelength - 645) / (645 - 580);
    blue = 0.0;
  } else if (averageWavelength >= 645 && averageWavelength < 781) {
    red = 1.0;
    green = 0.0;
    blue = 0.0;
  } else {
    red = 0.0;
    green = 0.0;
    blue = 0.0;
  }
  // Let the intensity fall off near the vision limits
  if (averageWavelength >= 380 && averageWavelength < 420) {
    factor = 0.3 + (0.7 * (averageWavelength - 380)) / (420 - 380);
  } else if (averageWavelength >= 420 && averageWavelength < 701) {
    factor = 1.0;
  } else if (averageWavelength >= 701 && averageWavelength < 781) {
    factor = 0.3 + (0.7 * (780 - averageWavelength)) / (780 - 700);
  } else {
    factor = 0.0;
  }
  if (red !== 0) {
    red = Math.round(maxIntensity * Math.pow(red * factor, gamma));
  }
  if (green !== 0) {
    green = Math.round(maxIntensity * Math.pow(green * factor, gamma));
  }
  if (blue !== 0) {
    blue = Math.round(maxIntensity * Math.pow(blue * factor, gamma));
  }

  if (factor === 0.0) {
    return `rgba(180, 188, 196, ${alpha})`;
  }

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const circleRadiusToArea = (radius: number) => {
  return Math.PI * radius * radius;
};

export const makeErrorMessage = (err: AxiosError<ErrorResponse>): string => {
  return err?.response?.data?.detail ?? err?.message ?? 'Unknown error occurred';
};
