import { Flex, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';

import BGAgriculture from '../../../assets/use-case-agriculture.webp';
import BGAssetManagement from '../../../assets/use-case-asset-management.webp';
import BGDefence from '../../../assets/use-case-defence.webp';
import BGDisaster from '../../../assets/use-case-disaster.webp';
import BGFinance from '../../../assets/use-case-finance.webp';
import BGForestry from '../../../assets/use-case-forestry.webp';
import BGInfrastructure from '../../../assets/use-case-infrastructure.webp';
import BGMaritime from '../../../assets/use-case-maritime.webp';
import BGMining from '../../../assets/use-case-mining.webp';

// TODO: Do something on image hover

interface UseCaseProps {
  imageUrl: string;
  title: string;
  description: string;
}

const UseCase = ({ imageUrl, title, description }: UseCaseProps) => {
  return (
    <Flex
      minH="3xs"
      maxW="sm"
      rounded="lg"
      shadow="lg"
      position="relative"
      backgroundImage={imageUrl}
      color="white"
      backgroundSize="cover"
      backgroundPosition="center center"
      justify="end"
      direction="column"
      p={4}
    >
      <Heading pt={20} size="md">
        {title}
      </Heading>
      <Text>{description}</Text>
    </Flex>
  );
};

export const UseCaseList = () => {
  return (
    <VStack spacing={10} align="center">
      <Heading textAlign={'center'}>Better informed decision making</Heading>
      <Text textAlign={'center'}>
        Earth observation has never been easier. Spiral Blue is providing the right information, at
        the right time to help industries make better informed decisions faster, cheaper, and more
        efficiently.
      </Text>
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={3}>
        <UseCase
          imageUrl={BGMaritime}
          title="Maritime"
          description="Affordably track, and monitor maritime assets from space."
        />
        <UseCase
          imageUrl={BGAgriculture}
          title="Agriculture"
          description="Efficiently, and sustainably increase the profitability of agricultural assets."
        />
        <UseCase
          imageUrl={BGForestry}
          title="Forestry"
          description="Supporting better forestry practices, and operations."
        />
        <UseCase
          imageUrl={BGInfrastructure}
          title="Infrastructure"
          description="Monitor assets, and evaluate competitor activity."
        />
        <UseCase
          imageUrl={BGDisaster}
          title="Disaster Management"
          description="Prevent, monitor, and respond to natural disasters."
        />
        <UseCase
          imageUrl={BGDefence}
          title="Defence & Intelligence"
          description="Quickly obtain data about geopolitical events."
        />
        <UseCase
          imageUrl={BGFinance}
          title="Financial Services"
          description="Make better informed decisions about global markets."
        />
        <UseCase
          imageUrl={BGMining}
          title="Mining"
          description="Simplifying exploration in hard to reach places."
        />
        <UseCase
          imageUrl={BGAssetManagement}
          title="Asset Management"
          description="Make better informed asset management decisions."
        />
      </SimpleGrid>
    </VStack>
  );
};
