import { Queries } from '../api';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

export class TokenManager {
  setTokens({ access, refresh }: { access: string; refresh: string }) {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, access);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
  }

  getAccessToken(): string | null {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  getRefreshToken() {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  getTokens() {
    const access_token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    const refresh_token = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    return { access_token, refresh_token };
  }

  removeAccessToken() {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  removeTokens() {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  async refreshToken() {
    const refresh = this.getRefreshToken();

    if (!refresh) {
      throw new Error('No refresh token found');
    }

    const response = await Queries.AuthRefresh({
      refresh,
    });

    this.removeAccessToken();
    this.setTokens(response.data);
  }
}
