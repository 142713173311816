import { Box, BoxProps, Flex, forwardRef } from '@chakra-ui/react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import React from 'react';

export interface ScrollAreaProps
  extends Omit<BoxProps, 'dir'>,
    Omit<ScrollAreaPrimitive.ScrollAreaProps, 'color'> {}

const SCROLLBAR_WIDTH = '10px';

export const ScrollArea = forwardRef((props: React.PropsWithChildren<ScrollAreaProps>, ref) => {
  const { children, ...rest } = props;

  return (
    <Box as={ScrollAreaPrimitive.Root} ref={ref} overflow="hidden" {...rest}>
      <Box as={ScrollAreaPrimitive.Viewport} w="full" h="full" borderRadius="inherit">
        {children}
      </Box>
      <Flex
        as={ScrollAreaPrimitive.Scrollbar}
        orientation="vertical"
        userSelect="none"
        p="2px"
        transition="background 160ms ease-out"
        w={SCROLLBAR_WIDTH}
      >
        <Box
          as={ScrollAreaPrimitive.Thumb}
          flex={1}
          bg="gray.400"
          borderRadius={SCROLLBAR_WIDTH}
          pos="relative"
        />
      </Flex>
    </Box>
  );
});

export const ScrollAreaAutosize = forwardRef(
  (props: React.PropsWithChildren<ScrollAreaProps>, ref) => {
    const { children, maxHeight, type, dir, ...rest } = props;

    return (
      <Flex {...rest} ref={ref} maxHeight={maxHeight}>
        <Flex flexDir="column" flex={1}>
          <ScrollArea type={type} dir={dir}>
            {children}
          </ScrollArea>
        </Flex>
      </Flex>
    );
  },
);
