import {
  Box,
  FormLabel,
  Heading,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Queries } from '../../../api';
import { HookedFormControl } from '../../../components/hooked/HookedFormControl';
import { HookedInput } from '../../../components/hooked/HookedInput';
import { HookedTextarea } from '../../../components/hooked/HookedTextarea';
import { HookedSubmitButton } from '../../../components/hooked/HookedSubmitButton';
import { useToast } from '../../../hooks/useToast';

const defaultValues = {
  name: '',
  email: '',
  message: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  message: Yup.string().required(),
});

export const Contact = () => {
  const { errorToast, successToast } = useToast();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const mutation = Queries.useContact({
    mutation: {
      onError: () => {
        errorToast('Something went wrong, please try again later.');
      },
      onSuccess: ({ data }) => {
        successToast('Message sent successfully');
      },
    },
  });

  const onSubmit = async (data: typeof defaultValues) => {
    return mutation.mutate({
      data: {
        name: data.name,
        email: data.email,
        message: data.message,
      },
    });
  };

  return (
    <VStack spacing={5}>
      <Heading>Get started now!</Heading>
      <Text align="center">
        Contact us to start ordering on demand, high resolution satellite imagery.
      </Text>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            bg={useColorModeValue('white', 'gray.700')}
            borderRadius="lg"
            width={{ base: 'full', md: 'lg' }}
            p={8}
            color={useColorModeValue('gray.700', 'whiteAlpha.900')}
            shadow="base"
          >
            <VStack spacing={5}>
              <HookedFormControl name="name">
                <FormLabel>Name</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <BsPerson />
                  </InputLeftElement>
                  <HookedInput
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    pl={8}
                    color="black"
                  />
                </InputGroup>
              </HookedFormControl>
              <HookedFormControl name="email">
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <MdOutlineEmail />
                  </InputLeftElement>
                  <HookedInput
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    pl={8}
                    color="black"
                  />
                </InputGroup>
              </HookedFormControl>
              <HookedFormControl name="message">
                <FormLabel>Message</FormLabel>
                <HookedTextarea
                  name="message"
                  placeholder="Your Message"
                  rows={6}
                  resize="none"
                  color="black"
                />
              </HookedFormControl>
              <HookedSubmitButton>Send Message</HookedSubmitButton>
            </VStack>
          </Box>
        </form>
      </FormProvider>
    </VStack>
  );
};
