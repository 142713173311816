import { Icon, IconButton, Text } from '@chakra-ui/react';
import { modes } from '@mapbox/mapbox-gl-draw';
import React from 'react';
import { FaRegSquare } from 'react-icons/fa';
import { MapDrawActions } from '../hooks/useMapDrawActions';
import { ControlTooltip } from './ControlTooltip';

/**
 * Custom DrawPolygonTool that overrides the onTrash
 * callback. Will delete last vertex if in a ring.
 */
export const DrawPolygonTool = {
  ...(modes as any).draw_polygon,
  onTrash: function onTrash(state: any) {
    if (state.currentVertexPosition <= 1) {
      this.deleteFeature([state.polygon.id], { silent: true });
      this.changeMode('simple_select');
    } else {
      const ring = state.polygon.coordinates[0];
      if (ring) {
        state.polygon.changed();
        state.currentVertexPosition -= 1;
        ring.splice(state.currentVertexPosition, 1);
      }
    }
  },
};

const MODE = 'draw_polygon';

interface Props {
  actions: MapDrawActions;
}

export const PolygonControl = (props: Props) => {
  const { actions } = props;

  const onClick = React.useCallback(() => {
    actions.draw.changeMode(MODE);
  }, [actions]);

  return (
    <ControlTooltip
      title="Draw a polygon"
      content={<Text>Click points on the map to create a polygon</Text>}
    >
      <IconButton
        aria-label="polygon"
        variant="secondary"
        icon={<Icon as={FaRegSquare} />}
        size="lg"
        rounded="md"
        onClick={onClick}
      />
    </ControlTooltip>
  );
};
