import _ from 'lodash';
import React from 'react';
import { Queries } from '../../api';
import { useDepartmentListDepartments } from '../../api/queries';
import { makeErrorMessage } from '../../common/utils';
import { useAppContext } from '../../hooks/useAppContext';
import { Select } from './select/Select';
import { SelectButton } from './select/SelectButton';
import { SelectMenu } from './select/SelectMenu';
import { SelectOption } from './select/SelectOption';

interface Props {
  userId: string;
  defaultValue: string;
}

export const DepartmentChooser = (props: Props) => {
  const { userId, defaultValue } = props;

  const { errorToast, successToast, user, queryClient } = useAppContext();
  const updateUserMutation = Queries.useUserUpdateUser({
    mutation: {
      onSettled: () => queryClient.invalidateQueries(Queries.getUserListUsersQueryKey()),
    },
  });
  const departmentsQuery = useDepartmentListDepartments();

  const departments = React.useMemo(() => {
    return departmentsQuery.data?.data ?? [];
  }, [departmentsQuery]);

  const onChange = React.useCallback(
    (value: string) => {
      updateUserMutation
        .mutateAsync({
          userId,
          data: {
            department: value,
          },
        })
        .then(() => {
          successToast("User's department updated successfully");
          return user.refreshToken();
        })
        .catch((err) => {
          errorToast(`Error updating user's department - ${makeErrorMessage(err)}`);
        });
    },
    [userId, updateUserMutation, user, errorToast, successToast],
  );

  const isLoading = updateUserMutation.isLoading;

  const options = _.map(departments, (department) => ({
    label: department.name,
    value: department.id,
    raw: department.id,
  }));

  return (
    <Select value={defaultValue} onChange={onChange}>
      <SelectButton variant="selectInline" w="auto" isLoading={isLoading} />
      <SelectMenu>
        {options.map((option) => (
          <SelectOption key={option.value} {...option} />
        ))}
      </SelectMenu>
    </Select>
  );
};
