import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import * as Api from '../api';
import { ROUTES } from '../common/routes';
import { Error } from '../components/Error';
import { Spin } from '../components/Spin';
import { useTokenManager } from './TokenManagerContext';

export interface UserContextResponse {
  isAuthenticated: boolean;
  isLoading: boolean;
  details: Api.Schema.UserDetailResponse;
  login: () => void;
  logout: () => void;
  refreshToken: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextResponse>(null as any);

export const UserContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const tokenManager = useTokenManager();

  const navigate = useNavigate();

  const isAuthenticated = !!tokenManager.getAccessToken() || !!tokenManager.getRefreshToken();

  const userQuery = Api.Queries.useUserGetLoggedInUser();
  const userData = userQuery.data;
  const isLoading = userQuery.isLoading;
  const isError = userQuery.isError;
  const isFetched = userQuery.isFetched;

  const login = React.useCallback(() => {
    navigate(ROUTES.LOGIN);
  }, [navigate]);

  const logout = React.useCallback(() => {
    tokenManager.removeTokens();
    login();
  }, [login, tokenManager]);

  const refreshToken = React.useCallback(() => {
    return tokenManager.refreshToken();
  }, [tokenManager]);

  const value: UserContextResponse = {
    isLoading,
    isAuthenticated,
    details: userData?.data as Api.Schema.UserDetailResponse,
    login,
    logout,
    refreshToken,
  };

  if (!isAuthenticated || isError) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  const content = () => {
    if (isLoading) {
      return <Spin />;
    }

    if (isError || !isFetched) {
      return <Error title="Could not fetch user details" />;
    }

    return children;
  };

  return <UserContext.Provider value={value}>{content()}</UserContext.Provider>;
};

export const useUser: () => UserContextResponse = () => {
  return React.useContext(UserContext);
};
