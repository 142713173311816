import { LngLatBoundsLike } from 'mapbox-gl';

export const LOGO_TEXT = 'COBALT';

export const DATE_FORMAT_SHORT = 'MMM';

export const CHIP_BGC: Record<string, string> = {
  completed: '#219c21',
  processing: '#708EF7',
  initialising: 'rgb(0 0 0 / 30%)',
  dispatched: '#708EF7',
  in_progress: '#708EF7',
  unfeasible: 'red',
  failed: 'red',
};

export const MAP_MAX_BOUNDS: LngLatBoundsLike = [
  [140, -39.5], // Southwest coordinates
  [151, -33.5], // Northeast coordinates
];

export const ORDER_STATUS_DESCRIPTION = [
  {
    title: 'Initialising',
    description: 'We’re getting your order ready to send to the satellite provider.',
  },
  {
    title: 'Dispatched',
    description: 'The satellite provider has received your order.',
  },
  {
    title: 'In Progress',
    description:
      'The satellite provider is tasking their satellites to capture imagery for your order.',
  },
  {
    title: 'Processing',
    description: 'Captures are starting to come in for the order.',
  },
  {
    title: 'Completed',
    description: 'The order has been completed, no more captures will be made.',
  },
  {
    title: 'Unfeasible',
    description:
      'It’s not possible for the satellite provider to capture the requested target in time.',
  },
  {
    title: 'Failed',
    description: 'Something has gone wrong with the order and it won’t be completed.',
  },
];

export const CAPTURE_STATUS_DESCRIPTION = [
  {
    title: 'Queued',
    description: 'The satellite provider has tasked a satellite with capturing imagery.',
  },
  {
    title: 'Processing',
    description: 'The satellite provider is processing the captured imagery.',
  },
  {
    title: 'Completed',
    description: 'The capture is complete and the data is available.',
  },
  {
    title: 'Failed',
    description:
      'The capture has failed, due to too much cloud cover, or issues with the satellite.',
  },
];

export const CREATE_ORDER_HELP_TEXT =
  "To select a region, select the polygon symbol in the top right, then click on the map to mark the points of the polygon. To complete the polygon, double click on the last point. The polygon can be moved by clicking and dragging. To select a point, click the point symbol in the top right, then click on the map to mark the point. The point can be moved by clicking and dragging on it's centre.";
