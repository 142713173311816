import {
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  ListItem,
  Stack,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { ProductDetailResponse } from '../../../api/queries.schemas';
import {
  circleRadiusToArea,
  currencyFormat,
  distanceFormat,
  durationFormat,
} from '../../../common/utils';
import { BandsList } from '../../../components/BandsList';

const InfoListItem = (props: { label: React.ReactNode; value: React.ReactNode }) => {
  return (
    <ListItem>
      <HStack>
        <Box color="neutral2" fontWeight="bold">
          {props.label}
        </Box>
        <Box color="neutral6">{props.value}</Box>
      </HStack>
    </ListItem>
  );
};

interface Props {
  product: ProductDetailResponse;
  showCount?: number;
}

export const ProductDetailsList = (props: Props) => {
  const { showCount, product } = props;

  const showMoreDisclosure = useDisclosure();

  const items = [
    {
      label: 'Turnaround time',
      value: `${durationFormat(dayjs.duration(product.turnaround_time, 'seconds'))}`,
    },
    {
      label: 'Resolution',
      value: `${product.resolution} m`,
    },
    {
      label: 'Bands',
      value: <BandsList bands={product.bands} />,
    },
    {
      label: 'Price per km²',
      value: `${currencyFormat(product.price_per_km2)} (inc GST)`,
    },
    {
      label: 'Satellite Provider',
      value: product.provider,
    },
    {
      label: 'Minimum order size',
      value: `${distanceFormat(product.minimum_area)}²`,
    },
    {
      label: 'Maximum polygon diameter',
      value: `${distanceFormat((product.aoi_max_radius * 2) / 1000)}`,
    },
    {
      label: 'Point area',
      value: `${distanceFormat(circleRadiusToArea(product.poi_radius / 1000))}²`,
    },
  ];

  const firstCount = showCount ? showCount : items.length;
  const firstItems = _.take(items, firstCount);
  const lastItems = _.takeRight(items, items.length - firstCount);

  return (
    <Box>
      <UnorderedList spacing={2} pl={1}>
        {_.map(firstItems, (item) => {
          return <InfoListItem key={item.label} label={item.label} value={item.value} />;
        })}
      </UnorderedList>
      {lastItems.length > 0 && (
        <Stack align="start">
          <Collapse in={showMoreDisclosure.isOpen}>
            <UnorderedList spacing={2} pl={1} mt={2}>
              {_.map(lastItems, (item) => {
                return <InfoListItem key={item.label} label={item.label} value={item.value} />;
              })}
            </UnorderedList>
          </Collapse>
          <Button
            variant="link"
            color="neutral3"
            onClick={showMoreDisclosure.onToggle}
            rightIcon={
              showMoreDisclosure.isOpen ? <Icon as={FaCaretUp} /> : <Icon as={FaCaretDown} />
            }
          >
            {showMoreDisclosure.isOpen ? 'Less details' : 'More details'}
          </Button>
        </Stack>
      )}
    </Box>
  );
};
