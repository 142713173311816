import { Box, Container, Flex } from '@chakra-ui/react';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero';
import { FeatureList } from './components/FeatureList';
import { SatelliteImagery } from './components/SatelliteImagery';
import { UseCaseList } from './components/UseCaseList';
import { WhatsNext } from './components/WhatsNext';
import { Contact } from './components/Contact';
import { createRef, ReactNode, RefObject } from 'react';
import { Pricing } from './components/Pricing';

interface SegmentProps {
  blue?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
}

const Segment = ({ blue, innerRef, children }: SegmentProps) => {
  const bg_props = blue
    ? { bgGradient: 'linear(to-r, primary.800, primary.500)', color: 'white' }
    : { backgroundColor: 'white', color: 'dark2' };
  let flex_props = {};
  if (innerRef) {
    flex_props = { ...bg_props, ref: innerRef };
  } else {
    flex_props = bg_props;
  }
  let container_props = blue ? { bg: 'transparent' } : { bg: 'white' };
  return (
    <Flex {...flex_props} py={20}>
      <Container maxWidth="5xl" {...container_props}>
        {children}
      </Container>
    </Flex>
  );
};

export const Landing = () => {
  const contactRef = createRef<HTMLDivElement>();
  const startRef = createRef<HTMLDivElement>();

  return (
    <Box>
      <Hero startRef={startRef} contactRef={contactRef} />
      <Segment innerRef={startRef}>
        <FeatureList />
      </Segment>
      <Segment blue>
        <SatelliteImagery />
      </Segment>
      <Segment>
        <Pricing />
      </Segment>
      <Segment blue>
        <UseCaseList />
      </Segment>
      <Segment>
        <WhatsNext />
      </Segment>
      <Segment blue innerRef={contactRef}>
        <Contact />
      </Segment>
      <Footer />
    </Box>
  );
};
