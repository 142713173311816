import React from 'react';
import { TokenManager } from '../common/TokenManager';

export const TokenManagerContext = React.createContext<TokenManager>(null as any);

export const TokenManagerContextProvider = (
  props: React.PropsWithChildren<{ tokenManager: TokenManager }>,
) => {
  const { tokenManager, children } = props;

  return (
    <TokenManagerContext.Provider value={tokenManager}>{children}</TokenManagerContext.Provider>
  );
};

export const useTokenManager: () => TokenManager = () => {
  return React.useContext(TokenManagerContext);
};
