import { Box, Button, Container, Icon, Stack, Text } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { ReactNode } from 'react';

interface SocialButtonProps {
  children: ReactNode;
  label: string;
  href: string;
}

const SocialButton = ({ children, label, href }: SocialButtonProps) => {
  return (
    <Button
      rounded="full"
      w={8}
      h={10}
      cursor="pointer"
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      color="primary"
    >
      {children}
    </Button>
  );
};

export const Footer = () => {
  return (
    <Box bg="white">
      <Container
        as={Stack}
        maxW="6xl"
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        color="primary1"
      >
        <Text>© 2023 Spiral Blue. All rights reserved</Text>
        <Stack direction="row" spacing={6}>
          <SocialButton label="LinkedIn" href="https://www.linkedin.com/company/spiralblue/">
            <Icon as={FaLinkedin} w={6} h={6} color="white" />
          </SocialButton>
          <SocialButton label="Instagram" href="https://www.instagram.com/spiralblue.space/">
            <Icon as={FaInstagram} w={6} h={6} color="white" />
          </SocialButton>
          <SocialButton label="Facebook" href="https://www.facebook.com/spiralblueptyltd/">
            <Icon as={FaFacebook} w={6} h={6} color="white" />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
};
