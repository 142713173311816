import {
  Avatar,
  Button,
  chakra,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import {
  FaChartLine,
  FaChevronDown,
  FaGlobe,
  FaKey,
  FaPlus,
  FaSignOutAlt,
  FaUser,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { RoleResponse } from '../api/queries.schemas';
import { LOGO_TEXT } from '../common/constants';
import { ROUTES } from '../common/routes';
import { useAppContext } from '../hooks/useAppContext';
import { useRole } from '../hooks/useRole';
import { NavButton } from './NavButton';

interface Props {}

export const Navbar = (props: Props) => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  // TODO: Could be pulled out into a <RequiresRole /> component, when this grows burdensome
  const canDashboard = useRole([RoleResponse.administrator]);
  const canUserManagement = useRole(RoleResponse.administrator);
  const canCreateOrder = useRole([RoleResponse.administrator, RoleResponse.creator]);

  const onLogout = () => {
    user.logout();
  };

  const onChangePassword = () => {
    navigate(ROUTES.CHANGE_PASSWORD);
  };

  const onOrders = () => {
    navigate(ROUTES.ORDERS);
  };

  const onCreateOrder = () => {
    navigate(ROUTES.CREATE_ORDER);
  };

  const onUsers = () => {
    navigate(ROUTES.USERS);
  };

  return (
    <chakra.nav userSelect="none">
      <HStack h="72px" w="full" bg="dark2" spacing={0}>
        <HStack h="full" spacing={0}>
          <Button
            variant="nav"
            fontWeight={700}
            fontSize="20px"
            lineHeight="32px"
            px={8}
            onClick={onOrders}
          >
            {LOGO_TEXT}
          </Button>
          <NavButton leftIcon={<Icon as={FaGlobe} />} to={ROUTES.ORDERS}>
            Orders
          </NavButton>
          {canDashboard && (
            <NavButton to={ROUTES.DASHBOARD} leftIcon={<Icon as={FaChartLine} />}>
              Dashboard
            </NavButton>
          )}
        </HStack>
        <Spacer />
        <HStack spacing={2} pr={4}>
          {canCreateOrder && (
            <Button
              variant="solid"
              size="md"
              leftIcon={<Icon as={FaPlus} />}
              onClick={onCreateOrder}
            >
              Create order
            </Button>
          )}
          <Menu isLazy lazyBehavior="keepMounted" data-pw="userMenu">
            <MenuButton
              as={Button}
              variant="ghost"
              colorScheme="transparent"
              color="neutral1"
              rightIcon={<Icon color="neutral3" fontSize="12px" as={FaChevronDown} />}
            >
              <Avatar w="40px" h="40px" name={user.details.name} />
            </MenuButton>
            <MenuList>
              {canUserManagement && (
                <MenuItem icon={<Icon as={FaUser} />} onClick={onUsers}>
                  User management
                </MenuItem>
              )}
              <MenuItem icon={<Icon as={FaKey} />} onClick={onChangePassword}>
                Reset password
              </MenuItem>
              <MenuItem icon={<Icon as={FaSignOutAlt} />} onClick={onLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>
    </chakra.nav>
  );
};
