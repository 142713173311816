import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

interface Props {
  date: Date;
}

export const HumanizedDate = (props: Props) => {
  const { date } = props;
  const humanized = dayjs(date).format('D MMM, YYYY, h:mm a');

  return <Text>{humanized}</Text>;
};
