import { useQueryClient } from '@tanstack/react-query';
import { useTokenManager } from '../context/TokenManagerContext';
import { useUser } from '../context/UserContext';
import { useToast } from './useToast';

const useGetContext = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const toast = useToast();
  const tokenManager = useTokenManager();

  return {
    ...toast,
    user,
    queryClient,
    tokenManager,
  };
};

export const useAppContext: () => ReturnType<typeof useGetContext> = () => {
  return useGetContext();
};
