import { Heading, Image, Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';

import AnalyticsExample from '../../../assets/analytics-example.webp';

// TODO:
//     - Make link underlined
//     - Make dot points look better

export const WhatsNext = () => {
  return (
    <VStack spacing={10}>
      <Heading textAlign={'center'}>What's Next?</Heading>
      <Text textAlign={'center'}>
        Upcoming updates to Cobalt include running analytics on ordered imagery including, but not
        limited to:
      </Text>
      <UnorderedList>
        <ListItem>Canopy mapping</ListItem>
        <ListItem>Coastal erosion</ListItem>
        <ListItem>Fire severity</ListItem>
        <ListItem>Water body mapping</ListItem>
      </UnorderedList>
      <Image src={AnalyticsExample} />
      <Text textAlign={'center'}>
        Ultimately, Cobalt will include a marketplace that allows users to choose and list analysis
        apps, with analysis conducted on{' '}
        <Link href="https://spiralblue.space/space-edge-computing">Space Edge Computers</Link> on
        board satellites where appropriate.
      </Text>
    </VStack>
  );
};
