import { Button, Icon, Wrap } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';

export interface TagOption {
  value: string;
  label: string;
}

export interface TagFilterProps {
  option: TagOption;
  isActive: boolean;
  onClick: () => void;
}

const TagFilter = (props: TagFilterProps) => {
  const { option, isActive, onClick } = props;

  return (
    <Button
      size="sm"
      leftIcon={
        isActive ? (
          <Icon color="primary1" as={FaCheckSquare} />
        ) : (
          <Icon color="dark5" as={FaRegSquare} />
        )
      }
      variant="outline"
      color={isActive ? 'neutral2' : 'neutral3'}
      onClick={onClick}
      bg={isActive ? 'primary4' : 'transparent'}
    >
      {option.label}
    </Button>
  );
};

interface TagsFilterProps {
  options: TagOption[];
  value: TagOption[];
  onChange: (active: TagOption[]) => void;
}

export const TagsFilter = (props: TagsFilterProps) => {
  const { options, value, onChange } = props;

  const onClick = React.useCallback(
    (option: TagOption) => {
      if (_.some(value, (v) => v.value === option.value)) {
        onChange(_.filter(value, (v) => v.value !== option.value));
      } else {
        onChange([...value, option]);
      }
    },
    [value, onChange],
  );

  return (
    <Wrap shouldWrapChildren>
      {_.map(options, (option) => (
        <TagFilter
          key={option.value}
          option={option}
          isActive={_.some(value, (v) => v.value === option.value)}
          onClick={() => onClick(option)}
        />
      ))}
    </Wrap>
  );
};
