/**
 * Generated by orval v6.9.2 🍺
 * Do not edit manually.
 * Internal API
 * OpenAPI spec version: 0.5.0
 */
export type UserListUsersOrderByItem =
  typeof UserListUsersOrderByItem[keyof typeof UserListUsersOrderByItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserListUsersOrderByItem = {
  name: 'name',
  role: 'role',
  email: 'email',
  invite_status: 'invite_status',
  department: 'department',
  date_joined: 'date_joined',
  '-name': '-name',
  '-role': '-role',
  '-email': '-email',
  '-invite_status': '-invite_status',
  '-department': '-department',
  '-date_joined': '-date_joined',
} as const;

export type UserListUsersParams = {
  role?: RoleResponse[];
  department?: string[];
  invite_status?: InvitationStatusResponse[];
  search?: string;
  order_by?: UserListUsersOrderByItem[];
  limit?: number;
  offset?: number;
};

export type UserListTopUsersForDashboardOrderByItem =
  typeof UserListTopUsersForDashboardOrderByItem[keyof typeof UserListTopUsersForDashboardOrderByItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserListTopUsersForDashboardOrderByItem = {
  name: 'name',
  total_orders: 'total_orders',
  total_price: 'total_price',
  total_downloads: 'total_downloads',
  '-name': '-name',
  '-total_orders': '-total_orders',
  '-total_price': '-total_price',
  '-total_downloads': '-total_downloads',
} as const;

export type UserListTopUsersForDashboardParams = {
  start?: Date;
  end?: Date;
  order_by?: UserListTopUsersForDashboardOrderByItem[];
  limit?: number;
  offset?: number;
};

export type UserListUsersAutocompleteParams = { q?: string };

export type OrdersAggregateOrdersForDashboardParams = {
  start: Date;
  end?: Date;
  time_zone?: string;
};

export type OrdersAutocompleteOrderNameParams = { q: string };

export type OrdersListOrdersOrderByItem =
  typeof OrdersListOrdersOrderByItem[keyof typeof OrdersListOrdersOrderByItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrdersListOrdersOrderByItem = {
  name: 'name',
  status: 'status',
  cost: 'cost',
  created_at: 'created_at',
  department: 'department',
  user: 'user',
  downloads: 'downloads',
  '-name': '-name',
  '-status': '-status',
  '-cost': '-cost',
  '-created_at': '-created_at',
  '-department': '-department',
  '-user': '-user',
  '-downloads': '-downloads',
} as const;

export type OrdersListOrdersParams = {
  start?: Date;
  end?: Date;
  status?: OrderStatusResponse[];
  department?: string[];
  user?: string[];
  search?: string;
  order_by?: OrdersListOrdersOrderByItem[];
  limit?: number;
  offset?: number;
};

export type DepartmentDeleteDepartmentParams = { replace_with?: string };

export type DepartmentListDepartmentsParams = { name?: string };

export type AuthRefreshParams = { refresh: string };

/**
 * Schema for account balances.
 */
export interface ContactEmailResponse {
  name: string;
  email: string;
  message: string;
}

export interface DeleteWithReplacementResponse {
  /** The record to replace this record with, should there be any related records. */
  replace_with?: string;
}

export interface UserDeleteConflictResponse {
  /** The number of orders attached to the user. */
  attached_orders: number;
  /** The list of users that can be used as a replacement. */
  valid_replacements: UserResponse[];
}

export interface UserModifyResponse {
  /** The name of the user. */
  name?: string;
  /** The email address of the user. */
  email?: string;
  /** The department the user is to be a part of. */
  department?: string;
  /** The role of the user. */
  role?: RoleResponse;
}

export interface UserCreateResponse {
  /** The name of the user. */
  name: string;
  /** The email address of the user. */
  email: string;
  /** The department the user is to be a part of. */
  department: string;
  /** The role of the user. */
  role: RoleResponse;
}

/**
 * User schema for the dashboard.

Includes the user's organisation and department.
 */
export interface UserDashboardResponse {
  /** The ID of the user. */
  id: string;
  /** The name of the user. */
  name: string;
  /** The email address of the user. */
  email: string;
  /** The total number of orders. */
  total_orders: number;
  /** The total price of all orders. */
  total_price: number;
  /** The total number of downloads. */
  total_downloads: number;
}

export interface PagedUserDashboardResponse {
  items?: UserDashboardResponse[];
  count: number;
}

/**
 * The target to validate, as a GeoJSON feature.
 */
export type TargetValidationResponseTarget = PointResponse | PolygonResponse;

export interface TargetValidationResponse {
  /** The target to validate, as a GeoJSON feature. */
  target: TargetValidationResponseTarget;
}

export interface OrderDashboardResponse {
  /** The index of the grouping period. */
  period: Date;
  /** The total cost of the orders. */
  cost: number;
  /** The total number of downloads. */
  downloads: number;
}

export interface PagedOrderResponse {
  items?: OrderResponse[];
  count: number;
}

/**
 * Schema for creating an order.
 */
export interface CreateOrderResponse {
  /** The user's name for the order. */
  name: string;
  /** The target of the order, as a GeoJSON feature. */
  target: CreateOrderResponseTarget;
  /** The ID of the product to use for the order. */
  product: string;
}

export interface ErrorResponse {
  /** The detail of the error. */
  detail: string;
}

export interface BandResponse {
  /** Name of the band */
  name: string;
  /** Wavelength of the band */
  wavelength: string;
}

export interface ProductDetailResponse {
  /** The ID of the product. */
  id: string;
  /** The name of the product. */
  name: string;
  /** The expected time (in seconds) from the order being placed to the first capture being completed. */
  turnaround_time: number;
  /** The per-pixel resolution, or ground sample distance, of the product (in meters). */
  resolution: number;
  /** The different bands available for the product. */
  bands: BandResponse[];
  /** The name of the satellite provider of the product. */
  provider: string;
  /** The minimum area for orders in square kilometers. */
  minimum_area: number;
  /** The price per square kilometer for the product. */
  price_per_km2: number;
  /** The radius of a point (in meters) that is guaranteed to be in the capture. */
  poi_radius: number;
  /** The maximum radius of the bounding circle of a polygon that can be captured by this product. */
  aoi_max_radius: number;
}

/**
 * The type of the geometry, must be 'Point'.
 */
export type PointResponseType = typeof PointResponseType[keyof typeof PointResponseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PointResponseType = {
  Point: 'Point',
} as const;

/**
 * A GeoJSON point.

See RFC 7946 § 3.1.2
 URL: https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.2
 */
export interface PointResponse {
  /** The type of the geometry, must be 'Point'. */
  type: PointResponseType;
  /** The coordinates of the point. */
  coordinates: number[];
}

/**
 * The target of the order, as a GeoJSON feature.
 */
export type CreateOrderResponseTarget = PointResponse | PolygonResponse;

/**
 * The target of the order, as a GeoJSON feature.
 */
export type OrderDetailResponseTarget = PointResponse | PolygonResponse;

/**
 * An enumeration.
 */
export type OrderStatusResponse = typeof OrderStatusResponse[keyof typeof OrderStatusResponse];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatusResponse = {
  initialising: 'initialising',
  dispatched: 'dispatched',
  in_progress: 'in_progress',
  processing: 'processing',
  completed: 'completed',
  failed: 'failed',
  unfeasible: 'unfeasible',
} as const;

export interface UserResponse {
  /** The ID of the user. */
  id: string;
  /** The name of the user. */
  name: string;
}

/**
 * A schema for an order.

An order is a request for a satellite to capture imagery of a target area.
 */
export interface OrderResponse {
  /** The ID of the order. */
  id: string;
  /** The user that created the order. */
  user: UserResponse;
  /** The status of the order. */
  status: OrderStatusResponse;
  /** The user's name for the order. */
  name: string;
  /** The time the order instance was created. */
  created_at: Date;
  /** The number of times the order has been downloaded. */
  download_count: number;
  /** The area of the target in square kilometers. */
  area: number;
  /** The department of the user that created the order. */
  department: DepartmentResponse;
  /** The price of the order. */
  price: number;
}

/**
 * Login details for a user
 */
export interface InviteLoginResponse {
  /** The new password for the user. */
  password: string;
}

/**
 * An enumeration.
 */
export type InvitationStatusResponse =
  typeof InvitationStatusResponse[keyof typeof InvitationStatusResponse];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvitationStatusResponse = {
  pending: 'pending',
  accepted: 'accepted',
  expired: 'expired',
  na: 'na',
} as const;

/**
 * The role of a user in an organisation.
 */
export type RoleResponse = typeof RoleResponse[keyof typeof RoleResponse];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleResponse = {
  administrator: 'administrator',
  creator: 'creator',
  viewer: 'viewer',
} as const;

/**
 * Detailed schema for Users.

Includes the department.
 */
export interface UserDetailResponse {
  /** The ID of the user. */
  id: string;
  /** The name of the user. */
  name: string;
  /** The email address of the user. */
  email: string;
  /** The department the user is part of. */
  department: DepartmentResponse;
  /** The role of the user. */
  role: RoleResponse;
  /** The status of the user's invite. */
  invite_status: InvitationStatusResponse;
  /** The date the user joined the organisation. */
  date_joined: Date;
}

export interface PagedUserDetailResponse {
  items?: UserDetailResponse[];
  count: number;
}

export interface DepartmentDeleteConflictResponse {
  /** The number of users attached to the department. */
  attached_users: number;
  /** The list of departments that can be used as a replacement. */
  valid_replacements: DepartmentResponse[];
}

export interface DepartmentModifyResponse {
  /** The new name of the department. */
  name: string;
}

export interface OrganisationResponse {
  /** The ID of the organisation. */
  id: string;
  /** The name of the organisation. */
  name: string;
  /** The URL for the logo of the organisation. */
  logo?: string;
}

/**
 * Detailed schema for departments.

Includes the organisation.
 */
export interface DepartmentDetailResponse {
  /** The ID of the department. */
  id: string;
  /** The name of the department. */
  name: string;
  /** The organisation the department is part of. */
  organisation: OrganisationResponse;
}

export interface DepartmentResponse {
  /** The ID of the department. */
  id: string;
  /** The name of the department. */
  name: string;
}

/**
 * The footprint of the capture.
 */
export type CaptureResponseFootprint = PolygonResponse | MultiPolygonResponse;

/**
 * Schema for a file.
 */
export interface FileResponse {
  /** The name of the file. */
  name: string;
  /** The size of the file in bytes. */
  size: number;
}

/**
 * The type of the geometry, must be 'Polygon'.
 */
export type MultiPolygonResponseType =
  typeof MultiPolygonResponseType[keyof typeof MultiPolygonResponseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultiPolygonResponseType = {
  MultiPolygon: 'MultiPolygon',
} as const;

/**
 * A GeoJSON multipolygon.

See RFC 7946 § 3.1.6
 URL: https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.7
 */
export interface MultiPolygonResponse {
  /** The type of the geometry, must be 'Polygon'. */
  type: MultiPolygonResponseType;
  /** The coordinates of the polygon, a list of lists of coordinates. */
  coordinates: number[][][][];
}

/**
 * The type of the geometry, must be 'Polygon'.
 */
export type PolygonResponseType = typeof PolygonResponseType[keyof typeof PolygonResponseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolygonResponseType = {
  Polygon: 'Polygon',
} as const;

/**
 * A GeoJSON polygon.

See RFC 7946 § 3.1.6
 URL: https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6
 */
export interface PolygonResponse {
  /** The type of the geometry, must be 'Polygon'. */
  type: PolygonResponseType;
  /** The coordinates of the polygon, a list of lists of coordinates. */
  coordinates: number[][][];
}

/**
 * An enumeration.
 */
export type CaptureStatusResponse =
  typeof CaptureStatusResponse[keyof typeof CaptureStatusResponse];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaptureStatusResponse = {
  queued: 'queued',
  processing: 'processing',
  completed: 'completed',
  failed: 'failed',
} as const;

/**
 * A schema for a capture.

Each successful order will have one or more captures associated with it.
A capture is an image capture by a specific satellite at a specific time.
 */
export interface CaptureResponse {
  /** The ID of the capture. */
  id: string;
  /** The status of the capture. */
  status: CaptureStatusResponse;
  /** The time the capture was executed. */
  timestamp: Date;
  /** The name of the satellite that executed the capture. */
  satellite: string;
  /** The footprint of the capture. */
  footprint?: CaptureResponseFootprint;
  /** The URL for the thumbnail of the capture. */
  thumbnail?: string;
  /** The data file of the capture. */
  data?: FileResponse;
  /** The time the capture instance was created. */
  created_at: Date;
  /** The time the capture status was last updated. */
  updated_at: Date;
  /** The number of times the capture has been downloaded. */
  download_count: number;
}

/**
 * A schema for an order.

An order is a request for a satellite to capture imagery of a target area.
 */
export interface OrderDetailResponse {
  /** The ID of the order. */
  id: string;
  /** The user that created the order. */
  user: UserResponse;
  /** The status of the order. */
  status: OrderStatusResponse;
  /** The user's name for the order. */
  name: string;
  /** The time the order instance was created. */
  created_at: Date;
  /** The number of times the order has been downloaded. */
  download_count: number;
  /** The area of the target in square kilometers. */
  area: number;
  /** The department of the user that created the order. */
  department: DepartmentResponse;
  /** The price of the order. */
  price: number;
  /** The target of the order, as a GeoJSON feature. */
  target: OrderDetailResponseTarget;
  /** The time the order status was last updated. */
  updated_at: Date;
  /** The product used for the order. */
  product: ProductDetailResponse;
  /** A list of captures connected to the order. */
  captures: CaptureResponse[];
}

/**
 * Old and new password for updating a user's password.
 */
export interface ResetPasswordResponse {
  /** The old password. */
  old_password: string;
  /** The new password. */
  new_password: string;
}

export type ResetPasswordErrorResponseField =
  typeof ResetPasswordErrorResponseField[keyof typeof ResetPasswordErrorResponseField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResetPasswordErrorResponseField = {
  old_password: 'old_password',
  new_password: 'new_password',
} as const;

/**
 * Error details for resetting a user's password.
 */
export interface ResetPasswordErrorResponse {
  field: ResetPasswordErrorResponseField;
  errors: string[];
}

/**
 * Login details for a user
 */
export interface LoginResponse {
  /** The email address of the user. */
  email: string;
  /** The password of the user. */
  password: string;
}

/**
 * Token pair for JWT authentication.

Access token is used to authenticate requests, and is short-lived.

New access tokens are generated with the refresh token.
 */
export interface JWTPairResponse {
  /** The refresh token, long expiry, used to refresh the access token if it expires. */
  refresh: string;
  /** The access token, short expiry, used to access the API. */
  access: string;
}

/**
 * Schema for account balances.
 */
export interface BalanceResponse {
  /** The total balance of the account. */
  balance: number;
  /** The sum cost of all pending orders. */
  pending_balance: number;
}
