import { extendTheme, StyleFunctionProps, theme as baseTheme } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import { Avatar } from './components/avatar';
import { Button } from './components/button';
import { Input } from './components/input';
import { Menu } from './components/menu';
import { Popover } from './components/popover';
import { Select } from './components/select';
import { Tag } from './components/tag';
import { Badge } from './components/badge';
import { Divider } from './components/divider';
import { Progress } from './components/progress';
import { Modal } from './components/modal';
import { Tooltip } from './components/tooltip';
import { Checkbox } from './components/checkbox';
import { Table } from './components/table';
import { Drawer } from './components/drawer';

const foundations = {
  fonts: { heading: 'Titillium Web, sans-serif', body: 'Titillium Web, sans-serif' },
  styles: {
    global: (props: StyleFunctionProps) => ({
      'html, body, #root': {
        bg: 'dark3',
        color: 'neutral2',
        height: '100%',
        margin: 0,
      },

      '*::placeholder': {
        color: 'neutral2',
      },

      // Mapbox

      '.mapboxgl-map': {
        fontFamily: 'Titillium Web, sans-serif',
      },

      '.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > *': {
        top: '12px !important',
      },

      '.mapboxgl-ctrl-geocoder *::-webkit-input-placeholder': {
        color: 'neutral4',
      },

      '.mapboxgl-ctrl-geocoder--icon-search': {
        top: '12px !important',
      },

      '.mapboxgl-ctrl-top-left .mapboxgl-ctrl': {
        margin: '20px 0 0 20px',
      },

      '.mapboxgl-ctrl-geocoder': {
        display: 'flex',
        alignItems: 'center',
        height: '42px !important',
        width: '400px !important',
        maxWidth: '400px !important',
        bgColor: 'dark4',
        caretColor: 'white',
        color: 'neutral2',
      },

      '.mapboxgl-ctrl-geocoder--button': {
        bg: 'dark4',
      },

      '.mapboxgl-ctrl-geocoder input': {
        color: 'neutral2',
      },

      '.mapboxgl-ctrl-geocoder input:focus': {
        outline: 'none',
        color: 'neutral2',
      },

      '.mapboxgl-ctrl-group button': {
        height: '50px !important',
        width: '50px !important',
      },

      // Mantine date picker

      '[data-in-range][data-selected]': {
        color: 'neutral2',
        bg: 'primary1 !important',
      },

      '[data-in-range]': {
        color: 'neutral2',
        bg: 'dark5 !important',
      },

      '[data-weekend]': {
        color: 'neutral2 !important',
      },

      '[data-outside]': {
        color: 'neutral4 !important',
      },
    }),
  },
};

export const theme: Record<string, any> = extendTheme({
  ...foundations,
  components: {
    Button,
    Input,
    Avatar,
    Tag,
    Badge,
    Select,
    Popover,
    Menu,
    Progress,
    Divider,
    Modal,
    Tooltip,
    Checkbox,
    Table,
    Drawer,
  },
  colors: {
    ...baseTheme.colors,

    dark1: '#2B2B2B',
    dark2: '#040404',
    dark3: '#131415',
    dark4: '#1C1D1E',
    dark5: '#2E2F34',
    dark6: '#101011',
    dark7: '#242526',

    neutral1: '#E7E7E8',
    neutral2: '#F3F3F3',
    neutral3: '#7F868C',
    neutral4: '#5F656D',
    neutral5: '#464B52',
    neutral6: '#B4BCC4',

    primary1: '#0171E2',
    primary2: '#17293C',
    primary3: '#3E9EFF',
    primary4: '#0f243c',

    primary: {
      50: '#E6F2FF',
      100: '#B8DBFF',
      200: '#8AC4FE',
      300: '#5DADFE',
      400: '#2F96FE',
      500: '#017FFE',
      600: '#0166CB',
      700: '#014C98',
      800: '#003366',
      900: '#001933',
    },

    red1: '#E8414B',
  },
});
