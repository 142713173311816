import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  Box,
  Icon,
  IconProps,
} from '@chakra-ui/react';
import React from 'react';
import { FaCheckCircle, FaExclamationTriangle, FaInfoCircle, FaTimesCircle } from 'react-icons/fa';

export type Status = 'success' | 'warning' | 'error' | 'info';

export interface ResultProps extends AlertProps {
  status: Status;
  title?: string;
  description?: string | React.ReactNode;
  extra?: React.ReactNode;
  icon?: React.ReactNode;
}

const StatusIcon = (props: { status: Status } & IconProps) => {
  const { status, ...rest } = props;

  switch (status) {
    case 'success':
      return <Icon as={FaCheckCircle} color="success" {...rest} />;
    case 'warning':
      return <Icon as={FaExclamationTriangle} color="warning" {...rest} />;
    case 'error':
      return <Icon as={FaTimesCircle} color="danger" {...rest} />;
    case 'info':
      return <Icon as={FaInfoCircle} color="info" {...rest} />;
  }
};

export const Result = (props: ResultProps) => {
  const { status, title, description, extra, icon, ...rest } = props;

  return (
    <Alert
      p={16}
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bg="transparent"
      color="neutral6"
      {...rest}
    >
      {icon ?? <StatusIcon status={status} boxSize="48px" mr={0} />}
      {title && (
        <AlertTitle mt={4} fontSize="md">
          {title}
        </AlertTitle>
      )}
      {description && (
        <AlertDescription mt={4} maxWidth="xl">
          {description}
        </AlertDescription>
      )}
      {extra && <Box mt={8}>{extra}</Box>}
    </Alert>
  );
};
