import { Button, HStack, Icon, IconButton, Spacer, Stack, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { Queries } from '../../../api';
import { SlideDrawer } from '../../../components/SlideDrawer';
import NiceModal from '@ebay/nice-modal-react';
import { DepartmentCreateModal } from './DepartmentCreateModal';
import { DepartmentDeleteModal } from './DepartmentDeleteModal';
import { DepartmentEditModal } from './DepartmentEditModal';
import { DepartmentResponse } from '../../../api/queries.schemas';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DepartmentsManageDrawer = (props: Props) => {
  const { isOpen, onClose } = props;

  const departmentsQuery = Queries.useDepartmentListDepartments();

  const departments = React.useMemo(() => {
    return departmentsQuery.data?.data ?? [];
  }, [departmentsQuery.data?.data]);

  const onClickAdd = React.useCallback(() => {
    NiceModal.show(DepartmentCreateModal);
  }, []);

  const onClickDelete = React.useCallback((department: DepartmentResponse) => {
    NiceModal.show(DepartmentDeleteModal, { department });
  }, []);

  const onClickEdit = React.useCallback((department: DepartmentResponse) => {
    NiceModal.show(DepartmentEditModal, { department });
  }, []);

  return (
    <SlideDrawer isOpen={isOpen} onClose={onClose}>
      <Stack spacing={8}>
        <HStack>
          <Text fontWeight="semibold" fontSize="18px">
            Departments
          </Text>
        </HStack>

        <Stack spacing={4} mb={4}>
          {_.map(departments, (department, index) => {
            return (
              <HStack key={department.id} spacing={4}>
                <Text>{department.name}</Text>
                <Spacer />
                <IconButton
                  variant="ghost"
                  aria-label="Edit"
                  icon={<Icon as={FaPencilAlt} />}
                  size="2xs"
                  onClick={() => onClickEdit(department)}
                />
                <IconButton
                  variant="ghost"
                  aria-label="Delete"
                  icon={<Icon as={FaTrash} />}
                  size="2xs"
                  _hover={{ color: 'red1' }}
                  onClick={() => onClickDelete(department)}
                />
              </HStack>
            );
          })}
          <Button
            variant="outline"
            color="primary3"
            leftIcon={<Icon as={FaPlus} />}
            onClick={onClickAdd}
          >
            Add new
          </Button>
        </Stack>
      </Stack>
    </SlideDrawer>
  );
};
