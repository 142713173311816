import { Avatar, Box, HStack, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import { FaArrowRight } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { OrderResponse } from '../../../api/queries.schemas';
import { ROUTES } from '../../../common/routes';
import { HumanizedDate } from '../../../components/HumanizedDate';
import { OrderStatusTag } from '../../../components/OrderStatusTag';
import { Table, TableProps } from '../../../components/Table';

const columnHelper = createColumnHelper<OrderResponse>();

const columns: ColumnDef<OrderResponse, any>[] = [
  columnHelper.accessor('name', {
    header: 'Order name',
    cell: (info) => (
      <Link as={NavLink} to={`${ROUTES.ORDER}/${info.row.original.id}`} fontWeight="semibold">
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (info) => <OrderStatusTag status={info.getValue()} />,
  }),
  columnHelper.accessor('price', {
    header: 'Cost',
    cell: (info) => {
      var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'AUD',
      });

      return <Text>{formatter.format(info.getValue())}</Text>;
    },
  }),
  columnHelper.accessor('created_at', {
    header: 'Created at',
    cell: (info) => <HumanizedDate date={info.getValue()} />,
  }),
  columnHelper.accessor('user', {
    header: 'User',
    cell: (info) => (
      <HStack spacing={3}>
        <Avatar w="32px" h="32px" name={info.getValue().name} size="14px" />
        <Text fontWeight="semibold">{info.getValue().name}</Text>
      </HStack>
    ),
  }),
  columnHelper.accessor('department', {
    header: 'Department',
    cell: (info) => <Text>{info.getValue().name}</Text>,
  }),

  columnHelper.accessor('id', {
    header: () => {
      return null;
    },
    cell: (info) => {
      if ((info.table.getState() as any).hoveredRowId === info.row.id) {
        return (
          <Box w="40px">
            <NavLink to={`${ROUTES.ORDER}/${info.getValue()}`}>
              <IconButton
                variant="secondary"
                aria-label="go-to"
                icon={<Icon as={FaArrowRight} />}
                bg="dark5"
                color="primary1"
                size="sm"
              />
            </NavLink>
          </Box>
        );
      }

      return <Box w="40px" />;
    },
  }),
];

interface Props extends Omit<TableProps<OrderResponse, unknown>, 'data' | 'columns'> {
  orders: OrderResponse[];
  empty: React.ReactElement;
}

export const OrdersTable = (props: Props) => {
  const { orders, empty, isLoading, error, pagination, ...rest } = props;
  const navigate = useNavigate();

  const onRowClick = (row: Row<OrderResponse>) => {
    navigate(`${ROUTES.ORDER}/${row.original.id}`);
  };

  return (
    <Table
      data={orders}
      columns={columns}
      isLoading={isLoading}
      error={error}
      pagination={pagination}
      onRowClick={onRowClick}
      empty={empty}
      {...rest}
    />
  );
};
