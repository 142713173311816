import axios from 'axios';
import qs from 'qs';
import { TokenManager } from '../common/TokenManager';
import * as Queries from './queries';
import * as Schema from './queries.schemas';

export { Queries, Schema };

export const initalizeAxios = (tokenManager: TokenManager) => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  axios.interceptors.request.use(async (config) => {
    const token = tokenManager.getAccessToken();

    if (config && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };

    return config;
  });

  axios.interceptors.response.use((originalResponse) => {
    handleDates(originalResponse.data);
    return originalResponse;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }

      const originalRequestConfig = error.config;

      return tokenManager.refreshToken().then(() => {
        return axios.request(originalRequestConfig);
      });
    },
  );
};

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = new Date(value); // default JS conversion
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  }
}
