import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../common/routes';
import { Result } from './Result';

export const Unauthorized = () => {
  return (
    <Result
      status="error"
      title="Unauthorized"
      description="Sorry, you are not authorized to access this page."
      extra={
        <Link to={ROUTES.HOME}>
          <Button>Back Home</Button>
        </Link>
      }
    />
  );
};
