import {
  autoUpdate,
  flip,
  FloatingContext,
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  FloatingTree,
  Middleware,
  offset,
  Placement,
  ReferenceType,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  UseFloatingProps,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import React from 'react';

const variants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    visibility: 'visible',
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    transitionEnd: {
      visibility: 'hidden',
    },
    opacity: 0,
    transition: {
      duration: 0.1,
      easings: 'easeOut',
    },
  },
};

export const FloatingOverlayContent = (props: { children: JSX.Element; isRoot?: boolean }) => {
  return props.isRoot ? (
    <FloatingOverlay lockScroll={false} style={{ zIndex: 1800 }}>
      {props.children}
    </FloatingOverlay>
  ) : (
    <>{props.children}</>
  );
};
export const FloatingFocusManagerContent = (props: {
  children: JSX.Element;
  context: FloatingContext<ReferenceType>;
  shouldInitialFocus?: boolean;
}) => {
  return props.shouldInitialFocus ? (
    <FloatingFocusManager context={props.context}>{props.children}</FloatingFocusManager>
  ) : (
    <>{props.children}</>
  );
};

interface Props {
  enabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  clientRect?: () => DOMRect;
  render: (data: { onOpenChange: (value: boolean) => void }) => React.ReactNode;
  middleware?: Middleware[];
  trigger?: 'hover' | 'click';
  children?: JSX.Element;
  isRoot?: boolean;
  placement?: Placement;
  shouldInitialFocus?: boolean;
}

const FloatingPopoverInternal = (props: Props) => {
  const { middleware, trigger, children, isRoot, shouldInitialFocus, placement, render } = props;
  const nodeId = useFloatingNodeId();

  const [isOpen, setIsOpen] = React.useState<boolean>(props.open || false);
  const open = props.open ?? isOpen;

  const onOpenChange = React.useCallback(
    (value: boolean) => {
      props.setOpen ? props.setOpen(value) : setIsOpen(value);
    },
    [props],
  );

  const options: UseFloatingProps = {
    open: open,
    onOpenChange,
    nodeId,
    whileElementsMounted: autoUpdate,
    placement: placement ?? 'right-start',
    strategy: 'fixed',
    middleware: middleware ?? [
      offset(5),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  };

  const { x, y, reference, floating, strategy, refs, context } = useFloating(options);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: trigger !== 'click',
    }),
    useHover(context, {
      enabled: trigger === 'hover',
      handleClose: safePolygon({
        blockPointerEvents: false,
      }),
      delay: {
        close: 30,
        open: 750,
      },
    }),
    useDismiss(context, {
      ancestorScroll: true,
    }),
  ]);

  React.useEffect(() => {
    if (props.clientRect) {
      reference({
        getBoundingClientRect: props.clientRect,
      });
    }
  }, [props.clientRect, reference]);

  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

  useIsomorphicLayoutEffect(() => {
    if (open && shouldInitialFocus) {
      refs.floating.current?.focus();
    }
  }, [open, shouldInitialFocus, refs.floating]);

  return (
    <FloatingNode id={nodeId}>
      {children &&
        React.cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <FloatingOverlayContent isRoot={isRoot}>
              <FloatingFocusManagerContent
                context={context}
                shouldInitialFocus={shouldInitialFocus}
              >
                <motion.div
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={variants}
                  {...getFloatingProps({
                    ref: floating,
                    style: {
                      position: strategy,
                      top: y ?? 0,
                      left: x ?? 0,
                      zIndex: 1800,
                    },
                  })}
                >
                  {render({ onOpenChange })}
                </motion.div>
              </FloatingFocusManagerContent>
            </FloatingOverlayContent>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </FloatingNode>
  );
};

export const FloatingPopover = (props: Props) => {
  const isRoot = props.isRoot ?? true;
  const enabled = props.enabled ?? true;
  const parentId = useFloatingParentNodeId();

  if (!enabled) {
    return <>{props.children}</>;
  }

  if (parentId == null) {
    return (
      <FloatingTree>
        <FloatingPopoverInternal {...props} isRoot={isRoot} />
      </FloatingTree>
    );
  }

  return <FloatingPopoverInternal {...props} />;
};
