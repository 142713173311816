import { Stack } from '@chakra-ui/react';
import { Geometry } from 'geojson';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { mapStyles } from '../../../common/mapbox';
import { useDidMountEffect } from '../../../hooks/useDidMountEffect';
import { SelectAreaPayload } from '../../../types/main';
import { useMapDrawActions } from '../hooks/useMapDrawActions';
import { ClearControl } from './ClearControl';
import { ImportFileControl } from './ImportFileControl';
import { PointControl } from './PointControl';
import { PolygonControl } from './PolygonControl';

type Props = {
  POIRadius: number;
  AOIMaxRadius: number;
  initialLayer?: Geometry;
  onSelectArea?: (payload: SelectAreaPayload) => void;
  mapStyle: keyof typeof mapStyles;
  isStylesLoaded: boolean;
};

export const DrawControls = (props: Props) => {
  const { initialLayer, mapStyle, isStylesLoaded } = props;

  const actions = useMapDrawActions(props);

  React.useEffect(() => {
    if (initialLayer && isStylesLoaded) {
      actions.addInitialLayer(initialLayer);
    }
  }, [actions, initialLayer, isStylesLoaded]);

  useDidMountEffect(() => {
    if (isStylesLoaded) {
      actions.init();
    }
  }, [mapStyle, isStylesLoaded]);

  useHotkeys('command+z, ctrl+z, delete', () => {
    actions.draw.trash();
  });

  return (
    <Stack spacing={3}>
      <PolygonControl actions={actions} />
      <PointControl actions={actions} />
      <ImportFileControl actions={actions} />
      <ClearControl actions={actions} />
    </Stack>
  );
};
