import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Queries } from '../../api';
import BackgroundImage from '../../assets/login-background-2.webp';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import WhiteLogo from '../../assets/white-logo.webp';
import { ROUTES } from '../../common/routes';
import { HookedFormControl } from '../../components/hooked/HookedFormControl';
import { HookedInput } from '../../components/hooked/HookedInput';
import { HookedSubmitButton } from '../../components/hooked/HookedSubmitButton';
import { useAppContext } from '../../hooks/useAppContext';

const defaultValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().min(4).required(),
});

export const Login = () => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const navigate = useNavigate();
  const { errorToast, tokenManager } = useAppContext();

  const mutation = Queries.useAuthLogin({
    mutation: {
      onError: () => {
        errorToast('Incorrect credentials');
      },
      onSuccess: ({ data }) => {
        tokenManager.setTokens(data);
        navigate(ROUTES.ORDERS);
      },
    },
  });

  const onSubmit = async (data: typeof defaultValues) => {
    return mutation.mutate({
      data: {
        email: data.email,
        password: data.password,
      },
    });
  };

  return (
    <Flex
      flexDir="column"
      h="full"
      bgImage={`url(${BackgroundImage})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      align="center"
      justify="center"
    >
      <Image pos="absolute" w="120px" top="24px" left="80px" src={WhiteLogo} />
      <Stack align="center" w="md" p="40px" rounded="md" spacing={8} bg="white" color="black">
        <Logo height={50} width={200} />
        <Text textTransform="uppercase">Login</Text>
        <Box w="full">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack w="full" spacing={4}>
                <HookedFormControl name="email">
                  <HookedInput
                    name="email"
                    placeholder="Enter email"
                    color="black"
                    size="lg"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <HookedFormControl name="password">
                  <HookedInput
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    color="black"
                    size="lg"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <HookedSubmitButton size="lg">Login</HookedSubmitButton>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </Flex>
  );
};
