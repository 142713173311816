import { Queries } from '../../../api';
import { makePaginationOptions } from '../../../common/pagination';
import { OrdersFilter } from './useOrdersFilter';

export const usePaginatedOrdersQuery = (filter: OrdersFilter) => {
  const query = Queries.useOrdersListOrders(
    {
      search: filter.name,
      status: filter.status,
      department: filter.department,
      user: filter.user,
      start: filter.start,
      end: filter.end,
      order_by: filter.orderBy ? [filter.orderBy] : undefined,
      limit: filter.pageSize,
      offset: filter.pageSize * (filter.currentPageNumber - 1),
    },
    {
      query: {
        keepPreviousData: true,
      },
    },
  );

  const total = query.data?.data.count ?? 0;

  const pagination = makePaginationOptions({
    total,
    resource: 'orders',
    ...filter,
  });

  return {
    query,
    pagination,
  };
};
