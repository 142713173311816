import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Queries } from '../../../api';
import { RoleResponse } from '../../../api/queries.schemas';
import { makeErrorMessage } from '../../../common/utils';
import { SelectButton } from '../../../components/forms/select/SelectButton';
import { SelectMenu } from '../../../components/forms/select/SelectMenu';
import { SelectOption } from '../../../components/forms/select/SelectOption';
import { HookedFormControl } from '../../../components/hooked/HookedFormControl';
import { HookedInput } from '../../../components/hooked/HookedInput';
import { HookedRadioStack } from '../../../components/hooked/HookedRadioStack';
import { HookedSelect } from '../../../components/hooked/HookedSelect';
import { HookedSubmitButton } from '../../../components/hooked/HookedSubmitButton';
import { useAppContext } from '../../../hooks/useAppContext';

const validationSchema = Yup.object({
  name: Yup.string().required('User name is a required field'),
  email: Yup.string().email('Invalid email address').required('Email is a required field'),
  department: Yup.string().required('Department is a required field'),
  role: Yup.string().required('Role is a required field'),
});

interface FormValues {
  name: string;
  email: string;
  department: string;
  role: RoleResponse;
}

interface InviteUserModalProps {}

export const InviteUserModal = NiceModal.create<InviteUserModalProps>(() => {
  const { successToast, errorToast, queryClient } = useAppContext();
  const modal = useModal();

  const createUserMutation = Queries.useUserCreateUser({
    mutation: {
      onSettled: () => queryClient.invalidateQueries(Queries.getUserListUsersQueryKey()),
    },
  });
  const departmentsQuery = Queries.useDepartmentListDepartments();

  const departments = React.useMemo(() => {
    return departmentsQuery.data?.data ?? [];
  }, [departmentsQuery]);

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      email: '',
      department: '',
      role: RoleResponse.administrator,
    },
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  React.useEffect(() => {
    if (departments.length > 0) {
      setValue('department', departments[0].id);
    }
  }, [setValue, departments]);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      return createUserMutation
        .mutateAsync({
          data: {
            email: values.email,
            name: values.name,
            role: values.role,
            department: values.department,
          },
        })
        .then(() => {
          successToast('Your invite successfully sent');
          modal.hide();
        })
        .catch((err) => {
          errorToast(`Error inviting user - ${makeErrorMessage(err)}`);
        });
    },
    [modal, createUserMutation, successToast, errorToast],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="lg">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <ModalHeader fontSize="2xl" py={4}>
              Invite new member
            </ModalHeader>
            <ModalCloseButton py={8} />
            <ModalBody>
              <Stack>
                <HookedFormControl name="name" label="Name">
                  <HookedInput variant="filled" name="name" placeholder="Type name" />
                </HookedFormControl>
                <HookedFormControl name="email" label="Email">
                  <HookedInput variant="filled" name="email" placeholder="example@gmail.com" />
                </HookedFormControl>
                <HookedFormControl name="department" label="Department">
                  <HookedSelect name="department">
                    <SelectButton />
                    <SelectMenu>
                      {_.map(departments, (department) => (
                        <SelectOption
                          key={department.id}
                          label={department.name}
                          value={department.id}
                          raw={department}
                        />
                      ))}
                    </SelectMenu>
                  </HookedSelect>
                </HookedFormControl>
                <HookedFormControl name="role" label="Role">
                  <HookedRadioStack
                    name="role"
                    options={[
                      {
                        value: RoleResponse.viewer,
                        label: 'Viewer',
                        description: 'Viewers can view and download orders created by other users.',
                      },
                      {
                        value: RoleResponse.creator,
                        label: 'Creator',
                        description:
                          'Creators can create new orders, as well as everything a viewer can do.',
                      },
                      {
                        value: RoleResponse.administrator,
                        label: 'Administrator',
                        description:
                          'Administrators can create and manage users and departments, as well as everything a creator can do.',
                      },
                    ]}
                  />
                </HookedFormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={modal.hide}>
                Cancel
              </Button>
              <HookedSubmitButton>Send invite</HookedSubmitButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
