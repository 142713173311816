import { forwardRef, Textarea, TextareaProps, useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends TextareaProps {
  name: string;
}

export const HookedTextarea = forwardRef<Props, 'input'>((props, forwardedRef) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const mergedRef = useMergeRefs(ref, forwardedRef);

  return <Textarea {...rest} {...inputProps} value={inputProps.value ?? ''} ref={mergedRef} />;
});
