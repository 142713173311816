/**
 * Generated by orval v6.9.2 🍺
 * Do not edit manually.
 * Internal API
 * OpenAPI spec version: 0.5.0
 */
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  AuthRefreshParams,
  BalanceResponse,
  CaptureResponse,
  ContactEmailResponse,
  CreateOrderResponse,
  DeleteWithReplacementResponse,
  DepartmentDeleteConflictResponse,
  DepartmentDeleteDepartmentParams,
  DepartmentDetailResponse,
  DepartmentListDepartmentsParams,
  DepartmentModifyResponse,
  DepartmentResponse,
  ErrorResponse,
  InviteLoginResponse,
  JWTPairResponse,
  LoginResponse,
  OrderDashboardResponse,
  OrderDetailResponse,
  OrdersAggregateOrdersForDashboardParams,
  OrdersAutocompleteOrderNameParams,
  OrdersListOrdersParams,
  PagedOrderResponse,
  PagedUserDashboardResponse,
  PagedUserDetailResponse,
  ProductDetailResponse,
  ResetPasswordErrorResponse,
  ResetPasswordResponse,
  TargetValidationResponse,
  UserCreateResponse,
  UserDeleteConflictResponse,
  UserDetailResponse,
  UserListTopUsersForDashboardParams,
  UserListUsersAutocompleteParams,
  UserListUsersParams,
  UserModifyResponse,
  UserResponse,
} from './queries.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get the total balance and pending balance of the user's organisation's account.

    The available balance can be calculated as the total balance minus the pending balance.
    

Requires permission `accounts.view_account`.
 * @summary Balance
 */
export const Balance = (options?: AxiosRequestConfig): Promise<AxiosResponse<BalanceResponse>> => {
  return axios.get(`/api/accounts/balance`, options);
};

export const getBalanceQueryKey = () => [`/api/accounts/balance`];

export type BalanceQueryResult = NonNullable<Awaited<ReturnType<typeof Balance>>>;
export type BalanceQueryError = AxiosError<unknown>;

export const useBalance = <
  TData = Awaited<ReturnType<typeof Balance>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof Balance>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBalanceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof Balance>>> = ({ signal }) =>
    Balance({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof Balance>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Login a user with email and password.

Returns the details of the logged-in user if successful.
Returns 403 if the login was unsuccessful.
 * @summary Login
 */
export const AuthLogin = (
  loginResponse: LoginResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<JWTPairResponse>> => {
  return axios.post(`/api/auth/login`, loginResponse, options);
};

export type AuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof AuthLogin>>>;
export type AuthLoginMutationBody = LoginResponse;
export type AuthLoginMutationError = AxiosError<void>;

export const useAuthLogin = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof AuthLogin>>,
    TError,
    { data: LoginResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof AuthLogin>>,
    { data: LoginResponse }
  > = (props) => {
    const { data } = props ?? {};

    return AuthLogin(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof AuthLogin>>,
    TError,
    { data: LoginResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Refresh a user's access token.

Returns the details of the logged-in user if successful.
 * @summary Refresh
 */
export const AuthRefresh = (
  params: AuthRefreshParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<JWTPairResponse>> => {
  return axios.post(`/api/auth/token/refresh`, undefined, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export type AuthRefreshMutationResult = NonNullable<Awaited<ReturnType<typeof AuthRefresh>>>;

export type AuthRefreshMutationError = AxiosError<void>;

export const useAuthRefresh = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof AuthRefresh>>,
    TError,
    { params: AuthRefreshParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof AuthRefresh>>,
    { params: AuthRefreshParams }
  > = (props) => {
    const { params } = props ?? {};

    return AuthRefresh(params, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof AuthRefresh>>,
    TError,
    { params: AuthRefreshParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Logs out the currently logged-in user.
 * @summary Logout
 */
export const AuthLogout = (options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/auth/logout`, undefined, options);
};

export type AuthLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof AuthLogout>>>;

export type AuthLogoutMutationError = AxiosError<unknown>;

export const useAuthLogout = <
  TError = AxiosError<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof AuthLogout>>,
    TError,
    TVariables,
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof AuthLogout>>, TVariables> = () => {
    return AuthLogout(axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof AuthLogout>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};

/**
 * Reset the logged-in user's password.

The old password must be provided for security.

Password validation is performed by Django:
    Minimum length is 8 characters.
    Can't be all numbers.
    Can't be a common password.
    Can't be too similar to the user's email or name.

Returns 204 if the password was successfully reset.
Returns 400 if the new password is invalid or if the old password is incorrect.
 * @summary Change Password
 */
export const AuthChangePassword = (
  resetPasswordResponse: ResetPasswordResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/auth/password/change`, resetPasswordResponse, options);
};

export type AuthChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof AuthChangePassword>>
>;
export type AuthChangePasswordMutationBody = ResetPasswordResponse;
export type AuthChangePasswordMutationError = AxiosError<ResetPasswordErrorResponse>;

export const useAuthChangePassword = <
  TError = AxiosError<ResetPasswordErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof AuthChangePassword>>,
    TError,
    { data: ResetPasswordResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof AuthChangePassword>>,
    { data: ResetPasswordResponse }
  > = (props) => {
    const { data } = props ?? {};

    return AuthChangePassword(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof AuthChangePassword>>,
    TError,
    { data: ResetPasswordResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get all captures associated with the current user.

Requires permission `orders.view_capture`.
 * @summary Get Captures
 */
export const CapturesGetCaptures = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<CaptureResponse[]>> => {
  return axios.get(`/api/captures/`, options);
};

export const getCapturesGetCapturesQueryKey = () => [`/api/captures/`];

export type CapturesGetCapturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof CapturesGetCaptures>>
>;
export type CapturesGetCapturesQueryError = AxiosError<unknown>;

export const useCapturesGetCaptures = <
  TData = Awaited<ReturnType<typeof CapturesGetCaptures>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof CapturesGetCaptures>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCapturesGetCapturesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof CapturesGetCaptures>>> = ({ signal }) =>
    CapturesGetCaptures({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof CapturesGetCaptures>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get a specific capture.

    If the capture does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `orders.view_capture`.
 * @summary Get Capture
 */
export const CapturesGetCapture = (
  captureId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<CaptureResponse>> => {
  return axios.get(`/api/captures/${captureId}`, options);
};

export const getCapturesGetCaptureQueryKey = (captureId: string) => [`/api/captures/${captureId}`];

export type CapturesGetCaptureQueryResult = NonNullable<
  Awaited<ReturnType<typeof CapturesGetCapture>>
>;
export type CapturesGetCaptureQueryError = AxiosError<unknown>;

export const useCapturesGetCapture = <
  TData = Awaited<ReturnType<typeof CapturesGetCapture>>,
  TError = AxiosError<unknown>,
>(
  captureId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof CapturesGetCapture>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCapturesGetCaptureQueryKey(captureId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof CapturesGetCapture>>> = ({ signal }) =>
    CapturesGetCapture(captureId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof CapturesGetCapture>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!captureId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Redirects to a file URL for the thumbnail for a specific capture.

    If the capture does not exist, or is not associated with the current user, a 404 error is returned.

    If there is no thumbnail available, a status of 204 is returned.
    

Requires permission `orders.view_capture`.
 * @summary Get Capture Thumbnail
 */
export const CapturesGetCaptureThumbnail = (
  captureId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/captures/${captureId}/thumbnail`, options);
};

export const getCapturesGetCaptureThumbnailQueryKey = (captureId: string) => [
  `/api/captures/${captureId}/thumbnail`,
];

export type CapturesGetCaptureThumbnailQueryResult = NonNullable<
  Awaited<ReturnType<typeof CapturesGetCaptureThumbnail>>
>;
export type CapturesGetCaptureThumbnailQueryError = AxiosError<unknown>;

export const useCapturesGetCaptureThumbnail = <
  TData = Awaited<ReturnType<typeof CapturesGetCaptureThumbnail>>,
  TError = AxiosError<unknown>,
>(
  captureId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof CapturesGetCaptureThumbnail>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCapturesGetCaptureThumbnailQueryKey(captureId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof CapturesGetCaptureThumbnail>>> = ({
    signal,
  }) => CapturesGetCaptureThumbnail(captureId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof CapturesGetCaptureThumbnail>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!captureId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Redirects to a file URL for the preview for a specific capture.

    If the capture does not exist, or is not associated with the current user, a 404 error is returned.

    If there is no preview available, a status of 204 is returned.
    

Requires permission `orders.download_capture`.
 * @summary Get Capture Preview
 */
export const CapturesGetCapturePreview = (
  captureId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/captures/${captureId}/preview`, options);
};

export const getCapturesGetCapturePreviewQueryKey = (captureId: string) => [
  `/api/captures/${captureId}/preview`,
];

export type CapturesGetCapturePreviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof CapturesGetCapturePreview>>
>;
export type CapturesGetCapturePreviewQueryError = AxiosError<unknown>;

export const useCapturesGetCapturePreview = <
  TData = Awaited<ReturnType<typeof CapturesGetCapturePreview>>,
  TError = AxiosError<unknown>,
>(
  captureId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof CapturesGetCapturePreview>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCapturesGetCapturePreviewQueryKey(captureId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof CapturesGetCapturePreview>>> = ({
    signal,
  }) => CapturesGetCapturePreview(captureId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof CapturesGetCapturePreview>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!captureId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Redirects to a file URL for the bounds for a specific capture.

    If the capture does not exist, or is not associated with the current user, a 404 error is returned.

    If there is no bounds available, a status of 204 is returned.
    

Requires permission `orders.download_capture`.
 * @summary Get Capture Bounds
 */
export const CapturesGetCaptureBounds = (
  captureId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/captures/${captureId}/bounds`, options);
};

export const getCapturesGetCaptureBoundsQueryKey = (captureId: string) => [
  `/api/captures/${captureId}/bounds`,
];

export type CapturesGetCaptureBoundsQueryResult = NonNullable<
  Awaited<ReturnType<typeof CapturesGetCaptureBounds>>
>;
export type CapturesGetCaptureBoundsQueryError = AxiosError<unknown>;

export const useCapturesGetCaptureBounds = <
  TData = Awaited<ReturnType<typeof CapturesGetCaptureBounds>>,
  TError = AxiosError<unknown>,
>(
  captureId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof CapturesGetCaptureBounds>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCapturesGetCaptureBoundsQueryKey(captureId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof CapturesGetCaptureBounds>>> = ({
    signal,
  }) => CapturesGetCaptureBounds(captureId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof CapturesGetCaptureBounds>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!captureId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Redirects to a file URL for the data for a specific capture.

    If the capture does not exist, or is not associated with the current user, a 404 error is returned.

    If there is no data available, a status of 204 is returned.
    

Requires permission `orders.download_capture`.
 * @summary Get Capture Image
 */
export const CapturesGetCaptureImage = (
  captureId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/captures/${captureId}/data`, options);
};

export const getCapturesGetCaptureImageQueryKey = (captureId: string) => [
  `/api/captures/${captureId}/data`,
];

export type CapturesGetCaptureImageQueryResult = NonNullable<
  Awaited<ReturnType<typeof CapturesGetCaptureImage>>
>;
export type CapturesGetCaptureImageQueryError = AxiosError<unknown>;

export const useCapturesGetCaptureImage = <
  TData = Awaited<ReturnType<typeof CapturesGetCaptureImage>>,
  TError = AxiosError<unknown>,
>(
  captureId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof CapturesGetCaptureImage>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCapturesGetCaptureImageQueryKey(captureId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof CapturesGetCaptureImage>>> = ({
    signal,
  }) => CapturesGetCaptureImage(captureId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof CapturesGetCaptureImage>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!captureId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get all departments associated with the current user.
 * @summary List Departments
 */
export const DepartmentListDepartments = (
  params?: DepartmentListDepartmentsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DepartmentResponse[]>> => {
  return axios.get(`/api/departments/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getDepartmentListDepartmentsQueryKey = (params?: DepartmentListDepartmentsParams) => [
  `/api/departments/`,
  ...(params ? [params] : []),
];

export type DepartmentListDepartmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof DepartmentListDepartments>>
>;
export type DepartmentListDepartmentsQueryError = AxiosError<unknown>;

export const useDepartmentListDepartments = <
  TData = Awaited<ReturnType<typeof DepartmentListDepartments>>,
  TError = AxiosError<unknown>,
>(
  params?: DepartmentListDepartmentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof DepartmentListDepartments>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDepartmentListDepartmentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof DepartmentListDepartments>>> = ({
    signal,
  }) => DepartmentListDepartments(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof DepartmentListDepartments>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create a new department.

Requires permission `users.add_department`.
 * @summary Create Department
 */
export const DepartmentCreateDepartment = (
  departmentModifyResponse: DepartmentModifyResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DepartmentDetailResponse>> => {
  return axios.post(`/api/departments/`, departmentModifyResponse, options);
};

export type DepartmentCreateDepartmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof DepartmentCreateDepartment>>
>;
export type DepartmentCreateDepartmentMutationBody = DepartmentModifyResponse;
export type DepartmentCreateDepartmentMutationError = AxiosError<unknown>;

export const useDepartmentCreateDepartment = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof DepartmentCreateDepartment>>,
    TError,
    { data: DepartmentModifyResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof DepartmentCreateDepartment>>,
    { data: DepartmentModifyResponse }
  > = (props) => {
    const { data } = props ?? {};

    return DepartmentCreateDepartment(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof DepartmentCreateDepartment>>,
    TError,
    { data: DepartmentModifyResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get all departments associated with the current user.
 * @summary Get Users Department
 */
export const DepartmentGetUsersDepartment = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DepartmentDetailResponse>> => {
  return axios.get(`/api/departments/me`, options);
};

export const getDepartmentGetUsersDepartmentQueryKey = () => [`/api/departments/me`];

export type DepartmentGetUsersDepartmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof DepartmentGetUsersDepartment>>
>;
export type DepartmentGetUsersDepartmentQueryError = AxiosError<unknown>;

export const useDepartmentGetUsersDepartment = <
  TData = Awaited<ReturnType<typeof DepartmentGetUsersDepartment>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof DepartmentGetUsersDepartment>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDepartmentGetUsersDepartmentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof DepartmentGetUsersDepartment>>> = ({
    signal,
  }) => DepartmentGetUsersDepartment({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof DepartmentGetUsersDepartment>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get a specific department.

    If the department does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `users.view_department`.
 * @summary Get Department
 */
export const DepartmentGetDepartment = (
  departmentId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DepartmentDetailResponse>> => {
  return axios.get(`/api/departments/${departmentId}`, options);
};

export const getDepartmentGetDepartmentQueryKey = (departmentId: string) => [
  `/api/departments/${departmentId}`,
];

export type DepartmentGetDepartmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof DepartmentGetDepartment>>
>;
export type DepartmentGetDepartmentQueryError = AxiosError<unknown>;

export const useDepartmentGetDepartment = <
  TData = Awaited<ReturnType<typeof DepartmentGetDepartment>>,
  TError = AxiosError<unknown>,
>(
  departmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof DepartmentGetDepartment>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDepartmentGetDepartmentQueryKey(departmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof DepartmentGetDepartment>>> = ({
    signal,
  }) => DepartmentGetDepartment(departmentId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof DepartmentGetDepartment>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!departmentId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update a specific department.

    If the department does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `users.change_department`.
 * @summary Update Department
 */
export const DepartmentUpdateDepartment = (
  departmentId: string,
  departmentModifyResponse: DepartmentModifyResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DepartmentDetailResponse>> => {
  return axios.patch(`/api/departments/${departmentId}`, departmentModifyResponse, options);
};

export type DepartmentUpdateDepartmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof DepartmentUpdateDepartment>>
>;
export type DepartmentUpdateDepartmentMutationBody = DepartmentModifyResponse;
export type DepartmentUpdateDepartmentMutationError = AxiosError<unknown>;

export const useDepartmentUpdateDepartment = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof DepartmentUpdateDepartment>>,
    TError,
    { departmentId: string; data: DepartmentModifyResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof DepartmentUpdateDepartment>>,
    { departmentId: string; data: DepartmentModifyResponse }
  > = (props) => {
    const { departmentId, data } = props ?? {};

    return DepartmentUpdateDepartment(departmentId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof DepartmentUpdateDepartment>>,
    TError,
    { departmentId: string; data: DepartmentModifyResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete a specific department.

    If the department does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `users.delete_department`.
 * @summary Delete Department
 */
export const DepartmentDeleteDepartment = (
  departmentId: string,
  params?: DepartmentDeleteDepartmentParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/departments/${departmentId}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export type DepartmentDeleteDepartmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof DepartmentDeleteDepartment>>
>;

export type DepartmentDeleteDepartmentMutationError = AxiosError<DepartmentDeleteConflictResponse>;

export const useDepartmentDeleteDepartment = <
  TError = AxiosError<DepartmentDeleteConflictResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof DepartmentDeleteDepartment>>,
    TError,
    { departmentId: string; params?: DepartmentDeleteDepartmentParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof DepartmentDeleteDepartment>>,
    { departmentId: string; params?: DepartmentDeleteDepartmentParams }
  > = (props) => {
    const { departmentId, params } = props ?? {};

    return DepartmentDeleteDepartment(departmentId, params, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof DepartmentDeleteDepartment>>,
    TError,
    { departmentId: string; params?: DepartmentDeleteDepartmentParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get Invitation
 */
export const InvitationGetInvitation = (
  invitationId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetailResponse>> => {
  return axios.get(`/api/invitations/${invitationId}`, options);
};

export const getInvitationGetInvitationQueryKey = (invitationId: string) => [
  `/api/invitations/${invitationId}`,
];

export type InvitationGetInvitationQueryResult = NonNullable<
  Awaited<ReturnType<typeof InvitationGetInvitation>>
>;
export type InvitationGetInvitationQueryError = AxiosError<unknown>;

export const useInvitationGetInvitation = <
  TData = Awaited<ReturnType<typeof InvitationGetInvitation>>,
  TError = AxiosError<unknown>,
>(
  invitationId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof InvitationGetInvitation>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInvitationGetInvitationQueryKey(invitationId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof InvitationGetInvitation>>> = ({
    signal,
  }) => InvitationGetInvitation(invitationId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof InvitationGetInvitation>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!invitationId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Accept Invitation
 */
export const InvitationAcceptInvitation = (
  invitationId: string,
  inviteLoginResponse: InviteLoginResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<JWTPairResponse>> => {
  return axios.post(`/api/invitations/${invitationId}`, inviteLoginResponse, options);
};

export type InvitationAcceptInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof InvitationAcceptInvitation>>
>;
export type InvitationAcceptInvitationMutationBody = InviteLoginResponse;
export type InvitationAcceptInvitationMutationError = AxiosError<ResetPasswordErrorResponse | void>;

export const useInvitationAcceptInvitation = <
  TError = AxiosError<ResetPasswordErrorResponse | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof InvitationAcceptInvitation>>,
    TError,
    { invitationId: string; data: InviteLoginResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof InvitationAcceptInvitation>>,
    { invitationId: string; data: InviteLoginResponse }
  > = (props) => {
    const { invitationId, data } = props ?? {};

    return InvitationAcceptInvitation(invitationId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof InvitationAcceptInvitation>>,
    TError,
    { invitationId: string; data: InviteLoginResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Creates a new order.

    The user's organisation will be charged for the cost of the order,
    so it is important to ensure that the user has confirmed the price.

    If the user does not have enough funds, a 402 error is returned.
    If the user already has an order with this name, a 409 error is returned.
    

Requires permission `orders.add_order`.
 * @summary Create Order
 */
export const OrdersCreateOrder = (
  createOrderResponse: CreateOrderResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrderDetailResponse>> => {
  return axios.post(`/api/orders/`, createOrderResponse, options);
};

export type OrdersCreateOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof OrdersCreateOrder>>
>;
export type OrdersCreateOrderMutationBody = CreateOrderResponse;
export type OrdersCreateOrderMutationError = AxiosError<ErrorResponse>;

export const useOrdersCreateOrder = <
  TError = AxiosError<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof OrdersCreateOrder>>,
    TError,
    { data: CreateOrderResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof OrdersCreateOrder>>,
    { data: CreateOrderResponse }
  > = (props) => {
    const { data } = props ?? {};

    return OrdersCreateOrder(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof OrdersCreateOrder>>,
    TError,
    { data: CreateOrderResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Lists all orders for the user.

Requires permission `orders.view_order`.
 * @summary List Orders
 */
export const OrdersListOrders = (
  params?: OrdersListOrdersParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PagedOrderResponse>> => {
  return axios.get(`/api/orders/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getOrdersListOrdersQueryKey = (params?: OrdersListOrdersParams) => [
  `/api/orders/`,
  ...(params ? [params] : []),
];

export type OrdersListOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof OrdersListOrders>>>;
export type OrdersListOrdersQueryError = AxiosError<unknown>;

export const useOrdersListOrders = <
  TData = Awaited<ReturnType<typeof OrdersListOrders>>,
  TError = AxiosError<unknown>,
>(
  params?: OrdersListOrdersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof OrdersListOrders>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersListOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof OrdersListOrders>>> = ({ signal }) =>
    OrdersListOrders(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof OrdersListOrders>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Autocomplete order names based on a provided string.

Requires permission `orders.view_order`.
 * @summary Autocomplete Order Name
 */
export const OrdersAutocompleteOrderName = (
  params: OrdersAutocompleteOrderNameParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string[]>> => {
  return axios.get(`/api/orders/autocomplete`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getOrdersAutocompleteOrderNameQueryKey = (
  params: OrdersAutocompleteOrderNameParams,
) => [`/api/orders/autocomplete`, ...(params ? [params] : [])];

export type OrdersAutocompleteOrderNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof OrdersAutocompleteOrderName>>
>;
export type OrdersAutocompleteOrderNameQueryError = AxiosError<unknown>;

export const useOrdersAutocompleteOrderName = <
  TData = Awaited<ReturnType<typeof OrdersAutocompleteOrderName>>,
  TError = AxiosError<unknown>,
>(
  params: OrdersAutocompleteOrderNameParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof OrdersAutocompleteOrderName>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersAutocompleteOrderNameQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof OrdersAutocompleteOrderName>>> = ({
    signal,
  }) => OrdersAutocompleteOrderName(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof OrdersAutocompleteOrderName>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Aggregate Orders For Dashboard
 */
export const OrdersAggregateOrdersForDashboard = (
  params: OrdersAggregateOrdersForDashboardParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrderDashboardResponse[]>> => {
  return axios.get(`/api/orders/dashboard`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getOrdersAggregateOrdersForDashboardQueryKey = (
  params: OrdersAggregateOrdersForDashboardParams,
) => [`/api/orders/dashboard`, ...(params ? [params] : [])];

export type OrdersAggregateOrdersForDashboardQueryResult = NonNullable<
  Awaited<ReturnType<typeof OrdersAggregateOrdersForDashboard>>
>;
export type OrdersAggregateOrdersForDashboardQueryError = AxiosError<unknown>;

export const useOrdersAggregateOrdersForDashboard = <
  TData = Awaited<ReturnType<typeof OrdersAggregateOrdersForDashboard>>,
  TError = AxiosError<unknown>,
>(
  params: OrdersAggregateOrdersForDashboardParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof OrdersAggregateOrdersForDashboard>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersAggregateOrdersForDashboardQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof OrdersAggregateOrdersForDashboard>>> = ({
    signal,
  }) => OrdersAggregateOrdersForDashboard(params, { signal, ...axiosOptions });

  const query = useQuery<
    Awaited<ReturnType<typeof OrdersAggregateOrdersForDashboard>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Gets an order by ID.

    If the order does not exist, or the user is not the owner of the order, a 404 error is returned.
    

Requires permission `orders.view_order`.
 * @summary Get Order
 */
export const OrdersGetOrder = (
  orderId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrderDetailResponse>> => {
  return axios.get(`/api/orders/${orderId}`, options);
};

export const getOrdersGetOrderQueryKey = (orderId: string) => [`/api/orders/${orderId}`];

export type OrdersGetOrderQueryResult = NonNullable<Awaited<ReturnType<typeof OrdersGetOrder>>>;
export type OrdersGetOrderQueryError = AxiosError<unknown>;

export const useOrdersGetOrder = <
  TData = Awaited<ReturnType<typeof OrdersGetOrder>>,
  TError = AxiosError<unknown>,
>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof OrdersGetOrder>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersGetOrderQueryKey(orderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof OrdersGetOrder>>> = ({ signal }) =>
    OrdersGetOrder(orderId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof OrdersGetOrder>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!orderId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Lists all captures for an order.

    If the order does not exist, or the user is not the owner of the order, a 404 error is returned.
    

Requires permission `orders.view_order`.
Requires permission `orders.view_capture`.
 * @summary List Captures For Order
 */
export const OrdersListCapturesForOrder = (
  orderId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<CaptureResponse[]>> => {
  return axios.get(`/api/orders/${orderId}/captures`, options);
};

export const getOrdersListCapturesForOrderQueryKey = (orderId: string) => [
  `/api/orders/${orderId}/captures`,
];

export type OrdersListCapturesForOrderQueryResult = NonNullable<
  Awaited<ReturnType<typeof OrdersListCapturesForOrder>>
>;
export type OrdersListCapturesForOrderQueryError = AxiosError<unknown>;

export const useOrdersListCapturesForOrder = <
  TData = Awaited<ReturnType<typeof OrdersListCapturesForOrder>>,
  TError = AxiosError<unknown>,
>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof OrdersListCapturesForOrder>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersListCapturesForOrderQueryKey(orderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof OrdersListCapturesForOrder>>> = ({
    signal,
  }) => OrdersListCapturesForOrder(orderId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof OrdersListCapturesForOrder>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!orderId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get all products associated with the current user.

Requires permission `orders.view_product`.
 * @summary List Products
 */
export const ProductsListProducts = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProductDetailResponse[]>> => {
  return axios.get(`/api/products/`, options);
};

export const getProductsListProductsQueryKey = () => [`/api/products/`];

export type ProductsListProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof ProductsListProducts>>
>;
export type ProductsListProductsQueryError = AxiosError<unknown>;

export const useProductsListProducts = <
  TData = Awaited<ReturnType<typeof ProductsListProducts>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof ProductsListProducts>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProductsListProductsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ProductsListProducts>>> = ({ signal }) =>
    ProductsListProducts({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof ProductsListProducts>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get a specific product.

    If the product does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `orders.view_product`.
 * @summary Get Product
 */
export const ProductsGetProduct = (
  productId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProductDetailResponse>> => {
  return axios.get(`/api/products/${productId}`, options);
};

export const getProductsGetProductQueryKey = (productId: string) => [`/api/products/${productId}`];

export type ProductsGetProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof ProductsGetProduct>>
>;
export type ProductsGetProductQueryError = AxiosError<unknown>;

export const useProductsGetProduct = <
  TData = Awaited<ReturnType<typeof ProductsGetProduct>>,
  TError = AxiosError<unknown>,
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ProductsGetProduct>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProductsGetProductQueryKey(productId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ProductsGetProduct>>> = ({ signal }) =>
    ProductsGetProduct(productId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof ProductsGetProduct>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!productId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Validates that a target conforms to the product requirements.

    Returns a 204 if the target is valid, or a 400 if it is not.

    If the product does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `orders.view_product`.
 * @summary Validate Product
 */
export const ProductsValidateProduct = (
  productId: string,
  targetValidationResponse: TargetValidationResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/products/${productId}/validate`, targetValidationResponse, options);
};

export type ProductsValidateProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof ProductsValidateProduct>>
>;
export type ProductsValidateProductMutationBody = TargetValidationResponse;
export type ProductsValidateProductMutationError = AxiosError<ErrorResponse>;

export const useProductsValidateProduct = <
  TError = AxiosError<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ProductsValidateProduct>>,
    TError,
    { productId: string; data: TargetValidationResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ProductsValidateProduct>>,
    { productId: string; data: TargetValidationResponse }
  > = (props) => {
    const { productId, data } = props ?? {};

    return ProductsValidateProduct(productId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof ProductsValidateProduct>>,
    TError,
    { productId: string; data: TargetValidationResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Redirects to a file URL for the spec sheet for a specific product.

    If the product does not exist, or is not associated with the current user, a 404 error is returned.

    If there is no spec sheet available, a status of 204 is returned.
    

Requires permission `orders.view_product`.
 * @summary Get Product Spec Sheet
 */
export const ProductsGetProductSpecSheet = (
  productId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/products/${productId}/spec_sheet`, options);
};

export const getProductsGetProductSpecSheetQueryKey = (productId: string) => [
  `/api/products/${productId}/spec_sheet`,
];

export type ProductsGetProductSpecSheetQueryResult = NonNullable<
  Awaited<ReturnType<typeof ProductsGetProductSpecSheet>>
>;
export type ProductsGetProductSpecSheetQueryError = AxiosError<unknown>;

export const useProductsGetProductSpecSheet = <
  TData = Awaited<ReturnType<typeof ProductsGetProductSpecSheet>>,
  TError = AxiosError<unknown>,
>(
  productId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ProductsGetProductSpecSheet>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProductsGetProductSpecSheetQueryKey(productId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ProductsGetProductSpecSheet>>> = ({
    signal,
  }) => ProductsGetProductSpecSheet(productId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof ProductsGetProductSpecSheet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!productId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get the details of the currently logged-in user.
 * @summary Get Logged In User
 */
export const UserGetLoggedInUser = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetailResponse>> => {
  return axios.get(`/api/users/me`, options);
};

export const getUserGetLoggedInUserQueryKey = () => [`/api/users/me`];

export type UserGetLoggedInUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof UserGetLoggedInUser>>
>;
export type UserGetLoggedInUserQueryError = AxiosError<unknown>;

export const useUserGetLoggedInUser = <
  TData = Awaited<ReturnType<typeof UserGetLoggedInUser>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof UserGetLoggedInUser>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetLoggedInUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof UserGetLoggedInUser>>> = ({ signal }) =>
    UserGetLoggedInUser({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof UserGetLoggedInUser>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get the logo of the organisation of the currently logged-in user.

If there is no data available, a status of 204 is returned.
 * @summary Get Logged In User Logo
 */
export const UserGetLoggedInUserLogo = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/users/me/logo`, options);
};

export const getUserGetLoggedInUserLogoQueryKey = () => [`/api/users/me/logo`];

export type UserGetLoggedInUserLogoQueryResult = NonNullable<
  Awaited<ReturnType<typeof UserGetLoggedInUserLogo>>
>;
export type UserGetLoggedInUserLogoQueryError = AxiosError<unknown>;

export const useUserGetLoggedInUserLogo = <
  TData = Awaited<ReturnType<typeof UserGetLoggedInUserLogo>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof UserGetLoggedInUserLogo>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetLoggedInUserLogoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof UserGetLoggedInUserLogo>>> = ({
    signal,
  }) => UserGetLoggedInUserLogo({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof UserGetLoggedInUserLogo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * List users for autocomplete.
 * @summary List Users Autocomplete
 */
export const UserListUsersAutocomplete = (
  params?: UserListUsersAutocompleteParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserResponse[]>> => {
  return axios.get(`/api/users/autocomplete`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getUserListUsersAutocompleteQueryKey = (params?: UserListUsersAutocompleteParams) => [
  `/api/users/autocomplete`,
  ...(params ? [params] : []),
];

export type UserListUsersAutocompleteQueryResult = NonNullable<
  Awaited<ReturnType<typeof UserListUsersAutocomplete>>
>;
export type UserListUsersAutocompleteQueryError = AxiosError<unknown>;

export const useUserListUsersAutocomplete = <
  TData = Awaited<ReturnType<typeof UserListUsersAutocomplete>>,
  TError = AxiosError<unknown>,
>(
  params?: UserListUsersAutocompleteParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof UserListUsersAutocomplete>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserListUsersAutocompleteQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof UserListUsersAutocomplete>>> = ({
    signal,
  }) => UserListUsersAutocomplete(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof UserListUsersAutocomplete>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary List Top Users For Dashboard
 */
export const UserListTopUsersForDashboard = (
  params?: UserListTopUsersForDashboardParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PagedUserDashboardResponse>> => {
  return axios.get(`/api/users/dashboard`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getUserListTopUsersForDashboardQueryKey = (
  params?: UserListTopUsersForDashboardParams,
) => [`/api/users/dashboard`, ...(params ? [params] : [])];

export type UserListTopUsersForDashboardQueryResult = NonNullable<
  Awaited<ReturnType<typeof UserListTopUsersForDashboard>>
>;
export type UserListTopUsersForDashboardQueryError = AxiosError<unknown>;

export const useUserListTopUsersForDashboard = <
  TData = Awaited<ReturnType<typeof UserListTopUsersForDashboard>>,
  TError = AxiosError<unknown>,
>(
  params?: UserListTopUsersForDashboardParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof UserListTopUsersForDashboard>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserListTopUsersForDashboardQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof UserListTopUsersForDashboard>>> = ({
    signal,
  }) => UserListTopUsersForDashboard(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof UserListTopUsersForDashboard>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get all users associated with the current user.

Requires permission `users.view_user`.
 * @summary List Users
 */
export const UserListUsers = (
  params?: UserListUsersParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PagedUserDetailResponse>> => {
  return axios.get(`/api/users/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getUserListUsersQueryKey = (params?: UserListUsersParams) => [
  `/api/users/`,
  ...(params ? [params] : []),
];

export type UserListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof UserListUsers>>>;
export type UserListUsersQueryError = AxiosError<unknown>;

export const useUserListUsers = <
  TData = Awaited<ReturnType<typeof UserListUsers>>,
  TError = AxiosError<unknown>,
>(
  params?: UserListUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof UserListUsers>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserListUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof UserListUsers>>> = ({ signal }) =>
    UserListUsers(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof UserListUsers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create a new user.

Requires permission `users.add_user`.
 * @summary Create User
 */
export const UserCreateUser = (
  userCreateResponse: UserCreateResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetailResponse>> => {
  return axios.post(`/api/users/`, userCreateResponse, options);
};

export type UserCreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof UserCreateUser>>>;
export type UserCreateUserMutationBody = UserCreateResponse;
export type UserCreateUserMutationError = AxiosError<unknown>;

export const useUserCreateUser = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof UserCreateUser>>,
    TError,
    { data: UserCreateResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof UserCreateUser>>,
    { data: UserCreateResponse }
  > = (props) => {
    const { data } = props ?? {};

    return UserCreateUser(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof UserCreateUser>>,
    TError,
    { data: UserCreateResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get a specific user.

    If the user does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `users.view_user`.
 * @summary Get User
 */
export const UserGetUser = (
  userId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetailResponse>> => {
  return axios.get(`/api/users/${userId}`, options);
};

export const getUserGetUserQueryKey = (userId: string) => [`/api/users/${userId}`];

export type UserGetUserQueryResult = NonNullable<Awaited<ReturnType<typeof UserGetUser>>>;
export type UserGetUserQueryError = AxiosError<unknown>;

export const useUserGetUser = <
  TData = Awaited<ReturnType<typeof UserGetUser>>,
  TError = AxiosError<unknown>,
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof UserGetUser>>, TError, TData>;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof UserGetUser>>> = ({ signal }) =>
    UserGetUser(userId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof UserGetUser>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!userId, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update a specific user.

    If the user does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `users.change_user`.
 * @summary Update User
 */
export const UserUpdateUser = (
  userId: string,
  userModifyResponse: UserModifyResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetailResponse>> => {
  return axios.patch(`/api/users/${userId}`, userModifyResponse, options);
};

export type UserUpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof UserUpdateUser>>>;
export type UserUpdateUserMutationBody = UserModifyResponse;
export type UserUpdateUserMutationError = AxiosError<unknown>;

export const useUserUpdateUser = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof UserUpdateUser>>,
    TError,
    { userId: string; data: UserModifyResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof UserUpdateUser>>,
    { userId: string; data: UserModifyResponse }
  > = (props) => {
    const { userId, data } = props ?? {};

    return UserUpdateUser(userId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof UserUpdateUser>>,
    TError,
    { userId: string; data: UserModifyResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete a specific user.

    If the user does not exist, or is not associated with the current user, a 404 error is returned.
    

Requires permission `users.delete_user`.
 * @summary Delete User
 */
export const UserDeleteUser = (
  userId: string,
  deleteWithReplacementResponse: DeleteWithReplacementResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/users/${userId}`, { data: deleteWithReplacementResponse, ...options });
};

export type UserDeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof UserDeleteUser>>>;
export type UserDeleteUserMutationBody = DeleteWithReplacementResponse;
export type UserDeleteUserMutationError = AxiosError<UserDeleteConflictResponse>;

export const useUserDeleteUser = <
  TError = AxiosError<UserDeleteConflictResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof UserDeleteUser>>,
    TError,
    { userId: string; data: DeleteWithReplacementResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof UserDeleteUser>>,
    { userId: string; data: DeleteWithReplacementResponse }
  > = (props) => {
    const { userId, data } = props ?? {};

    return UserDeleteUser(userId, data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof UserDeleteUser>>,
    TError,
    { userId: string; data: DeleteWithReplacementResponse },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Send a contact email to the support team.
 * @summary Contact
 */
export const Contact = (
  contactEmailResponse: ContactEmailResponse,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/frontend/contact`, contactEmailResponse, options);
};

export type ContactMutationResult = NonNullable<Awaited<ReturnType<typeof Contact>>>;
export type ContactMutationBody = ContactEmailResponse;
export type ContactMutationError = AxiosError<unknown>;

export const useContact = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof Contact>>,
    TError,
    { data: ContactEmailResponse },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof Contact>>,
    { data: ContactEmailResponse }
  > = (props) => {
    const { data } = props ?? {};

    return Contact(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof Contact>>,
    TError,
    { data: ContactEmailResponse },
    TContext
  >(mutationFn, mutationOptions);
};
