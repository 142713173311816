const variants = {
  filled: {
    field: {
      bg: 'dark4',
      rounded: 'md',
      color: 'neutral2',
      _hover: {
        bg: 'dark1',
      },
    },
  },
};

export const Select = {
  variants,
};
