import { Box, Heading, HStack, Spacer } from '@chakra-ui/react';
import { Content } from '../../components/Content';
import { DateRange, RangeDatePicker } from '../../components/forms/RangeDatePicker';
import { useOrdersFilter } from '../Orders/hooks/useOrdersFilter';
import { UsersCard } from './components/UsersCard';
import { OrdersDetails } from './components/OrdersDetails';
import { OrdersCard } from './components/OrdersCard';
import dayjs from 'dayjs';

export const Dashboard = () => {
  const ordersFilter = useOrdersFilter({
    pageSize: 5,
  });

  const onDateChange = (value: DateRange) => {
    const [start, end] = value;
    ordersFilter.setStart(start ?? undefined);
    ordersFilter.setEnd(end ?? undefined);
  };

  const dateRange: DateRange = [
    ordersFilter.start ?? dayjs().endOf('month').subtract(11, 'month').startOf('month').toDate(),
    ordersFilter.end ?? dayjs().endOf('month').toDate(),
  ];

  return (
    <Content minH="full" w="full">
      <HStack spacing={8}>
        <Heading>Dashboard</Heading>
        <Spacer />
        <HStack spacing={4}>
          <Box w="280px">
            <RangeDatePicker
              value={dateRange}
              onChange={onDateChange}
              presets={[
                'thisMonth',
                'lastMonth',
                'thisQuarter',
                'lastQuarter',
                'thisYear',
                'lastYear',
              ]}
            />
          </Box>
        </HStack>
      </HStack>
      <HStack spacing={8} mt={8}>
        <OrdersCard dateRange={dateRange} />
        <UsersCard dateRange={dateRange} />
      </HStack>
      <OrdersDetails ordersFilter={ordersFilter} />
    </Content>
  );
};
