import _ from 'lodash';
import React from 'react';
import { Queries } from '../../../api/';
import { AutoComplete, AutoCompleteItem } from '../../../components/forms/AutoComplete';
import { UsersFilter } from '../hooks/useUsersFilter';

interface Props {
  filter: UsersFilter;
}

export const UsersNameAutocomplete = (props: Props) => {
  const { filter } = props;

  const autoCompleteQuery = Queries.useUserListUsersAutocomplete(
    {
      q: filter.name ?? '',
    },
    {
      query: {
        enabled: !!filter.name,
        keepPreviousData: true,
      },
    },
  );

  const onQueryChange = React.useCallback(
    (value: string) => {
      filter.setName(value);
      autoCompleteQuery.refetch();
    },
    [filter, autoCompleteQuery],
  );

  const onChange = React.useCallback(
    (item: AutoCompleteItem<string>) => filter.setName(item.value),
    [filter],
  );

  const items = _.map(autoCompleteQuery.data?.data ?? [], (value) => ({
    value: value.name,
    label: value.name,
    raw: value.name,
  }));

  const value = {
    value: filter.name ?? '',
    label: filter.name ?? '',
    raw: filter.name ?? '',
  };

  return (
    <AutoComplete
      variant="filled"
      size="lg"
      w="xs"
      placeholder="Search by name"
      value={value}
      query={filter.name ?? ''}
      items={items}
      onChange={onChange}
      onQueryChange={onQueryChange}
      isLoading={autoCompleteQuery.isFetching}
    />
  );
};
