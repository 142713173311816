import { Checkbox, CheckboxGroup, Stack, Text } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { useUserListUsers } from '../../../api/queries';
import { UserDetailResponse, UserResponse } from '../../../api/queries.schemas';
import { Error } from '../../../components/Error';
import { AutoComplete, AutoCompleteItem } from '../../../components/forms/AutoComplete';

interface Props {
  value: string[] | undefined;
  onChange: (value: string[] | undefined) => void;
}

export const OrdersUsersFilter = (props: Props) => {
  const [query, setQuery] = React.useState('');
  const value = {
    value: query,
    label: query,
    raw: {
      id: query,
      name: query,
    },
  };

  const { data, error } = useUserListUsers({
    limit: 1000,
    search: query,
  });

  const users: UserDetailResponse[] = data?.data.items ?? [];

  const items = _.map(users, (user) => ({
    value: user.id,
    label: user.name,
    raw: user,
  }));

  const onChange = React.useCallback(
    (item: AutoCompleteItem<UserResponse>) => {
      props.onChange(_.concat(props.value ?? [], item.value));
    },
    [props],
  );

  const onCheckboxChange = React.useCallback(
    (values: string[]) => {
      props.onChange(values);
    },
    [props],
  );

  if (error) {
    return <Error title="Unable to load users filter data" />;
  }

  return (
    <Stack spacing={4}>
      <AutoComplete
        value={value}
        onChange={onChange}
        query={query}
        onQueryChange={setQuery}
        items={items}
        placeholder="Search"
      />
      <CheckboxGroup size="md" value={props.value ?? []} onChange={onCheckboxChange}>
        <Stack spacing={4}>
          {_.map(items, (i) => (
            <Checkbox key={i.value} value={i.value} spacing={4}>
              <Text>{i.label}</Text>
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Stack>
  );
};
