import { Box, Button, HStack, Spacer, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import _ from 'lodash';
import React from 'react';
import { useController } from 'react-hook-form';
import { ProductDetailResponse } from '../../api/queries.schemas';
import { ChooseProductModal } from '../../pages/Orders/components/ChooseProductModal';
import { ProductDetailsList } from '../../pages/Orders/components/ProductDetailsList';

interface Props {
  name: string;
  products: ProductDetailResponse[];
}

export const HookedProductChooser = (props: Props) => {
  const { name, products } = props;

  const { field } = useController({ name });

  const onSelect = React.useCallback(
    (value: string) => {
      field.onChange(_.find(products, (p) => p.id === value));
    },
    [field, products],
  );

  const onClick = React.useCallback(() => {
    NiceModal.show(ChooseProductModal, { products, value: field.value, onSelect: onSelect });
  }, [field, products, onSelect]);

  if (_.isNil(field.value)) {
    return (
      <Button variant="link" color="primary3" onClick={onClick}>
        Select tasking type
      </Button>
    );
  }

  return (
    <Box>
      <HStack mb={2}>
        <Text fontSize="md" color="neutral6">
          {field.value.name}
        </Text>
        <Spacer />
        <Button variant="link" onClick={onClick}>
          Change
        </Button>
      </HStack>
      <ProductDetailsList product={field.value} showCount={3} />
    </Box>
  );
};
