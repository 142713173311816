import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { UserContextProvider } from '../context/UserContext';

export const OrganisationLayout = () => {
  return (
    <UserContextProvider>
      <Flex flexDir="column" h="full">
        <Navbar />
        <Box h="full" flexGrow={1} overflowY="auto">
          <Outlet />
        </Box>
      </Flex>
    </UserContextProvider>
  );
};
