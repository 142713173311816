import { ChakraProvider } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { theme } from './theme/theme';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { initalizeAxios } from './api';
import { TokenManager } from './common/TokenManager';
import { TokenManagerContextProvider } from './context/TokenManagerContext';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const tokenManager = new TokenManager();

initalizeAxios(tokenManager);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ChakraProvider theme={theme}>
    <TokenManagerContextProvider tokenManager={tokenManager}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <NiceModal.Provider>
            <App />
          </NiceModal.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </TokenManagerContextProvider>
  </ChakraProvider>,
);
