import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const variants = {
  solid: {
    color: 'neutral1',
    bg: 'primary1',
    rounded: 'sm',
    textTransform: 'uppercase',
    _hover: {
      bg: 'primary3',
    },
  },

  ghost: {
    color: 'neutral3',
    textTransform: 'uppercase',
    _hover: {
      bg: 'inherit',
      color: 'neutral1',
    },
  },

  link: {
    color: 'primary3',
    _active: {
      color: 'primary1',
    },
  },

  outline: {
    borderColor: 'dark4',
    _hover: {
      color: 'neutral2',
      bg: 'primary3',
    },
    _active: {
      bg: 'primary1',
    },
  },

  nav: (props: StyleFunctionProps) => ({
    ...props.theme.components.Button.variants.ghost(props),
    color: 'neutral1',
    textTransform: 'uppercase',
    rounded: 'none',
    h: 'full',
    fontSize: '16px',
    _hover: {
      bg: 'dark3',
      color: 'primary3',
    },
    _active: {
      bg: 'dark3',
      color: 'primary3',
    },
  }),

  secondary: (props: StyleFunctionProps) => ({
    ...props.theme.components.Button.variants.ghost(props),
    bg: 'dark4',
    color: 'neutral2',
    rounded: 'sm',
    textTransform: 'inherit',
    _hover: {
      bg: 'dark5',
      color: 'primary3',
    },
    _active: {
      bg: 'dark5',
      color: 'white',
    },
  }),

  inlineSelect: (props: StyleFunctionProps) => ({
    _hover: {
      bg: 'transparent',
    },
    _focus: {
      bg: 'transparent',
      borderColor: 'gray.400',
    },
    _focusVisible: {
      bg: 'transparent',
      border: 'none',
    },
  }),
  icon: (props: StyleFunctionProps) => ({
    ...props.theme.components.Button.variants.ghost(props),
    minW: 'fit-content',
    h: 'fit-content',
    bg: 'transparent',
    _hover: {
      bg: 'transparent',
      color: 'primary1',
    },
    _active: {
      bg: 'transparent',
    },
  }),
};

export const Button = {
  variants,
};
