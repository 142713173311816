import { Box, SimpleGrid } from '@chakra-ui/react';
import React from 'react';

type DescriptionItem = {
  title: string;
  content: JSX.Element;
};

interface Props {
  items: DescriptionItem[];
}

export const DescriptionList = (props: Props) => {
  const { items } = props;

  return (
    <SimpleGrid
      columns={2}
      rowGap={4}
      columnGap={4}
      templateColumns="max-content auto"
      alignItems="start"
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Box fontFamily="heading" fontWeight="bold">
            {item.title}
          </Box>
          <Box>{item.content}</Box>
        </React.Fragment>
      ))}
    </SimpleGrid>
  );
};
