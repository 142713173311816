import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Queries } from '../../api';
import BackgroundImage from '../../assets/login-background-2.webp';
import { ROUTES } from '../../common/routes';
import { HookedFormControl } from '../../components/hooked/HookedFormControl';
import { HookedInput } from '../../components/hooked/HookedInput';
import { HookedSubmitButton } from '../../components/hooked/HookedSubmitButton';
import { useAppContext } from '../../hooks/useAppContext';

const defaultValues = {
  old_password: '',
  new_password: '',
};

const validationSchema = Yup.object({
  old_password: Yup.string().min(4, 'Old password must be at least 8 characters').required(),
  new_password: Yup.string().min(8, 'New password must be at least 8 characters').required(),
});

export const ChangePassword = () => {
  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const { errorToast, successToast } = useAppContext();

  const mutation = Queries.useAuthChangePassword();

  const onSubmit = async (data: typeof defaultValues) => {
    return mutation
      .mutateAsync({ data })
      .then(() => {
        successToast('Password changed successfully');
        navigate(ROUTES.HOME);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          errorToast('Old password is incorrect');
        } else {
          errorToast('Error occurred');
        }
      });
  };

  return (
    <Flex
      flexDir="column"
      h="full"
      bgImage={`url(${BackgroundImage})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      align="center"
      justify="center"
    >
      <Stack align="center" w="md" p="40px" rounded="md" spacing={8} bg="white" color="black">
        <Text textTransform="uppercase">Change password</Text>
        <Box w="full">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack w="full" spacing={4}>
                <HookedFormControl name="old_password">
                  <HookedInput
                    name="old_password"
                    placeholder="Current password"
                    size="lg"
                    color="black"
                    type="password"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <HookedFormControl name="new_password">
                  <HookedInput
                    name="new_password"
                    type="password"
                    placeholder="New password"
                    size="lg"
                    color="black"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <Box fontSize="sm" color="gray.400">
                  <Text>Password must be more than 8 characters</Text>
                  <Text>Password cannot contain all numbers</Text>
                  <Text>Password cannot be similar to the username</Text>
                </Box>
                <HookedSubmitButton size="lg" textTransform="uppercase" w="full">
                  Change password
                </HookedSubmitButton>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </Flex>
  );
};
