import { Button, ButtonProps } from '@chakra-ui/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface Props extends ButtonProps, Pick<NavLinkProps, 'to'> {}

export const NavButton = (props: Props) => {
  const { to, children, ...rest } = props;

  return (
    <NavLink to={to} style={{ height: '100%' }}>
      {({ isActive }) => (
        <Button variant="nav" isActive={isActive} {...rest}>
          {children}
        </Button>
      )}
    </NavLink>
  );
};
