import { Navigate, Route, Routes } from 'react-router-dom';
import { RoleResponse } from './api/queries.schemas';
import { ROUTES } from './common/routes';
import { PermissionRoute } from './components/PermissionRoute';
import { OrganisationLayout } from './layouts/OrganisationLayout';
import { ChangePassword } from './pages/Auth/ChangePassword';
import { Invite } from './pages/Auth/Invite';
import { Login } from './pages/Auth/Login';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { CreateOrder } from './pages/Orders/CreateOrder';
import { Order } from './pages/Orders/Order';
import { Orders } from './pages/Orders/Orders';
import { Users } from './pages/Users/Users';
import { Landing } from './pages/Landing/Landing';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<OrganisationLayout />}>
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <PermissionRoute role={[RoleResponse.administrator]}>
              <Dashboard />
            </PermissionRoute>
          }
        />
        <Route
          path={ROUTES.USERS}
          element={
            <PermissionRoute role={[RoleResponse.administrator]}>
              <Users />
            </PermissionRoute>
          }
        />
        <Route path={ROUTES.ORDERS} element={<Orders />} />
        <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.ORDERS} replace />} />
        <Route path={`${ROUTES.ORDER}/:id`} element={<Order />} />
        <Route
          path={ROUTES.CREATE_ORDER}
          element={
            <PermissionRoute role={[RoleResponse.administrator, RoleResponse.creator]}>
              <CreateOrder />
            </PermissionRoute>
          }
        />
        <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
      </Route>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={`${ROUTES.INVITE}/:id`} element={<Invite />} />
    </Routes>
  );
};

export default App;
