import { Button, Divider, Flex, Heading, HStack, Icon, Spacer } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { FaBriefcase, FaFilter, FaUser } from 'react-icons/fa';
import { Content } from '../../components/Content';
import { DepartmentsManageDrawer } from '../Departments/components/DepartmentsManageDrawer';
import { InviteUserModal } from './components/InviteUserModal';
import { UsersFilterDrawer } from './components/UsersFilterDrawer';
import { UsersNameAutocomplete } from './components/UsersNameAutocomplete';
import { UsersSortByMenu } from './components/UsersSortByMenu';
import { UsersTable } from './components/UsersTable';
import { usePaginatedUsersQuery } from './hooks/usePaginatedUsersQuery';
import { useUsersFilter } from './hooks/useUsersFilter';

type DrawerState = 'filter' | 'departments' | 'closed';

interface Props {}

export const Users = (props: Props) => {
  const usersFilter = useUsersFilter();
  const { query, pagination } = usePaginatedUsersQuery(usersFilter);
  const users = query.data?.data.items ?? [];
  const [drawerState, setDrawerState] = React.useState<DrawerState>('closed');

  const closeDrawer = () => setDrawerState('closed');

  const onClickDepartments = React.useCallback(() => {
    setDrawerState((v) => (v === 'departments' ? 'closed' : 'departments'));
  }, []);

  const onClickFilter = React.useCallback(() => {
    setDrawerState((v) => (v === 'filter' ? 'closed' : 'filter'));
  }, []);

  const onClickInvite = React.useCallback(() => {
    NiceModal.show(InviteUserModal);
  }, []);

  return (
    <Flex flexDir="row" minH="full">
      <Content w="full" minH="full">
        <HStack spacing={8}>
          <Heading>User management</Heading>
          <UsersNameAutocomplete filter={usersFilter} />
          <Spacer />
          <HStack spacing={4}>
            <UsersSortByMenu filter={usersFilter} />
            <Button variant="secondary" leftIcon={<Icon as={FaFilter} />} onClick={onClickFilter}>
              Filters
            </Button>
            <Divider h="40px" orientation="vertical" />
            <Button
              variant="secondary"
              leftIcon={<Icon as={FaBriefcase} />}
              onClick={onClickDepartments}
            >
              Departments
            </Button>
            <Button leftIcon={<Icon as={FaUser} />} onClick={onClickInvite}>
              Invite User
            </Button>
          </HStack>
        </HStack>

        <UsersTable
          users={users}
          isLoading={query.isLoading}
          error={query.error}
          pagination={pagination}
          mt={8}
        />
      </Content>
      <UsersFilterDrawer
        filter={usersFilter}
        isOpen={drawerState === 'filter'}
        onClose={closeDrawer}
      />
      <DepartmentsManageDrawer isOpen={drawerState === 'departments'} onClose={closeDrawer} />
    </Flex>
  );
};
