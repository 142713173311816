import {
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Stat,
  StatHelpText,
  StatNumber,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { currencyFormat } from '../../../common/utils';
import React from 'react';

// TODO: Properly center tier prop positioning

interface OptionProps {
  id: number;
  description: string;
  color: string;
}

interface OptionPropsList extends Array<OptionProps> {}

interface PackageTierProps {
  title: string;
  options: OptionPropsList;
  price: number;
  checked?: boolean;
}
const PackageTier = ({ title, options, price }: PackageTierProps) => {
  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: 'center',
        md: 'space-between',
      }}
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems="center"
    >
      <Heading size={'xl'} minW={{ base: '0px', md: '2xs', lg: 'xs' }}>
        {title}
      </Heading>
      <List spacing={3} minW={{ base: '0px', md: '2xs', lg: 'xs' }}>
        {options.map((option, id) => (
          <ListItem key={option.id}>
            <ListIcon as={FaCheckCircle} color={option.color} />
            {option.description}
          </ListItem>
        ))}
      </List>
      <Stat minW={{ base: '0px', md: '4xs' }} textAlign={{ base: 'center', md: 'end' }}>
        <StatNumber>{currencyFormat(price)}</StatNumber>
        <StatHelpText>per km²</StatHelpText>
      </Stat>
    </Stack>
  );
};

export const Pricing = () => {
  return (
    <VStack spacing={8}>
      <Heading size={'xl'} alignSelf="center">
        Tasking Types
      </Heading>
      <Text textAlign={'center'} pb={5}>
        Whether you require time sensitive data during a natural disaster, or new imagery to update
        mapping systems, Cobalt offers three tasking types to suit all use cases, priced
        accordingly.
      </Text>
      <Stack spacing={2} width={'100%'} direction={'column'}>
        <Divider />
        <PackageTier
          title="Standard"
          price={15.01}
          options={[
            { id: 1, description: '21 day turnaround', color: 'red.500' },
            { id: 2, description: '7 day order cutoff', color: 'orange.500' },
          ]}
        />
        <Divider />
        <PackageTier
          title={'Priority'}
          price={22.55}
          options={[
            { id: 1, description: '7 day turnaround', color: 'orange.500' },
            { id: 2, description: '12 hour order cutoff', color: 'green.500' },
          ]}
        />
        <Divider />
        <PackageTier
          title={'Rush'}
          price={33.82}
          options={[
            { id: 1, description: '24 hour turnaround', color: 'green.500' },
            { id: 2, description: '12 hour order cutoff', color: 'green.500' },
          ]}
        />
        <Divider />
      </Stack>
      <Button
        as="a"
        href="/tasking_types.png"
        target="_blank"
        bg="blue.400"
        rounded="full"
        color="white"
        _hover={{ bg: 'blue.500' }}
      >
        More Information
      </Button>
    </VStack>
  );
};
