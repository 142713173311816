import React from 'react';
import { Result, ResultProps } from './Result';

interface Props extends Omit<ResultProps, 'status'> {
  title?: string;
  description?: React.ReactNode;
  extra?: React.ReactNode;
}

export const Error = (props: Props) => {
  const { title, description, extra, ...rest } = props;

  return (
    <Result
      {...rest}
      status="error"
      title={title ?? 'Server error'}
      description={description ?? 'Sorry, something broke. Please contact support.'}
      extra={extra}
    />
  );
};
