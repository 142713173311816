import { Queries } from '../../../api';
import { makePaginationOptions } from '../../../common/pagination';
import { UsersFilter } from './useUsersFilter';

export const usePaginatedUsersQuery = (filter: UsersFilter) => {
  const query = Queries.useUserListUsers(
    {
      search: filter.name,
      department: filter.department,
      role: filter.roles,
      order_by: filter.orderBy ? [filter.orderBy] : undefined,
      limit: filter.pageSize,
      invite_status: filter.inviteStatus,
      offset: filter.pageSize * (filter.currentPageNumber - 1),
    },
    {
      query: {
        keepPreviousData: true,
      },
    },
  );

  const total = query.data?.data.count ?? 0;

  const pagination = makePaginationOptions({
    total,
    resource: 'users',
    ...filter,
  });

  return {
    query,
    pagination,
  };
};
