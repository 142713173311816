import { Box, Button, HStack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { Queries } from '../../../api';
import { currencyFormat, currencyFormatShort } from '../../../common/utils';
import { Card } from '../../../components/Card';
import { Error } from '../../../components/Error';
import { DateRange } from '../../../components/forms/RangeDatePicker';
import { Spin } from '../../../components/Spin';
import { OrdersChart } from './OrdersChart';

type ChartType = 'costs' | 'downloads';

interface Props {
  dateRange: DateRange;
}

export const OrdersCard = (props: Props) => {
  const { dateRange } = props;

  const [chartType, setChartType] = React.useState<ChartType>('costs');
  const [start, end] = dateRange;

  const { data, isLoading, error } = Queries.useOrdersAggregateOrdersForDashboard({
    start: start ?? dayjs().subtract(1, 'year').startOf('year').toDate(),
    end: end ?? undefined,
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const orderDashboardResponses = data?.data;
  const total = _.sumBy(orderDashboardResponses, chartType === 'costs' ? 'cost' : 'downloads');
  const formattedTotal = chartType === 'costs' ? currencyFormat(total) : total;

  const yFormatter = (value: number) =>
    chartType === 'costs' ? currencyFormatShort(value) : value.toString();

  const chartData = React.useMemo(
    () =>
      _.map(orderDashboardResponses, (response, index) => ({
        index,
        period: response.period,
        value: chartType === 'downloads' ? response.downloads : response.cost,
      })),
    [chartType, orderDashboardResponses],
  );

  const onClickTotalCosts = React.useCallback(() => {
    setChartType('costs');
  }, []);

  const onClickTotalDownloads = React.useCallback(() => {
    setChartType('downloads');
  }, []);

  let content;

  if (error) {
    content = <Error title="Unable to fetch orders information" />;
  } else if (isLoading) {
    content = <Spin />;
  } else {
    content = (
      <Box h="full" w="99%">
        <OrdersChart data={chartData} yFormatter={yFormatter} />
      </Box>
    );
  }

  return (
    <Card
      w="full"
      p={0}
      leftContent={
        <HStack px={8} py={4}>
          <Text fontSize="2xl" fontWeight="semibold">
            {chartType === 'costs' ? 'Total cost' : 'Total downloads'}
          </Text>
          <Text fontSize="2xl">{formattedTotal}</Text>
        </HStack>
      }
      rightContent={
        <HStack px={8} py={4}>
          <Button
            variant="secondary"
            color="neutral6"
            isActive={chartType === 'costs'}
            onClick={onClickTotalCosts}
            borderWidth="1px"
            borderColor="dark4"
            _active={{
              borderColor: 'primary1',
            }}
          >
            Total costs
          </Button>
          <Button
            variant="secondary"
            color="neutral6"
            isActive={chartType === 'downloads'}
            onClick={onClickTotalDownloads}
            borderWidth="1px"
            borderColor="dark4"
            _active={{
              borderColor: 'primary1',
            }}
          >
            Downloads
          </Button>
        </HStack>
      }
    >
      <Box h="md">{content}</Box>
    </Card>
  );
};
