import { Tag } from '@chakra-ui/react';
import { CaptureStatusResponse } from '../api/queries.schemas';

interface Props {
  status: CaptureStatusResponse;
}

export const CaptureStatusTag = (props: Props) => {
  switch (props.status) {
    case CaptureStatusResponse.queued:
      return (
        <Tag variant="solid" bg="rgba(180, 188, 196, 0.2)" color="#B4BCC4">
          Queued
        </Tag>
      );
    case CaptureStatusResponse.completed:
      return (
        <Tag variant="solid" bg="rgba(38, 156, 128, 0.2)" color="#269C80">
          Completed
        </Tag>
      );
    case CaptureStatusResponse.failed:
      return (
        <Tag variant="solid" bg="rgba(235,65,75,0.2)" color="#E8414B">
          Failed
        </Tag>
      );
    case CaptureStatusResponse.processing:
      return (
        <Tag variant="solid" bg="rgba(222, 172, 43, 0.2)" color="#DEAC2B">
          Processing
        </Tag>
      );
  }
};
