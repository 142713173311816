import {
  Button,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import BackgroundImage from '../../../assets/landing-background.webp';
import CobaltLogo from '../../../assets/cobalt-logo-white.webp';
import CobaltMockup from '../../../assets/cobalt-mockup.webp';
import React, { RefObject } from 'react';

// TODO:
//     - Improve background resolution

interface HeroProps {
  startRef: RefObject<HTMLDivElement>;
  contactRef: RefObject<HTMLDivElement>;
}

export const Hero = ({ startRef, contactRef }: HeroProps) => {
  const executeContactScroll = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Flex
      w="full"
      h="100vh"
      backgroundImage={BackgroundImage}
      backgroundSize="cover"
      backgroundPosition="center center"
      alignItems="center"
      justifyContent="center"
    >
      <Center>
        <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={10} maxWidth="5xl" p={5}>
          <VStack align="flex-start">
            <Image src={CobaltLogo} mx={-3} py={3} />
            <Heading size="md" textAlign={{ base: 'center', md: 'start' }}>
              On demand satellite imagery at your fingertips.
            </Heading>
            <Text textAlign={{ base: 'center', md: 'start' }} pb={10}>
              Cobalt is Spiral Blue’s new user friendly tasking interface that is making Earth
              observation faster, cheaper, and easier.
            </Text>
            <Stack direction="row" alignSelf={{ base: 'center', md: 'start' }}>
              <Button
                as="a"
                href="/login"
                bg="blue.400"
                rounded="full"
                color="white"
                _hover={{ bg: 'blue.500' }}
              >
                Log In
              </Button>
              <Button
                bg="whiteAlpha.300"
                rounded="full"
                color="white"
                _hover={{ bg: 'whiteAlpha.500' }}
                onClick={executeContactScroll}
              >
                Enquire
              </Button>
            </Stack>
          </VStack>
          <Center>
            <Image src={CobaltMockup} />
          </Center>
        </SimpleGrid>
      </Center>
    </Flex>
  );
};
