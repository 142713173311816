import _ from 'lodash';
import { useController } from 'react-hook-form';
import { DepartmentResponse } from '../../api/queries.schemas';
import { Select } from '../forms/select/Select';
import { SelectButton } from '../forms/select/SelectButton';
import { SelectMenu } from '../forms/select/SelectMenu';
import { SelectOption } from '../forms/select/SelectOption';

interface Props {
  name: string;
  departments: DepartmentResponse[];
}

export const HookedReplacementDepartmentSelect = (props: Props) => {
  const { name, departments, ...rest } = props;
  const { field } = useController({ name });

  const options = _.map(departments, (department) => ({
    label: department.name,
    value: department.id,
    raw: department.id,
  }));

  return (
    <Select value={field.value} onChange={field.onChange} {...rest}>
      <SelectButton placeholder="Select department" />
      <SelectMenu>
        {options.map((option) => (
          <SelectOption key={option.value} {...option} />
        ))}
      </SelectMenu>
    </Select>
  );
};
