const baseStyle = () => ({
  control: {
    bg: 'dark5',
    border: 0,
    _checked: {
      bg: 'primary1',
    },
  },
});

export const Checkbox = {
  baseStyle,
};
