import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  List,
  Spinner,
} from '@chakra-ui/react';
import { Combobox } from '@headlessui/react';
import { FaSearch } from 'react-icons/fa';

export interface AutoCompleteItem<A> {
  value: string;
  label: string;
  raw: A;
}

export interface AutoCompleteProps<A> extends Omit<InputProps, 'onChange' | 'value'> {
  value: AutoCompleteItem<A>;
  query: string;
  items: AutoCompleteItem<A>[];
  isLoading?: boolean;
  onChange: (value: AutoCompleteItem<A>) => void;
  onQueryChange: (value: string) => void;
}

export const AutoComplete = <A extends unknown>(props: AutoCompleteProps<A>) => {
  const { value, query, items, isLoading, onChange, onQueryChange, ...rest } = props;

  return (
    <Combobox as={Box} value={value} onChange={onChange}>
      <Box pos="relative">
        <InputGroup variant="filled" size="lg">
          <InputLeftElement pointerEvents="none">
            <Icon as={FaSearch} />
          </InputLeftElement>
          <Input
            as={Combobox.Input}
            displayValue={(item: AutoCompleteItem<A>) => item.label}
            onChange={(event) => onQueryChange(event.target.value)}
            {...rest}
          />
          {isLoading && (
            <InputRightElement pointerEvents="none">
              <Spinner />
            </InputRightElement>
          )}
        </InputGroup>
        {query !== '' && (
          <Combobox.Options
            as={List}
            position="absolute"
            mt={4}
            maxH="sm"
            w="full"
            overflow="auto"
            rounded="md"
            bg="dark4"
            py={2}
            shadow="lg"
            zIndex={10}
          >
            {items.length === 0 ? (
              <Box py={2} px={6} fontSize="md">
                Nothing found
              </Box>
            ) : (
              items.map((item) => (
                <Combobox.Option key={item.value} value={item}>
                  {({ active }) => (
                    <Box py={2} px={6} fontSize="md" bg={active ? 'primary2' : 'inherit'}>
                      {item.label}
                    </Box>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        )}
      </Box>
    </Combobox>
  );
};
