import {
  InvitationStatusResponse,
  RoleResponse,
  UserListUsersOrderByItem,
} from '../../../api/queries.schemas';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import {
  arrayOfParam,
  numberParam,
  stringParam,
  useQueryParams,
} from '../../../hooks/useQueryParams';
import { PaginationFilter } from '../../../common/pagination';

const DEFAULT_USERS_PAGE_NUMBER = 1;
const DEFAULT_USERS_PAGE_SIZE = 10;
const DEFAULT_USERS_NAME = undefined;
const DEFAULT_USERS_ROLES = undefined;
const DEFAULT_USERS_DEPARTMENT = undefined;
const DEFAULT_USERS_ORDER_BY = UserListUsersOrderByItem['name'];

export interface UsersFilter extends PaginationFilter {
  department: string[] | undefined;
  setDepartment: (department: string[] | undefined) => void;
  orderBy: UserListUsersOrderByItem | undefined;
  setOrderBy: (orderBy: UserListUsersOrderByItem | undefined) => void;
  roles: RoleResponse[] | undefined;
  setRoles: (roles: RoleResponse[] | undefined) => void;
  name: string | undefined;
  setName: (name: string | undefined) => void;
  inviteStatus: InvitationStatusResponse[] | undefined;
  setInviteStatus: (statuses: InvitationStatusResponse[] | undefined) => void;
  clearFilter: () => void;
}

export const useUsersFilter: () => UsersFilter = () => {
  const [params, setParams] = useQueryParams({
    page: numberParam,
    pageSize: numberParam,
    name: stringParam,
    roles: arrayOfParam(stringParam, { delimiter: ',' }),
    department: arrayOfParam(stringParam, { delimiter: ',' }),
    inviteStatus: arrayOfParam(stringParam, { delimiter: ',' }),
    orderBy: stringParam,
  });

  const setCurrentPageNumber = (value: number) =>
    setParams({
      page: value,
    });

  const setPageSize = (value: number) =>
    setParams({
      pageSize: value,
    });

  const setName = useDebouncedCallback(
    (value: string | undefined) =>
      setParams({
        name: value,
        page: 1,
      }),
    0,
  );

  const setDepartment = (value: string[] | undefined) =>
    setParams({
      department: value,
    });

  const setOrderBy = (value: UserListUsersOrderByItem | undefined) =>
    setParams({
      orderBy: value,
    });

  const setRoles = (roles: RoleResponse[] | undefined) =>
    setParams({
      roles,
    });

  const setInviteStatus = (inviteStatus: InvitationStatusResponse[] | undefined) =>
    setParams({
      inviteStatus,
    });

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} users`;

  const clearFilter = () => {
    setParams({
      page: DEFAULT_USERS_PAGE_NUMBER,
      pageSize: DEFAULT_USERS_PAGE_SIZE,
      name: DEFAULT_USERS_NAME,
      department: DEFAULT_USERS_DEPARTMENT,
      roles: DEFAULT_USERS_ROLES,
    });
  };

  return {
    pageSizeOptions: [5, 10, 20, 30],
    pageSize: params.pageSize ?? DEFAULT_USERS_PAGE_SIZE,
    currentPageNumber: params.page ?? DEFAULT_USERS_PAGE_NUMBER,
    roles: (params.roles ?? DEFAULT_USERS_ROLES) as RoleResponse[],
    orderBy: (params.orderBy as UserListUsersOrderByItem) ?? DEFAULT_USERS_ORDER_BY,
    name: params.name,
    inviteStatus: params.inviteStatus as InvitationStatusResponse[],
    department: params.department,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    setName,
    setDepartment,
    setRoles,
    setInviteStatus,
    setOrderBy,
    clearFilter,
  };
};
