import React from 'react';
import {
  Center,
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
} from '@chakra-ui/react';

export const Spin = (props: ChakraSpinnerProps) => {
  return (
    <Center h="full" w="full">
      <ChakraSpinner {...props} />
    </Center>
  );
};
