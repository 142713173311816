import { Box, Checkbox, Divider, HStack, Text } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';
import React from 'react';

const RadioItem = () => {
  return (
    <Box
      boxSize={5}
      bg="transpaent"
      rounded="full"
      position="relative"
      _after={{
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        rounded: 'full',
      }}
      borderWidth="1px"
      borderColor="dark5"
      _groupChecked={{
        bg: 'transparent',
        borderColor: 'primary1',
        _after: {
          bg: 'primary1',
          boxSize: 2,
        },
      }}
    />
  );
};

export interface SelectableItemProps {
  type: 'checkbox' | 'radio';
  label: string;
  description: string;
  extraContent?: () => React.ReactElement;
  icon?: () => React.ReactElement;
  isDisabled?: boolean;
  isChecked: boolean;
  getInputProps: PropGetter<HTMLInputElement, {}>;
  getItemProps: PropGetter<any, {}>;
}

export const SelectableItem = (props: SelectableItemProps) => {
  const {
    type,
    isChecked,
    label,
    description,
    extraContent,
    icon,
    isDisabled,
    getInputProps,
    getItemProps,
    ...rest
  } = props;

  const selectableProps = {
    bg: 'transparent',

    size: 'lg',
    isChecked: isChecked,
    pointerEvents: 'none',
    disabled: isDisabled,
  } as const;

  const input = getInputProps();
  const item = getItemProps();

  return (
    <Box
      userSelect="none"
      w="full"
      as="label"
      border="1px"
      borderColor="dark7"
      rounded="lg"
      color="neutral6"
      px={4}
      py={2}
      cursor="pointer"
      transition="0.2s"
      _hover={{ borderColor: 'neutral3' }}
      _checked={{
        borderColor: 'neutral6',
        color: 'neutral2',
      }}
      _disabled={{ bg: 'gray.50', cursor: 'not-allowed' }}
      role="group"
      {...item}
      {...rest}
    >
      <input {...input} hidden />
      <HStack spacing="4">
        <Box>{type === 'checkbox' ? <Checkbox {...selectableProps} /> : <RadioItem />}</Box>
        {icon && <Box fontSize="lg">{icon()}</Box>}
        <Box>
          <Text color="neutral2" fontSize="md" fontWeight="semibold">
            {label}
          </Text>
          <Box color="neutral6" fontSize="sm">
            {description}
          </Box>
        </Box>
      </HStack>
      {extraContent && (
        <Box>
          <Divider my={2} />
          {extraContent()}
        </Box>
      )}
    </Box>
  );
};
