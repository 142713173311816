import { Avatar, Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { OrderResponse } from '../../../api/queries.schemas';
import { Table } from '../../../components/Table';
import { OrdersNameAutocomplete } from '../../../components/OrdersNameAutoComplete';
import { OrdersSortByMenu } from '../../Orders/components/OrdersSortByMenu';
import { OrdersFilter } from '../../Orders/hooks/useOrdersFilter';
import { usePaginatedOrdersQuery } from '../../Orders/hooks/usePaginatedOrdersQuery';
import { currencyFormat, distanceFormat } from '../../../common/utils';
import { EmptyOrdersState } from '../../Orders/components/EmptyOrdersState';

interface Props {
  ordersFilter: OrdersFilter;
}

const columnHelper = createColumnHelper<OrderResponse>();

const columns: ColumnDef<OrderResponse, any>[] = [
  columnHelper.accessor('user', {
    header: 'User',
    cell: (info) => (
      <HStack spacing={3}>
        <Avatar name={info.getValue().name} size="sm" color="neutral2" />
        <Box>
          <Text fontWeight="semibold">{info.getValue().name}</Text>
        </Box>
      </HStack>
    ),
  }),
  columnHelper.accessor('department', {
    header: 'Department',
    cell: (info) => <Text>{info.getValue().name}</Text>,
  }),
  columnHelper.accessor('name', {
    header: 'Order name',
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
  columnHelper.accessor('area', {
    header: 'Area',
    cell: (info) => <Text>{distanceFormat(info.getValue())}²</Text>,
  }),
  columnHelper.accessor('price', {
    header: 'Cost',
    cell: (info) => <Text>{currencyFormat(info.getValue())}</Text>,
  }),
  columnHelper.accessor('download_count', {
    header: 'Number of downloads',
    cell: (info) => <Text>{info.getValue()}</Text>,
  }),
];

export const OrdersDetails = (props: Props) => {
  const { ordersFilter } = props;
  const { query, pagination } = usePaginatedOrdersQuery(ordersFilter);
  const orders = query.data?.data.items ?? [];

  return (
    <Box w="full" mt={8}>
      <HStack spacing={8}>
        <Text fontSize="2xl" fontWeight="semibold">
          Orders
        </Text>
        <OrdersNameAutocomplete name={ordersFilter.name} setName={ordersFilter.setName} />
        <Spacer />
        <HStack spacing={4}>
          <OrdersSortByMenu orderBy={ordersFilter.orderBy} setOrderBy={ordersFilter.setOrderBy} />
        </HStack>
      </HStack>
      <Table
        mt={4}
        data={orders}
        columns={columns}
        isLoading={query.isLoading}
        error={query.error}
        pagination={pagination}
        empty={<EmptyOrdersState showButton={false} />}
        size="md"
      />
    </Box>
  );
};
