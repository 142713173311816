import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AxiosError } from 'axios';
import inflection from 'inflection';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Queries } from '../../../api';
import { UserDeleteConflictResponse } from '../../../api/queries.schemas';
import { Error } from '../../../components/Error';
import { HookedFormControl } from '../../../components/hooked/HookedFormControl';
import { HookedSubmitButton } from '../../../components/hooked/HookedSubmitButton';
import { HookedUserSelect as HookedReplacementUserSelect } from '../../../components/hooked/HookedUserSelect';
import { Spin } from '../../../components/Spin';
import { useAppContext } from '../../../hooks/useAppContext';

interface FormValues {
  userId: string;
  replaceWith: string;
}

interface ConfirmUserDeleteModalProps {
  id: string;
}

const ReplacementUserError = (props: { data: UserDeleteConflictResponse }) => {
  const attachedOrders = props.data.attached_orders;

  if (attachedOrders === 0) {
    return (
      <Error
        title="Error deleting user"
        description="User has no attached orders. Deletion failed."
      />
    );
  }

  return (
    <Stack spacing={4}>
      <Alert status="info" rounded="md" color="neutral2" bg="neutral5" fontWeight="semibold">
        <AlertIcon />
        This user has created {attachedOrders} {inflection.inflect('orders', attachedOrders)}. A
        replacement user to transfer orders to, must be nominated, in order to delete.
      </Alert>
      <HookedFormControl name="replaceWith" label="Replacement user">
        <HookedReplacementUserSelect name="replaceWith" users={props.data.valid_replacements} />
      </HookedFormControl>
    </Stack>
  );
};

export const ConfirmUserDeleteModal = NiceModal.create<ConfirmUserDeleteModalProps>(({ id }) => {
  const { successToast, errorToast, queryClient } = useAppContext();
  const modal = useModal();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const deleteUserMutation = Queries.useUserDeleteUser({
    mutation: {
      onSettled: () => queryClient.invalidateQueries(Queries.getUserListUsersQueryKey()),
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      userId: id,
      replaceWith: undefined,
    },
    mode: 'all',
  });

  React.useEffect(() => {
    methods.trigger();
  }, [methods]);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      deleteUserMutation
        .mutateAsync({
          userId: values.userId,
          data: {
            replace_with: values.replaceWith,
          },
        })
        .then(() => {
          successToast('User deleted successfully');
          modal.hide();
        })
        .catch((err: AxiosError<UserDeleteConflictResponse>) => {
          if (err.response?.status !== 409) {
            errorToast(`Error deleting user`);
          }
        });
    },
    [modal, deleteUserMutation, successToast, errorToast],
  );

  let content;

  if (deleteUserMutation.isLoading) {
    content = <Spin />;
  } else {
    content = (
      <Stack spacing={4}>
        {deleteUserMutation.error?.response?.data && (
          <ReplacementUserError data={deleteUserMutation.error?.response?.data} />
        )}

        <Text>There is no way to cancel this action.</Text>
      </Stack>
    );
  }

  return (
    <AlertDialog
      isOpen={modal.visible}
      leastDestructiveRef={cancelRef}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
      size="xl"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AlertDialogOverlay>
            <AlertDialogContent as={Flex} p={6}>
              <AlertDialogHeader fontSize="xl" fontWeight="bold">
                Delete user
              </AlertDialogHeader>
              <AlertDialogBody>{content}</AlertDialogBody>
              <AlertDialogFooter>
                <Button variant="secondary" ref={cancelRef} onClick={modal.remove}>
                  Cancel
                </Button>
                <HookedSubmitButton size="md" ml={3} bg="red1" isDisabled={false}>
                  Delete
                </HookedSubmitButton>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </form>
      </FormProvider>
    </AlertDialog>
  );
});
