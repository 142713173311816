import {
  Button,
  ButtonGroup,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { usePaginationControls } from '../hooks/usePagination';
import { PaginationOptions } from '../common/pagination';
import { Select } from './forms/select/Select';
import { SelectButton } from './forms/select/SelectButton';
import { SelectMenu } from './forms/select/SelectMenu';
import { SelectOption } from './forms/select/SelectOption';

interface Props extends FlexProps {
  pagination: PaginationOptions;
}

export const PaginationControls = (props: Props) => {
  const { pagination, ...rest } = props;

  const paginationControls = usePaginationControls({
    total: pagination.totalPages,
    siblings: 1,
    onChange: pagination.gotoPage,
    boundaries: 1,
  });

  return (
    <Flex w="full" {...rest}>
      <HStack w="sm" spacing={4}>
        <Text color="neutral3" fontSize="16px">
          {pagination.showTotal(pagination.total, [
            (pagination.currentPage - 1) * pagination.pageSize + 1,
            Math.min(pagination.currentPage * pagination.pageSize, pagination.total),
          ])}
        </Text>
        <Select
          onChange={(value) => pagination.onPageSizeChange(_.parseInt(value))}
          value={pagination.pageSize.toString()}
        >
          <SelectButton placeholder="Select page size" w="140px" />
          <SelectMenu>
            {pagination.pageSizeOptions.map((option) => (
              <SelectOption
                key={option}
                value={option.toString()}
                label={`${option} per page`}
                raw={option}
              />
            ))}
          </SelectMenu>
        </Select>
      </HStack>
      <Spacer />
      <HStack alignItems="center">
        <ButtonGroup size="md">
          <IconButton
            variant="secondary"
            icon={<Icon as={FaCaretLeft} />}
            aria-label="Previous page"
            isDisabled={!pagination.hasPreviousPage}
            onClick={() => paginationControls.previous()}
          />
          {_.map(paginationControls.range, (page, index) =>
            page === 'dots' ? (
              <Button key={index} variant="secondary">
                ...
              </Button>
            ) : (
              <Button
                key={index}
                variant="secondary"
                isActive={page === pagination.currentPage}
                onClick={() => paginationControls.setPage(page)}
              >
                {page}
              </Button>
            ),
          )}
          <IconButton
            variant="secondary"
            icon={<Icon as={FaCaretRight} />}
            aria-label="Next page"
            isDisabled={!pagination.hasNextPage}
            onClick={() => paginationControls.next()}
          />
        </ButtonGroup>
      </HStack>
    </Flex>
  );
};
