import { StyleFunctionProps, SystemStyleFunction } from '@chakra-ui/react';

const baseStyleDialog: SystemStyleFunction = (props) => {
  return {
    bg: 'dark3',
  };
};

const baseStyle = (props: StyleFunctionProps) => ({
  dialog: baseStyleDialog(props),
});

export const Drawer = {
  baseStyle,
};
