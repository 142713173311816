import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { Feature, Geometry, Point } from 'geojson';
import _ from 'lodash';
import React from 'react';
import { useControl, useMap } from 'react-map-gl';
import { parseFeaturesFromFiles } from '../../../common/loaders';
import { addEditableLayer, getArea, zoomToFeature } from '../../../common/mapbox';
import { SelectAreaPayload } from '../../../types/main';
import { DrawPolygonTool } from '../controls/PolygonControl';

type MapActionsOptions = ConstructorParameters<typeof MapboxDraw>[0] & {
  POIRadius: number;
  AOIMaxRadius: number;
  onSelectArea?: (payload: SelectAreaPayload) => void;
};

export const useMapDrawActions = (options: MapActionsOptions) => {
  const { POIRadius, AOIMaxRadius, onSelectArea } = options;

  const map = useMap().current!.getMap();
  const draw = useControl<MapboxDraw>((ctx) => {
    return new MapboxDraw({
      ...options,
      modes: {
        ...MapboxDraw.modes,
        draw_polygon: DrawPolygonTool,
      },
      displayControlsDefault: false,
    });
  }, _.noop);

  const selectFeatureIds = React.useCallback(
    (featureIds: string[]) => {
      draw.changeMode('simple_select', { featureIds });
    },
    [draw],
  );

  const removePoints = React.useCallback(() => {
    if (map.getLayer('circle-fill')) {
      map.removeLayer('circle-fill');
    }

    if (map.getSource('circle')) {
      map.removeSource('circle');
    }
  }, [map]);

  const addPointRadius = React.useCallback(
    (point: Point) => {
      let circle = turf.circle(point.coordinates, POIRadius, {
        units: 'meters',
      });

      map.addSource('circle', {
        type: 'geojson',
        data: circle,
      });

      map.addLayer({
        id: 'circle-fill',
        type: 'fill',
        source: 'circle',
        paint: {
          'fill-color': 'orange',
          'fill-opacity': 0.3,
        },
      });
    },
    [map, POIRadius],
  );

  const onDrawUpdate = React.useCallback(
    (event: MapboxDraw.DrawCreateEvent): string[] => {
      removePoints();
      const feature = event.features[0];

      if (!feature) {
        throw new Error(`Feature is undefined`);
      }

      const area = getArea(feature, POIRadius);

      const featureIds = draw.set({
        type: 'FeatureCollection',
        features: [feature],
      });

      if (feature.geometry.type === 'Point') {
        addPointRadius(feature.geometry);
        onSelectArea?.({
          geometry: feature.geometry,
          area,
        });
        return featureIds;
      }

      if (feature.geometry.type === 'Polygon') {
        onSelectArea?.({
          geometry: feature.geometry,
          area,
        });

        return featureIds;
      }

      throw new Error(`Unknown geometry type: ${feature.geometry.type}`);
    },
    [draw, POIRadius, onSelectArea, addPointRadius, removePoints],
  );

  const onDrawDelete = React.useCallback(
    (_event: MapboxDraw.DrawDeleteEvent) => {
      removePoints();

      onSelectArea?.({
        geometry: null,
        area: null,
      });
    },
    [onSelectArea, removePoints],
  );

  const addFromGeoJSON = React.useCallback(
    (features: Feature[]) => {
      const featureIds = onDrawUpdate({
        type: 'draw.create',
        features: features,
        target: map,
      });
      selectFeatureIds(featureIds);
      zoomToFeature(map, features[0].geometry);
    },
    [map, selectFeatureIds, onDrawUpdate],
  );

  const addFromFile = React.useCallback(
    async (files: File[]) => {
      const features: Feature[] = await parseFeaturesFromFiles(files, AOIMaxRadius);
      addFromGeoJSON(features);
    },
    [addFromGeoJSON, AOIMaxRadius],
  );

  const addInitialLayer = React.useCallback(
    (layer: Geometry) => {
      const area = addEditableLayer(map, layer, POIRadius);
      draw.add(area);
      zoomToFeature(map, layer);
    },
    [draw, map, POIRadius],
  );

  const init = React.useCallback(() => {
    const features = draw.getAll().features;

    if (features.length > 0) {
      onDrawUpdate({
        features: draw.getAll().features,
        type: 'draw.create',
        target: map,
      });
    }
  }, [draw, map, onDrawUpdate]);

  React.useEffect(() => {
    // TODO: Figure out how to get MapboxDraw types working
    map.on('draw.create' as any, onDrawUpdate);
    map.on('draw.update' as any, onDrawUpdate);
    map.on('draw.delete' as any, onDrawDelete);

    return () => {
      // TODO: Figure out how to get MapboxDraw types working
      map.off('draw.create' as any, onDrawUpdate);
      map.off('draw.update' as any, onDrawUpdate);
      map.off('draw.delete' as any, onDrawDelete);
    };
  }, [map, onDrawUpdate, onDrawDelete]);

  return React.useMemo(
    () => ({
      map,
      draw,
      onDrawUpdate,
      onDrawDelete,
      removePoints,
      addPointRadius,
      addFromGeoJSON,
      addInitialLayer,
      selectFeatureIds,
      addFromFile,
      init,
    }),
    [
      map,
      draw,
      onDrawUpdate,
      onDrawDelete,
      removePoints,
      addPointRadius,
      addFromGeoJSON,
      addInitialLayer,
      selectFeatureIds,
      addFromFile,
      init,
    ],
  );
};

export type MapDrawActions = ReturnType<typeof useMapDrawActions>;
