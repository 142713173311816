import { Box, Button, FormControl, FormLabel, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { DepartmentFilter } from '../../../components/DepartmentFilter';
import { DateRange, RangeDatePicker } from '../../../components/forms/RangeDatePicker';
import { OrderStatusFilter } from '../../../components/OrderStatusFilter';
import { SlideDrawer } from '../../../components/SlideDrawer';
import { OrdersFilter } from '../hooks/useOrdersFilter';
import { OrdersUsersFilter } from './OrdersUsersFilter';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  filter: OrdersFilter;
}

export const OrdersFilterDrawer = (props: Props) => {
  const { isOpen, onClose, filter } = props;

  const onDateChange = (value: DateRange) => {
    const [start, end] = value;
    filter.setStart(start ?? undefined);
    filter.setEnd(end ?? undefined);
  };

  const dateValue: DateRange = [filter.start ?? null, filter.end ?? null];

  return (
    <Box flexBasis="content" flexShrink={0} overflowY="auto" overflowX="hidden">
      <SlideDrawer isOpen={isOpen} onClose={onClose}>
        <Stack spacing={8}>
          <HStack>
            <Text fontWeight="semibold" fontSize="18px">
              Filters
            </Text>
            <Spacer />
            <Button variant="link" onClick={() => filter.clearFilters()}>
              Clear all
            </Button>
          </HStack>
          <FormControl>
            <FormLabel fontWeight="semibold">Date</FormLabel>
            <RangeDatePicker value={dateValue} onChange={onDateChange} />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="semibold">Status</FormLabel>
            <OrderStatusFilter value={filter.status} onChange={filter.setStatus} />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="semibold">Department</FormLabel>
            <DepartmentFilter value={filter.department} onChange={filter.setDepartment} />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="semibold">Users</FormLabel>
            <OrdersUsersFilter value={filter.user} onChange={filter.setUser} />
          </FormControl>
        </Stack>
      </SlideDrawer>
    </Box>
  );
};
