import { Tag } from '@chakra-ui/react';
import { InvitationStatusResponse } from '../api/queries.schemas';

interface Props {
  status: InvitationStatusResponse;
}

export const InvitationStatusTag = (props: Props) => {
  switch (props.status) {
    case InvitationStatusResponse.accepted:
      return (
        <Tag variant="solid" bg="rgba(38, 156, 128, 0.2)" color="#269C80">
          Accepted
        </Tag>
      );
    case InvitationStatusResponse.pending:
      return (
        <Tag variant="solid" bg="rgba(222, 172, 43, 0.2)" color="#DEAC2B">
          Pending
        </Tag>
      );
    case InvitationStatusResponse.expired:
      return (
        <Tag variant="solid" bg="rgba(235,65,75,0.2)" color="#E8414B">
          Expired
        </Tag>
      );
    case InvitationStatusResponse.na:
      return (
        <Tag variant="solid" bg="rgba(180, 188, 196, 0.2)" color="#B4BCC4">
          N/A
        </Tag>
      );
  }
};
