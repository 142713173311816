const variants = {
  filled: {
    field: {
      bg: 'dark4',
      rounded: 'md',
      color: 'neutral2',
      _hover: {
        bg: 'dark1',
      },
      _placeholder: {
        color: 'neutral4',
      },
    },
    addon: {
      borderRadius: 'lg',
      bg: 'dark4',
    },
  },
  outline: {
    field: {
      color: 'neutral2',
      borderColor: 'dark4',
    },
  },
};

export const Input = {
  variants,
};
