import _ from 'lodash';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export type ParamsSpec<T> = {
  [K in keyof T]: ParamDecoder<T[K]>;
};

export type Params<T> = { [K in keyof T]: T[K] | undefined };

export type UseQueryParamsReturn<T> = [Params<T>, (newParams: Partial<Params<T>>) => void];

export interface ParamDecoder<T> {
  decode: (value: string) => T | undefined;
  encode: (value: T) => string | undefined;
}

export const useQueryParams = <T,>(paramsSpec: ParamsSpec<T>): UseQueryParamsReturn<T> => {
  const [search, setSearch] = useSearchParams();

  const decodedParams = React.useMemo(() => {
    const searchAsObject = Object.fromEntries(new URLSearchParams(search));
    const decodedParams: { [K in keyof T]: T[K] } = Object.apply({});

    for (const key in paramsSpec) {
      const value = searchAsObject[key];
      const decoded = value === undefined ? undefined : paramsSpec[key].decode(value);
      if (decoded !== undefined) {
        decodedParams[key] = decoded;
      }
    }

    return decodedParams;
  }, [search, paramsSpec]);

  const setParams = React.useCallback(
    (newParams: Partial<Params<T>>) => {
      for (const key in newParams) {
        const newValue = newParams[key];
        const encoded =
          newValue === undefined ? undefined : paramsSpec[key].encode(newValue as any);
        encoded ? search.set(key, encoded) : search.delete(key);
      }
      setSearch(search);
    },
    [search, setSearch, paramsSpec],
  );

  return [decodedParams, setParams];
};

export const withDefault = <T,>(defaultValue: T, decoder: ParamDecoder<T>) => {
  return {
    decode: (value: string) => (value === undefined ? defaultValue : decoder.decode(value)),
  };
};

export const stringParam: ParamDecoder<string> = {
  decode: _.identity,
  encode: _.identity,
};

export const booleanParam: ParamDecoder<boolean> = {
  decode: (value: string) => {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    }

    return undefined;
  },
  encode: (value: boolean) => (value ? 'true' : 'false'),
};

export const numberParam: ParamDecoder<number> = {
  decode: (value: string) => {
    if (!isNumber(value)) {
      return undefined;
    }

    return _.toNumber(value);
  },
  encode: (value: number) => value.toString(),
};

export const dateParam: ParamDecoder<Date> = {
  decode: (value: string) => new Date(value),
  encode: (value: Date) => value.toISOString(),
};

export const arrayOfParam = <T,>(
  of: ParamDecoder<T>,
  options?: {
    delimiter?: string;
  },
): ParamDecoder<T[]> => {
  const delimiter = _.defaultTo(options?.delimiter, '_');

  return {
    decode: (value: string) => _.compact(value.split(delimiter).map(of.decode)),
    encode: (value: T[]) => {
      if (value.length === 0) {
        return undefined;
      }

      if (value.length === 1) {
        return of.encode(value[0]);
      }

      return value.map(of.encode).join(delimiter);
    },
  };
};
