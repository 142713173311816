import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import inflection from 'inflection';
import { Queries } from '../../../api';
import { UserDashboardResponse } from '../../../api/queries.schemas';
import { currencyFormat } from '../../../common/utils';
import { Card } from '../../../components/Card';
import { DateRange } from '../../../components/forms/RangeDatePicker';
import { Table } from '../../../components/Table';
import { EmptyUsersState } from '../../Users/components/EmptyUsersState';

const columnHelper = createColumnHelper<UserDashboardResponse>();

const columns: ColumnDef<UserDashboardResponse, any>[] = [
  columnHelper.accessor('name', {
    header: 'User name',
    cell: (info) => (
      <HStack spacing={3}>
        <Avatar name={info.getValue()} size="md" color="neutral2" />
        <Box>
          <Text fontWeight="semibold">{info.getValue()}</Text>
          <Text>{info.row.original.email}</Text>
        </Box>
      </HStack>
    ),
  }),
  columnHelper.accessor('total_orders', {
    header: 'Order count',
    cell: (info) => (
      <Text>
        {info.getValue()} {inflection.inflect('orders', info.getValue())}
      </Text>
    ),
  }),
  columnHelper.accessor('total_price', {
    header: 'Total spend',
    cell: (info) => <Text>{currencyFormat(info.getValue())}</Text>,
  }),
];

interface Props {
  dateRange: DateRange;
}

export const UsersCard = (props: Props) => {
  const { dateRange } = props;
  const [start, end] = dateRange;

  const { data, error, isLoading } = Queries.useUserListTopUsersForDashboard({
    order_by: ['-total_price'],
    limit: 5,
    start: start ?? undefined,
    end: end ?? undefined,
  });

  const userDashboardResponse = data?.data.items ?? [];

  return (
    <Card
      w="full"
      p={0}
      leftContent={
        <HStack px={8} py={4}>
          <Text fontSize="2xl" fontWeight="semibold">
            Active users
          </Text>
        </HStack>
      }
    >
      <Box h="md" w="full">
        <Table
          columns={columns}
          data={userDashboardResponse}
          isLoading={isLoading}
          error={error}
          empty={<EmptyUsersState showButton={false} />}
          size="md"
          showHeader={false}
          bordered={false}
        />
      </Box>
    </Card>
  );
};
