import {
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Tr,
  VStack,
} from '@chakra-ui/react';
import ImageryProductSpecifications from '../../../assets/ImageryProductSpecifications.pdf';
import React from 'react';

interface ImagerySpec {
  name: string;
  value: string;
}

export const SatelliteImagery = () => {
  const specs: ImagerySpec[] = [
    { name: 'Resolution', value: '0.7 m' },
    {
      name: 'Bands',
      value:
        'Blue (450 - 510nm)<br />Green (510 - 580nm)<br />Red (590 - 690nm)<br />Near-IR (750 - 900nm)',
    },
    { name: 'Frequency', value: '3+ passes per day' },
    { name: 'Minimum order size', value: '50 km²' },
    { name: 'Maximum polygon diameter', value: '25 km' },
    { name: 'Point order size', value: '15.21 km²' },
    { name: 'Satellite provider', value: 'Satellogic' },
  ];
  return (
    <VStack spacing={10}>
      <Heading textAlign={'center'}>Best in class satellite imagery</Heading>
      <TableContainer>
        <Table size={{ base: 'sm', md: 'lg' }}>
          <Tbody>
            {specs.map((spec, id) => (
              <Tr>
                <Th color="white">{spec.name}</Th>
                <Td dangerouslySetInnerHTML={{ __html: spec.value }}></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Button
        as="a"
        href={ImageryProductSpecifications}
        target="_blank"
        bg="whiteAlpha.300"
        rounded="full"
        color="white"
        _hover={{ bg: 'whiteAlpha.500' }}
      >
        Full Product Specification
      </Button>
    </VStack>
  );
};
