import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { FaDownload, FaRegQuestionCircle } from 'react-icons/fa';
import { ProductDetailResponse } from '../../../api/queries.schemas';
import { RadioStack } from '../../../components/forms/RadioStack';
import { useAppContext } from '../../../hooks/useAppContext';
import { ProductDetailsList } from './ProductDetailsList';

interface ChooseProductModalProps {
  products: ProductDetailResponse[];
  onSelect: (value: string) => void;
}

export const ChooseProductModal = NiceModal.create<ChooseProductModalProps>(
  ({ products, onSelect }) => {
    const modal = useModal();
    const { tokenManager } = useAppContext();

    const [value, setValue] = React.useState(products[0]);

    const options = _.map(products, (p) => ({
      value: p.id,
      label: p.name,
      description: '',
    }));

    const onChange = React.useCallback(
      (value: StringOrNumber) => {
        const found = _.find(products, (p) => p.id === value);
        if (found) {
          setValue(found);
        }
      },
      [setValue, products],
    );

    const onSave = React.useCallback(() => {
      onSelect(value.id);
      modal.hide();
    }, [onSelect, value, modal]);

    const onDownloadSpecSheet = React.useCallback(() => {
      tokenManager.refreshToken().then(() => {
        const token = tokenManager.getAccessToken();
        window.open(
          `${axios.defaults.baseURL}/api/products/${value.id}/spec_sheet?api_key=${token}`,
          '_blank',
        );
      });
    }, [value, tokenManager]);

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
        size="4xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader py={4}>
            Tasking type
            <Link href="/tasking_types.png" target="_blank" pl={2}>
              <Icon as={FaRegQuestionCircle} color="neutral4" />
            </Link>
          </ModalHeader>
          <ModalCloseButton py={8} />
          <ModalBody>
            <Flex flexDir="row" h="300px">
              <Box flexShrink={0}>
                <RadioStack options={options} value={value.id} onChange={onChange} />
              </Box>
              <Box ml={8} pl={8} flexGrow={0} borderLeftWidth="1px" borderLeftColor="dark5">
                <Stack spacing={4} align="start">
                  <ProductDetailsList product={value} />
                  <Button
                    variant="link"
                    leftIcon={<Icon as={FaDownload} />}
                    onClick={onDownloadSpecSheet}
                  >
                    Download spec sheet
                  </Button>
                </Stack>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={modal.hide}>
              Cancel
            </Button>
            <Button onClick={onSave}>Select product</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
