import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { CaptureResponse } from '../../../api/queries.schemas';
import { intersperse } from '../../../common/utils';
import { EmptyCaptureAttemptsState } from '../../Dashboard/components/EmptyCaptureAttemptsState';
import { OrderCaptureAttempt } from './OrderCaptureAttempt';

interface Props {
  captures: CaptureResponse[];
  selectedCaptureId: string | undefined;
  setSelectedCaptureId: (captureId: string | undefined) => void;
}

export const OrderCapturesList = (props: Props) => {
  const { captures, selectedCaptureId, setSelectedCaptureId } = props;

  const completedCaptures = _.filter(captures, (capture) => capture.status === 'completed');
  const attemptedCaptures = _.filter(captures, (capture) => capture.status !== 'completed');

  if (_.isEmpty(captures)) {
    return (
      <Box p={4} bg="dark4" rounded="md">
        <EmptyCaptureAttemptsState p={0} />
      </Box>
    );
  }

  return (
    <Stack spacing={4}>
      {!_.isEmpty(completedCaptures) && (
        <>
          <Text fontSize="xl" fontWeight="bold">
            Completed captures
          </Text>
          <Stack spacing={4}>
            {intersperse(
              completedCaptures.map((capture, index) => (
                <OrderCaptureAttempt
                  key={`capture-${capture.id}-${index}`}
                  capture={capture}
                  isSelected={selectedCaptureId === capture.id}
                  onClick={() => setSelectedCaptureId(capture.id)}
                />
              )),
              (index) => (
                <Divider key={`divider-${index}`} />
              ),
            )}
          </Stack>
        </>
      )}

      {!_.isEmpty(attemptedCaptures) && (
        <>
          <Text fontSize="xl" fontWeight="bold">
            Capture attempts
          </Text>
          <Stack spacing={4}>
            {intersperse(
              attemptedCaptures.map((capture, index) => (
                <OrderCaptureAttempt
                  key={`capture-${capture.id}-${index}`}
                  capture={capture}
                  isSelected={selectedCaptureId === capture.id}
                  onClick={() => setSelectedCaptureId(capture.id)}
                  borderColor="dark3"
                />
              )),
              (index) => (
                <Divider key={`divider-${index}`} />
              ),
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
