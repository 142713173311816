import { Button, Flex, Heading, HStack, Icon, Spacer, useDisclosure } from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';
import { Content } from '../../components/Content';
import { OrdersNameAutocomplete } from '../../components/OrdersNameAutoComplete';
import { EmptyOrdersState } from './components/EmptyOrdersState';
import { OrdersFilterDrawer } from './components/OrdersFilterDrawer';
import { OrdersSortByMenu } from './components/OrdersSortByMenu';
import { OrdersTable } from './components/OrdersTable';
import { useOrdersFilter } from './hooks/useOrdersFilter';
import { usePaginatedOrdersQuery } from './hooks/usePaginatedOrdersQuery';

export const Orders = () => {
  const ordersFilter = useOrdersFilter();
  const { query, pagination } = usePaginatedOrdersQuery(ordersFilter);
  const orders = query.data?.data.items ?? [];
  const ordersFilterDrawer = useDisclosure();

  return (
    <Flex flexDir="row" minH="full">
      <Content w="full" minH="full">
        <HStack spacing={8}>
          <Heading>Orders</Heading>
          <OrdersNameAutocomplete name={ordersFilter.name} setName={ordersFilter.setName} />
          <Spacer />
          <HStack spacing={4}>
            <OrdersSortByMenu orderBy={ordersFilter.orderBy} setOrderBy={ordersFilter.setOrderBy} />
            <Button
              variant="secondary"
              leftIcon={<Icon as={FaFilter} />}
              {...ordersFilterDrawer.getButtonProps()}
            >
              Filters
            </Button>
          </HStack>
        </HStack>

        <OrdersTable
          orders={orders}
          isLoading={query.isLoading}
          error={query.error}
          pagination={pagination}
          empty={<EmptyOrdersState />}
          mt={8}
        />
      </Content>
      <OrdersFilterDrawer
        filter={ordersFilter}
        isOpen={ordersFilterDrawer.isOpen}
        onClose={ordersFilterDrawer.onClose}
      />
    </Flex>
  );
};
