export interface PaginationFilter {
  currentPageNumber: number;
  pageSize: number;
  pageSizeOptions: number[];
  showTotal: (total: number, range: [number, number]) => string;
  setPageSize: (n: number) => void;
  setCurrentPageNumber: (n: number) => void;
}

export interface PaginationOptions {
  total: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  pageSizeOptions: number[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  showTotal: (total: number, range: [number, number]) => string;
  onPageSizeChange: (pageSize: number) => void;
}

export const makePaginationOptions = (options: {
  total: number;
  pageSize: number;
  currentPageNumber: number;
  pageSizeOptions: number[];
  setCurrentPageNumber: (n: number) => void;
  setPageSize: (n: number) => void;
  resource: string;
}): PaginationOptions => {
  return {
    total: options.total,
    totalPages: Math.ceil(options.total / options.pageSize),
    currentPage: options.currentPageNumber,
    pageSize: options.pageSize,
    pageSizeOptions: options.pageSizeOptions,
    hasNextPage: options.total > options.pageSize * options.currentPageNumber,
    hasPreviousPage: options.currentPageNumber > 1,
    gotoPage: options.setCurrentPageNumber,
    nextPage: () => options.setCurrentPageNumber(options.currentPageNumber + 1),
    previousPage: () => options.setCurrentPageNumber(options.currentPageNumber - 1),
    showTotal: (total: number, range: [number, number]) =>
      `Showing ${range[0]}-${range[1]} from ${total} ${options.resource}`,
    onPageSizeChange: options.setPageSize,
  };
};
