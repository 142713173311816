import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AxiosError } from 'axios';
import inflection from 'inflection';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Queries } from '../../../api';
import { DepartmentDeleteConflictResponse, DepartmentResponse } from '../../../api/queries.schemas';
import { HookedReplacementDepartmentSelect } from '../../../components/hooked/HookedDepartmentSelect';
import { HookedFormControl } from '../../../components/hooked/HookedFormControl';
import { HookedSubmitButton } from '../../../components/hooked/HookedSubmitButton';
import { Spin } from '../../../components/Spin';
import { useAppContext } from '../../../hooks/useAppContext';

const ReplacementDepartmentError = (props: { data: DepartmentDeleteConflictResponse }) => {
  const attachedUsers = props.data.attached_users;

  return (
    <Stack spacing={4}>
      <Alert status="info" rounded="md" color="neutral2" bg="neutral5" fontWeight="semibold">
        <AlertIcon />
        This department has {attachedUsers} {inflection.inflect('users', attachedUsers)}. A
        replacement department to transfer users to, must be nominated, in order to delete.
      </Alert>
      <HookedFormControl name="replaceWith" label="Replacement department">
        <HookedReplacementDepartmentSelect
          name="replaceWith"
          departments={props.data.valid_replacements}
        />
      </HookedFormControl>
    </Stack>
  );
};

interface Props {
  department: DepartmentResponse;
}

interface FormValues {
  departmentId: string;
  replaceWith: string;
}

export const DepartmentDeleteModal = NiceModal.create<Props>((props) => {
  const { department } = props;
  const modal = useModal();
  const { successToast, errorToast, queryClient } = useAppContext();

  const deleteDepartmentMutation = Queries.useDepartmentDeleteDepartment({
    mutation: {
      onSettled: () =>
        queryClient.invalidateQueries(Queries.getDepartmentListDepartmentsQueryKey()),
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      departmentId: department.id,
      replaceWith: undefined,
    },
    mode: 'all',
  });

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      return deleteDepartmentMutation
        .mutateAsync({
          departmentId: department.id,
          params: {
            replace_with: values.replaceWith,
          },
        })
        .then(() => {
          successToast(`${department.name} deleted successfully`);
          modal.hide();
        })
        .catch((err: AxiosError<DepartmentDeleteConflictResponse>) => {
          if (err.response?.status !== 409) {
            errorToast(`Error deleting ${department.name}`);
          }
        });
    },
    [successToast, errorToast, modal, deleteDepartmentMutation, department],
  );

  let content;

  if (deleteDepartmentMutation.isLoading) {
    content = <Spin />;
  } else {
    content = (
      <Stack spacing={4}>
        {deleteDepartmentMutation.error?.response?.data && (
          <ReplacementDepartmentError data={deleteDepartmentMutation.error?.response?.data} />
        )}
        <Text>There is no way to cancel this action.</Text>
      </Stack>
    );
  }

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="lg">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <ModalHeader fontSize="2xl" py={4}>
              Delete department
            </ModalHeader>
            <ModalCloseButton py={8} />
            <ModalBody>{content}</ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={modal.hide}>
                Cancel
              </Button>
              <HookedSubmitButton bg="red1" isDisabled={false}>
                Delete
              </HookedSubmitButton>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
