import { Box, Flex, HStack, Image, Link as ChakraLink, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Queries, Schema } from '../../api';
import BackgroundImage from '../../assets/login-background-2.webp';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import WhiteLogo from '../../assets/white-logo.webp';
import { ROUTES } from '../../common/routes';
import { Error } from '../../components/Error';
import { HookedCheckbox } from '../../components/hooked/HookedCheckbox';
import { HookedFormControl } from '../../components/hooked/HookedFormControl';
import { HookedInput } from '../../components/hooked/HookedInput';
import { HookedSubmitButton } from '../../components/hooked/HookedSubmitButton';
import { Spin } from '../../components/Spin';
import { useTokenManager } from '../../context/TokenManagerContext';
import { useAppContext } from '../../hooks/useAppContext';
import { useRequiredParams } from '../../hooks/useRequiredParams';

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required(),
  passwordConfirmation: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    },
  ),
  eulaAgree: Yup.boolean().oneOf([true], 'You must agree to the EULA'),
});

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
  eulaAgree: boolean;
}

export const Invite = () => {
  const { id } = useRequiredParams<{ id: string }>();

  const tokenManager = useTokenManager();
  const { errorToast, successToast } = useAppContext();

  const navigate = useNavigate();
  const { data, error, isLoading } = Queries.useInvitationGetInvitation(id);
  const acceptInviteMutation = Queries.useInvitationAcceptInvitation();

  const methods = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
      eulaAgree: false,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { reset, setError } = methods;

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      tokenManager.removeTokens();
      acceptInviteMutation
        .mutateAsync({
          invitationId: id,
          data: {
            password: values.password,
          },
        })
        .then((res) => {
          successToast('Invitation accepted successfully');
          navigate(ROUTES.HOME);
        })
        .catch((err: AxiosError<Schema.ResetPasswordErrorResponse | void>) => {
          if (err.response?.data?.errors && err.response?.data?.field) {
            setError(
              'password',
              {
                type: 'server',
                message: err.response?.data?.errors!.join(' '),
              },
              {
                shouldFocus: true,
              },
            );
          }

          errorToast(`Error accepting invitation`);
        });
    },
    [id, acceptInviteMutation, tokenManager, errorToast, successToast, navigate, setError],
  );

  const user = data?.data;

  React.useEffect(() => {
    if (user) {
      reset({
        email: user.email,
      });
    }
  }, [reset, user]);

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error title="Unable to fetch invitation information" />;
  }

  if (!user) {
    return <Error title="We could not find a matching invitation" />;
  }

  return (
    <Flex
      flexDir="column"
      h="full"
      bgImage={`url(${BackgroundImage})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      align="center"
      justify="center"
    >
      <Image pos="absolute" w="120px" top="24px" left="80px" src={WhiteLogo} />
      <Stack align="center" w="md" p="40px" rounded="md" spacing={4} bg="white" color="black">
        <Logo height={50} width={200} />
        <Text fontWeight="semibold">You have been invited to be a member of our team</Text>
        <Box w="full">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack w="full" spacing={4}>
                <HookedFormControl name="email" label="Email">
                  <HookedInput
                    disabled
                    name="email"
                    placeholder="Enter email"
                    color="black"
                    size="lg"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <HookedFormControl name="password" label="Create password">
                  <HookedInput
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    color="black"
                    size="lg"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <HookedFormControl name="passwordConfirmation" label="Confirm password">
                  <HookedInput
                    name="passwordConfirmation"
                    type="password"
                    placeholder="Confirm password"
                    color="black"
                    size="lg"
                    _placeholder={{
                      color: 'gray.400',
                    }}
                  />
                </HookedFormControl>
                <HStack align="start">
                  <HookedFormControl
                    name="eulaAgree"
                    label={
                      <>
                        I agree to the{' '}
                        <ChakraLink target="_blank" href="/eula.pdf" textDecoration="underline">
                          EULA
                        </ChakraLink>
                      </>
                    }
                    w="auto"
                  />
                  <Box py={1}>
                    <HookedCheckbox name="eulaAgree" />
                  </Box>
                </HStack>

                <HookedSubmitButton size="lg">Login</HookedSubmitButton>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </Flex>
  );
};
