import { StackProps, useRadio, useRadioGroup, UseRadioProps, VStack } from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import React from 'react';
import { SelectableItem } from './SelectableItem';

interface RadioItemProps extends UseRadioProps {
  label: string;
  description: string;
  extraContent?: () => React.ReactElement;
  icon?: () => React.ReactElement;
}

const RadioItem = (props: RadioItemProps) => {
  const { getInputProps, getCheckboxProps, state } = useRadio(props);

  return (
    <SelectableItem
      type="radio"
      label={props.label}
      description={props.description}
      extraContent={props.extraContent}
      icon={props.icon}
      getInputProps={getInputProps}
      getItemProps={getCheckboxProps}
      isChecked={state.isChecked}
      isDisabled={state.isDisabled}
    />
  );
};

export interface RadioStackProps extends Omit<StackProps, 'onChange'> {
  options: {
    value: string;
    label: string;
    description: string;
    extraContent?: () => React.ReactElement;
    icon?: () => React.ReactElement;
    isDisabled?: boolean;
  }[];
  value: StringOrNumber;
  onChange: (value: StringOrNumber) => void;
}

export const RadioStack = (props: RadioStackProps) => {
  const { options, value, onChange, ...rest } = props;

  const { getRadioProps } = useRadioGroup({
    value,
    onChange,
  });

  return (
    <VStack alignItems="flex-start" spacing={3} {...rest}>
      {options.map((option) => (
        <RadioItem key={option.value} {...option} {...getRadioProps({ ...option })} />
      ))}
    </VStack>
  );
};
