import { Box, Icon, IconButton, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FaFileUpload } from 'react-icons/fa';
import { makeErrorMessage } from '../../../common/utils';
import { useAppContext } from '../../../hooks/useAppContext';
import { MapDrawActions } from '../hooks/useMapDrawActions';
import { ControlTooltip } from './ControlTooltip';

interface Props {
  actions: MapDrawActions;
}

export const ImportFileControl = (props: Props) => {
  const { actions } = props;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { errorToast } = useAppContext();

  const onClick = () => {
    inputRef.current?.click();
  };

  const reset = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [inputRef]);

  const onChange = React.useCallback(
    (files: FileList | null) => {
      if (!files || files.length === 0) {
        return;
      }

      actions.addFromFile(Array.from(files ?? [])).catch((err) => {
        errorToast(`There was an error importing file ${makeErrorMessage(err)}`);
      });

      reset();
    },
    [actions, reset, errorToast],
  );

  return (
    <ControlTooltip
      title="Import features from file"
      content={
        <Box>
          <Text>We support import features from the following file types:</Text>
          <Stack ml={2} my={1}>
            <Text>GeoJSON (*.json)</Text>
            <Text>KML (*.kml)</Text>
            <Text>KMZ (*.kmz)</Text>
            <Text>Shapefile (*.shp, *.zip)</Text>
          </Stack>
          <Text>
            Files are expected to contain at maximum, 1 feature. For multipolygon features, we will
            take the first polygon.
          </Text>
        </Box>
      }
    >
      <Box>
        <input
          type="file"
          multiple={true}
          hidden
          ref={inputRef}
          onChange={(e) => onChange(e.target.files)}
        />
        <IconButton
          aria-label="upload"
          variant="secondary"
          rounded="md"
          icon={<Icon as={FaFileUpload} />}
          size="lg"
          onClick={onClick}
        />
      </Box>
    </ControlTooltip>
  );
};
