import { _BrowserFileSystem as BrowserFileSystem, load, loadInBatches } from '@loaders.gl/core';
import { JSONLoader } from '@loaders.gl/json';
import { KMLLoader } from '@loaders.gl/kml';
import { LoaderContext, LoaderOptions, LoaderWithParser } from '@loaders.gl/loader-utils';
import { ShapefileLoader } from '@loaders.gl/shapefile';
import { ZipLoader } from '@loaders.gl/zip';
import { Feature, FeatureCollection } from 'geojson';
import _ from 'lodash';
import { polygonKinksValid, polygonLatLngValid } from './mapbox';

export const KMZLoader: LoaderWithParser = {
  id: 'kmz',
  module: 'kmz',
  name: 'KMZ Archive',
  version: '1.0.0',
  extensions: ['kmz'],
  mimeTypes: ['application/zip'],
  category: 'archive',
  tests: ['PK'],
  options: {},
  parse: async (
    arrayBuffer: ArrayBuffer,
    _options?: LoaderOptions,
    context?: LoaderContext,
  ): Promise<Feature[]> => {
    if (!context) {
      throw new Error('LoaderContext is required');
    }

    const { parse } = context;

    const filemap: Record<string, ArrayBuffer> = await parse(arrayBuffer, ZipLoader, context);

    const featureCollections: FeatureCollection[] = await Promise.all(
      _.map(filemap, (buffer, name) => {
        return parse(buffer, KMLLoader, context);
      }),
    );

    return _.flatMap(featureCollections, (fc) => fc.features);
  },
};

export const getExtension = (url: string): string => {
  const extIndex = url && url.lastIndexOf('.');
  if (typeof extIndex === 'number') {
    return extIndex >= 0 ? url.substr(extIndex + 1) : '';
  }
  return extIndex;
};

export const parseFeaturesFromFiles = async (
  files: File[],
  AOIMaxRadius: number,
): Promise<Feature[]> => {
  const filesToParse: File[] = [];

  if (files.length === 0) {
    return [];
  } else if (files.length > 1) {
    filesToParse.push(...files);
  } else if (files.length === 1) {
    const file = _.head(files) as File;
    const extension = getExtension(file.name);

    if (extension === 'zip') {
      const zipContents = await load(file, [ZipLoader]);
      filesToParse.push(
        ..._.map(zipContents, (ab, key) => {
          const blob = new Blob([ab]);
          (blob as any).name = key;
          return blob as File;
        }),
      );
    }
  }

  const fileSystem = new BrowserFileSystem(filesToParse);
  const { fetch } = fileSystem;

  const shpFile = _.find(filesToParse, (file) => file.name.endsWith('.shp'));
  const batches = await loadInBatches(
    shpFile ?? files[0],
    [JSONLoader, KMLLoader, KMZLoader, ShapefileLoader],
    {
      fetch,
      // Reproject shapefiles to WGS84
      gis: { reproject: true, _targetCrs: 'EPSG:4326' },
    },
  );

  const features: Feature[] = [];
  for await (const batch of batches) {
    if (batch.data.type === 'FeatureCollection') {
      features.push(...batch.data.features);
    } else {
      for (const feature of batch.data) {
        if (feature.geometry.type === 'Polygon') {
          features.push(feature);
        }

        // Take first Polygon of MultiPolygon
        if (feature.geometry.type === 'MultiPolygon') {
          features.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: feature.geometry.coordinates[0],
            },
            properties: feature.properties,
          });
        }
      }
    }
  }

  if (features.length > 1) {
    throw new Error(
      `Files with multiple features are not supported. Please upload a file containing a single feature`,
    );
  }

  // Check basic validity of the feature
  for (const feature of features) {
    if (feature.geometry.type === 'Polygon') {
      polygonLatLngValid(feature.geometry) && polygonKinksValid(feature.geometry);
    }
  }

  return features;
};
