import { Geometry } from 'geojson';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import MapGL, { MapRef } from 'react-map-gl';
import {
  drawPreviewLayer,
  drawPreviewTargetLayer,
  initialViewState,
  mapStyles,
  removeOldLayers,
} from '../../common/mapbox';
import { SelectAreaPayload } from '../../types/main';
import { DrawControls } from './controls/DrawControls';
import { GeoCoderControl } from './controls/GeoCoderControl';

import { Box } from '@chakra-ui/react';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { StylesControls } from './controls/StylesControls';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface Props {
  mapboxAccessToken: string;
  isEdit?: boolean;
  previewLayer?: Geometry;
  previewTargetLayer?: Geometry;
  POIRadius: number;
  AOIMaxRadius: number;
  layer?: Geometry;
  onSelectArea?: (payload: SelectAreaPayload) => void;
}

export const Mapbox = (props: Props) => {
  const {
    mapboxAccessToken,
    isEdit,
    previewLayer,
    previewTargetLayer,
    layer,
    POIRadius,
    AOIMaxRadius,
    onSelectArea,
  } = props;

  const mapRef = React.useRef<MapRef>(null);

  const [isMapLoaded, setIsMapLoaded] = React.useState<boolean>(false);
  const [isStylesLoaded, setIsStylesLoaded] = React.useState<boolean>(false);
  const [mapStyle, setMapStyle] = React.useState<keyof typeof mapStyles>('streets' as const);
  const isLoaded = isMapLoaded && isStylesLoaded;

  const onSelectAreaPrime = React.useCallback(
    (payload: SelectAreaPayload) => {
      onSelectArea?.(payload);
    },
    [onSelectArea],
  );

  const onChangeStyle = React.useCallback(
    (style: keyof typeof mapStyles) => {
      if (mapStyle === style || !mapRef.current) {
        return;
      }

      setIsStylesLoaded(false);
      setMapStyle(style);
    },
    [mapStyle],
  );

  React.useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const map = mapRef.current?.getMap()!;
    removeOldLayers(map);

    if (!isEdit) {
      if (previewLayer) {
        drawPreviewLayer(map, previewLayer, POIRadius);
      }

      if (previewTargetLayer) {
        drawPreviewTargetLayer(map, previewTargetLayer, POIRadius);
      }
    }
  }, [isLoaded, isEdit, previewLayer, previewTargetLayer, POIRadius]);

  return (
    <MapGL
      ref={mapRef}
      onLoad={() => setIsMapLoaded(true)}
      onStyleData={() => setIsStylesLoaded(true)}
      initialViewState={initialViewState}
      style={{ width: '100%', height: '100%', backgroundColor: '#131415' }}
      mapStyle={mapStyles[mapStyle]}
      mapboxAccessToken={mapboxAccessToken}
      styleDiffing={false}
    >
      {isMapLoaded && (
        <GeoCoderControl
          position="top-left"
          accessToken={mapboxAccessToken}
          mapboxgl={mapRef.current?.getMap()}
        />
      )}
      {isEdit && (
        <Box position="absolute" top={0} right={0} p={8}>
          <DrawControls
            POIRadius={POIRadius}
            AOIMaxRadius={AOIMaxRadius}
            onSelectArea={onSelectAreaPrime}
            mapStyle={mapStyle}
            isStylesLoaded={isStylesLoaded}
            initialLayer={layer}
          />
        </Box>
      )}

      {isLoaded && (
        <Box position="absolute" bottom={0} right={0} p={8}>
          <StylesControls value={mapStyle} onChange={onChangeStyle} />
        </Box>
      )}
    </MapGL>
  );
};
