import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends FlexProps {}

export const Content = (props: React.PropsWithChildren<Props>) => {
  const { children, ...rest } = props;
  return (
    <Flex flexDir="column" minH="full" w="full" p={8} {...rest}>
      {children}
    </Flex>
  );
};
