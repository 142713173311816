import _ from 'lodash';
import React from 'react';
import { useOrdersAutocompleteOrderName } from '../api/queries';
import { AutoComplete, AutoCompleteItem } from './forms/AutoComplete';

interface Props {
  name: string | undefined;
  setName: (name: string) => void;
}

export const OrdersNameAutocomplete = (props: Props) => {
  const { name, setName } = props;

  const autoCompleteQuery = useOrdersAutocompleteOrderName(
    {
      q: name ?? '',
    },
    {
      query: {
        enabled: !!name,
        keepPreviousData: true,
      },
    },
  );

  const onQueryChange = React.useCallback(
    (value: string) => {
      setName(value);
      autoCompleteQuery.refetch();
    },
    [setName, autoCompleteQuery],
  );

  const onChange = React.useCallback(
    (item: AutoCompleteItem<string>) => setName(item.value),
    [setName],
  );

  const items = _.map(autoCompleteQuery.data?.data ?? [], (value) => ({
    value,
    label: value,
    raw: value,
  }));

  const value = {
    value: name ?? '',
    label: name ?? '',
    raw: name ?? '',
  };

  return (
    <AutoComplete
      variant="filled"
      size="lg"
      w="xs"
      placeholder="Search by order name"
      value={value}
      query={name ?? ''}
      items={items}
      onChange={onChange}
      onQueryChange={onQueryChange}
      isLoading={autoCompleteQuery.isFetching}
    />
  );
};
