import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Progress,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';
import { ProductDetailResponse } from '../../../api/queries.schemas';
import { currencyFormat, distanceFormat } from '../../../common/utils';
import { HookedFormControl } from '../../../components/hooked/HookedFormControl';
import { HookedInput } from '../../../components/hooked/HookedInput';
import { HookedProductChooser } from '../../../components/hooked/HookedProductChooser';
import { HookedSubmitButton } from '../../../components/hooked/HookedSubmitButton';
import { OrderData } from '../../../types/main';
import NiceModal from '@ebay/nice-modal-react';
import { ChooseProductModal } from './ChooseProductModal';
import _ from 'lodash';
import { useCustomCompareEffect } from 'react-use';

interface Props {
  orderData: OrderData;
  products: ProductDetailResponse[];
  forceProductChoice: boolean;
}

export const CreateOrderSidebar = (props: Props) => {
  const { orderData, products, forceProductChoice } = props;

  const { field } = useController({ name: 'product' });

  // We want to show the modal if the user has not
  // explicitly selected a product, but we don't want
  // to show it if the user has already selected a product
  useCustomCompareEffect(
    () => {
      const onSelect = (value: string) => {
        field.onChange(_.find(products, (p) => p.id === value));
      };

      if (forceProductChoice) {
        NiceModal.show(ChooseProductModal, {
          products,
          value: field.value,
          onSelect: onSelect,
        });
      }
    },
    [{ forceProductChoice, products }],
    (prevDeps, nextDeps) => _.isEqual(prevDeps, nextDeps),
  );

  return (
    <Stack bg="dark3" w="360px" p={6} spacing={6} overflowY="auto">
      <HStack align="baseline">
        <Heading size="lg">Create Order</Heading>
      </HStack>

      <Flex flexDir="column" px={4} py={3} rounded="md" bg="dark4" w="full" align="center">
        <Text color="neutral6">Available balance</Text>
        <Text color="neutral2" fontSize="2xl" fontWeight="bold">
          {currencyFormat(orderData.available)}
        </Text>
        <Divider my={2} />
        <Box w="full">
          <HStack>
            <Text color="neutral6">Used</Text>
            <Spacer />
            <Text color="neutral6">Total</Text>
          </HStack>
          <HStack>
            <Text color="neutral2" fontWeight="semibold">
              {currencyFormat(orderData.pending)}
            </Text>
            <Spacer />
            <Text color="neutral2" fontWeight="semibold">
              {currencyFormat(orderData.total)}
            </Text>
          </HStack>

          <Progress mt={2} min={0} max={orderData.total} value={orderData.pending} size="sm" />
        </Box>
      </Flex>

      <Stack>
        <Text fontSize="2xl" fontWeight="semibold" color="neutral2">
          Your selection
        </Text>
        <HStack>
          <Text>Min order - </Text>
          <Text fontWeight="semibold">{distanceFormat(orderData.product.minimum_area)}²</Text>
        </HStack>
      </Stack>
      <HookedFormControl name="target">
        <HStack>
          <Box p={4} rounded="md" w="full" bg="dark4">
            <Text>Area</Text>
            <Text fontSize="lg" fontWeight="semibold">
              {distanceFormat(orderData.area)}²
            </Text>
          </Box>
          <Spacer />
          <Box p={4} rounded="md" w="full" bg="dark4">
            <Text>Price</Text>
            <Text fontSize="lg" fontWeight="semibold">
              {currencyFormat(orderData.price)}
            </Text>
          </Box>
        </HStack>
      </HookedFormControl>

      <Text fontSize="2xl" fontWeight="semibold" color="neutral2">
        Tasking type
      </Text>
      <HookedFormControl name="product">
        <HookedProductChooser name="product" products={products} />
      </HookedFormControl>

      <HookedFormControl name="name" label="Order name">
        <HookedInput name="name" variant="filled" placeholder="Choose an order name" />
      </HookedFormControl>
      <Spacer />
      <Box w="full">
        <HookedSubmitButton w="full" textTransform="uppercase" size="md">
          Place order
        </HookedSubmitButton>
      </Box>
    </Stack>
  );
};
