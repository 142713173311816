import React from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { dateFormatShort } from '../../../common/utils';

interface Props {
  data: any[];
  yFormatter: (value: number) => string;
}

export const OrdersChart = (props: Props) => {
  const { data, yFormatter } = props;
  const [focusedBar, setFocusedBar] = React.useState<number | null>(null);

  const onMouseEnter = (state: any) => {
    if (state?.payload) {
      setFocusedBar(state.payload.index);
    }
  };

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props;
    const radius = 20;

    return (
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#3E9EFF"
        textAnchor="middle"
        dominantBaseline="middle"
        visibility={focusedBar === props.index ? 'visible' : 'hidden'}
        fontWeight="bold"
        fontSize="18px"
      >
        {yFormatter(value)}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="period"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => dateFormatShort(value)}
        />
        <YAxis tickMargin={10} tickFormatter={yFormatter} axisLine={false} tickLine={false} />
        <Bar
          dataKey="value"
          onMouseEnter={onMouseEnter}
          onMouseLeave={() => setFocusedBar(null)}
          radius={6}
          label={renderCustomizedLabel}
        >
          {data.map((_item, index) => (
            <Cell
              key={`cell-${index}`}
              fill="#2E2F34"
              stroke={focusedBar === index ? '#3E9EFF' : 'none'}
              strokeWidth="2px"
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
